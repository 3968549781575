import React, {FunctionComponent, useState} from 'react';
import {Grid, Typography, CardMedia, Button} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {useHistory, withRouter} from 'react-router';
import colors from '../theming/colors';
import parentImg from '../static/img/avatars/giraffe.png';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import SvgIcon from '@material-ui/core/SvgIcon';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import route from '../routes/route';
import {Child} from '../features/children';
import LoadChildCard, {ChildCard} from './ChildCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';

const childEntrySize = '7rem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    gridContainer: {
      margin: '3vh 0',
      width: 'fit-content',
    },
    titleText: {
      color: colors.primary,
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
      },
    },
    topButton: {
      borderRadius: 10,
      marginRight: 12,
      color: 'white',
    },
    guardianAvatar: {
      margin: 'auto',
      height: '12rem',
      width: '12rem',
      borderRadius: '50%',
      border: '0.15rem solid #003459',
    },
    childList: {
      marginTop: '1vh',
    },
    plus: {
      marginRight: '0.5rem',
    },
    topGrid: {
      marginTop: 32,
      marginBottom: 48,
      minWidth: '375px',
    },
    menuContent: {
      maxWidth: '500px',
      marginLeft: '24px',
    },
    pageContent: {
      marginLeft: 24,
      marginRight: 24,
      background: 'white',
      padding: '1.5rem',
      display: 'flex',
      borderRadius: 10,
      boxShadow: '2px 3px 8px #000',
      maxWidth: 'inherit',
      width: 'fit-content',
    },
  })
);

const ParentHome: FunctionComponent = () => {
  const user = useSelector((state: RootState) => state.user);
  // use useSelect if we are in a react function componenet
  const childrenState = useSelector((state: RootState) => state.children);
  const childElements: Array<any> = [];
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMDorLower = useMediaQuery(theme.breakpoints.down('sm'));

  const addChild = () => {
    history.push(`/${route.signup.addNewChild}`);
  };

  // This function goes to the main module selection page
  const goToModuleDashBoard = (e: any) => {
    history.push(`/${route.modules.moduleDashboard}`, e.currentTarget.id);
  };

  const goChildView = () => {
    history.push(`/${route.childrensView}`);
  };
  const createChildElement = (childrenList: {[index: string]: Child}) => {
    for (const key in childrenList) {
      const childInfo: ChildCard = {
        name: childrenList[key].name.first,
        avatar: childrenList[key].avatar,
        childID: childrenList[key].uid,
        buttons: [
          {
            label: 'Child Progress',
            color: 'primary',
            func: goToModuleDashBoard,
          },
          {
            label: 'Module Dashboard',
            func: goToModuleDashBoard,
          },
          // {
          //   label: 'Demographic Questionnaire',
          //   func: goToChildDemographic,
          // },
        ],
      };

      childElements.push(
        <div key={childInfo.childID}>{LoadChildCard(childInfo)}</div>
      );
    }
  };

  createChildElement(childrenState);

  const gridLayoutConfig = isMDorLower ? 'center' : 'flex-start';

  return (
    <>
      <Grid
        container
        className={classes.topGrid}
        direction="row"
        justifyContent="center"
      >
        <Grid
          item
          container
          className={classes.pageContent}
          direction="row"
          justifyContent={gridLayoutConfig}
          alignItems={gridLayoutConfig}
        >
          <Grid item>
            <CardMedia
              image={parentImg}
              title="logo"
              className={classes.guardianAvatar}
            />
          </Grid>
          <Grid item className={classes.menuContent}>
            <Grid
              container
              direction="column"
              justifyContent={gridLayoutConfig}
              alignItems={gridLayoutConfig}
            >
              <Grid item>
                <Typography className={classes.titleText} variant="h4">
                  <b>{`${user.name.first} ${user.name.last}`}</b>
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent={gridLayoutConfig}
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Button
                    className={classes.topButton}
                    variant="contained"
                    color="secondary"
                    onClick={goChildView}
                  >
                    Childrens View
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    className={classes.topButton}
                    variant="contained"
                    color="secondary"
                    onClick={addChild}
                  >
                    <SvgIcon
                      component={ControlPointIcon}
                      className={classes.plus}
                    />
                    Add Child
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Typography className={classes.text} variant="h3">
          {Object.keys(childrenState).length > 0 ? (
            <b>Select Child To View Dashboard</b>
          ) : (
            <b>No existing children found</b>
          )}
        </Typography>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          spacing={0}
          className={classes.childList}
        >
          {childElements}
        </Grid>
      </div>
    </>
  );
};

export default withRouter(ParentHome);
