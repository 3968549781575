import React, {useEffect} from 'react';
import {Switch, Route, withRouter, RouteComponentProps} from 'react-router-dom';
import {PublicRoute, PrivateRoute} from './auth/RouteHandler';
import Navbar from './components/navBar';
import LandingPage from './LandingPage';
import ParentConsent from './consent-forms/ParentConsent';
import TeacherConsent from './consent-forms/TeacherConsent';
import TeacherRegistration from './signups/TeacherRegistration';
import ChildrensView from './components/ChildrensView';
import ChildRegistration from './signups/signup_pages/ChildRegistration';
import HomeLearningDiscovery from './signups/signup_pages/HomeLearningDiscovery';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import Login from './auth/Login';
import colors from './theming/colors';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Home from './Home';
import ErrorPage from './ErrorPage';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import anime from 'animejs';
import animations from './theming/animations';
import ParentSignup from './signups/ParentSignup';
import route from './routes/route';
import About from './components/About';
import {ACCOUNT_TYPES} from './features/users';
import CreateClass from './components/CreateClass';
import ClassDashboard from './components/ClassDashboard';
import PLOTTQuestionnaire from './components/PLOTTQuestionnaire';
import OLACQuestionnaire from './signups/school_questionnaire/OLACQuestionnaire';
import ELQuestionnaire from './signups/school_questionnaire/ELQuestionnaire';
import ENQuestionnaire from './signups/school_questionnaire/ENQuestionnaire';
import SEDQuestionnaire from './signups/school_questionnaire/SEDQuestionnaire';
import ATLQuestionnaire from './signups/school_questionnaire/ATLQuestionnaire';
import MATSQuestionnaire from './signups/school_questionnaire/MATSQuestionnaire';
import WTPQuestionnaire from './signups/school_questionnaire/WTPQuestionnaire';
import ModuleDashboard from './modules/ModuleDashboard';
import Module0 from './modules/module0/Module0';
import StudentProgress from './components/StudentProgress';
import AboutMyChildsDevelopment from './signups/signup_pages/AboutMyChildsDevelopment';

import ParentHome from './components/ParentHome';
import TeacherHome from './components/TeacherHome';
import AddNewChild from './signups/AddNewChild';
const globalTheme = createTheme({
  palette: {
    primary: {main: colors.primary},
    secondary: {main: colors.secondary},
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

const animationName = 'slideY';

const startAnimation = (node: HTMLElement | null) => {
  anime.timeline({loop: false}).add({
    targets: node,
    duration: animations.initEnterEffect,
    translateY: [-30, 0],
    opacity: {
      value: [0.1, 1],
      easing: 'linear',
      duration: animations.initEnterEffect,
    },
    easing: 'easeInOutCubic',
  });
};

const exitAnimation = (node: HTMLElement | null) => {
  anime.timeline({loop: false}).add({
    targets: node,
    duration: animations.initEnterEffect,
    translateY: [0, 90],
    opacity: {
      value: [1, 0.1],
      easing: 'linear',
      duration: animations.initEnterEffect,
    },
    easing: 'easeInOutCubic',
  });
};

const App: React.SFC<RouteComponentProps> = ({location}) => {
  useEffect(() => {
    startAnimation(document.querySelector('.route-section'));
  }, []);
  return (
    <ThemeProvider theme={globalTheme}>
      <Navbar />
      <ReactNotification />
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={animations.initEnterEffect}
          classNames={animationName}
          onEnter={startAnimation}
          onExit={exitAnimation}
        >
          <section className="route-section">
            <Switch location={location}>
              <PublicRoute exact path="/" component={LandingPage} />
              <PublicRoute exact path={`/${route.login}`} component={Login} />
              <PublicRoute
                exact
                path={`/${route.consent.parent}`}
                component={ParentConsent}
              />
              <PublicRoute
                exact
                path={`/${route.consent.teacher}`}
                component={TeacherConsent}
              />
              <PublicRoute
                exact
                path={`/${route.signup.teacher}`}
                component={TeacherRegistration}
              />
              <PublicRoute
                exact
                path={`/${route.signup.parent}`}
                component={ParentSignup}
              />
              <PublicRoute exact path={`/${route.about}`} component={About} />
              <PrivateRoute exact path={`/${route.home}`} component={Home} />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.parentHome}`}
                component={ParentHome}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacherHome}`}
                component={TeacherHome}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.signup.child}`}
                component={ChildRegistration}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.signup.homeLearningDiscovery}`}
                component={HomeLearningDiscovery}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.signup.aboutMyChildsDevelopment}`}
                component={AboutMyChildsDevelopment}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.signup.addNewChild}`}
                component={AddNewChild}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.childrensView}`}
                component={ChildrensView}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.modules.moduleDashboard}`}
                component={ModuleDashboard}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.PARENT}
                path={`/${route.modules.module0}`}
                component={Module0}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.signup.createClass}`}
                component={CreateClass}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.classDashboard}`}
                component={ClassDashboard}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.plottQuestionnaire}`}
                component={PLOTTQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.olacQuestionnaire}`}
                component={OLACQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.elQuestionnaire}`}
                component={ELQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.wtpQuestionnaire}`}
                component={WTPQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.enQuestionnaire}`}
                component={ENQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.sedQuestionnaire}`}
                component={SEDQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.atlQuestionnaire}`}
                component={ATLQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.matsQuestionnaire}`}
                component={MATSQuestionnaire}
              />
              <PrivateRoute
                exact
                accountType={ACCOUNT_TYPES.TEACHER}
                path={`/${route.teacher.studentProgress}`}
                component={StudentProgress}
              />
              <Route component={ErrorPage} />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </ThemeProvider>
  );
};

export default withRouter(App);
