import React, {FunctionComponent} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {HomeDemographicSchema} from '../../schema';
import Signup from '../../components/Signup';
import {FormikBag} from 'formik';

export interface FormValues {
  educationParent1: string;
  educationParent2: string;
  maritalStatus: string;
  annualIncome: string;
}

type HomeDemographicProps = RouteComponentProps<any> & {
  formOnSubmit: (
    values: FormValues,
    formikBag: FormikBag<object, FormValues>
  ) => void;
  formInitialValues: FormValues;
  onBack: (values: FormValues) => void;
};

const HomeDemographicQuestions: React.FunctionComponent<HomeDemographicProps> =
  (props: HomeDemographicProps) => {
    const {formOnSubmit, formInitialValues, onBack} = props;
    const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
      const lst = [];
      if (!labelArr) labelArr = valArr;
      for (let i = 0; i < valArr.length; i++)
        lst.push({value: valArr[i], label: labelArr[i]});
      return lst;
    };

    const formModel: InputModels[] = [
      {
        type: 'select',
        key: 'educationParent1',
        data: {
          id: 'educationParent1',
          inputText: 'What is the highest level of education of Parent 1?',
          label: 'educationParent1',
          menuItems: generateMenuItems([
            'High school',
            'College/University Undergraduate Degree',
            'Master’s Degree',
            'Doctoral Degree',
          ]),
        },
      },
      {
        type: 'select',
        key: 'educationParent2',
        data: {
          id: 'educationParent2',
          inputText: 'What is the highest level of education of Parent 2?',
          label: 'educationParent2',
          menuItems: generateMenuItems([
            'High school',
            'College/University Undergraduate Degree',
            'Master’s Degree',
            'Doctoral Degree',
          ]),
        },
      },
      {
        type: 'select',
        key: 'maritalStatus',
        data: {
          id: 'maritalStatus',
          inputText: 'What is your current marital status?',
          label: 'maritalStatus',
          menuItems: generateMenuItems([
            'Single parent ',
            'Married/Common Law',
            'Separated/Divorced  ',
          ]),
        },
      },
      {
        type: 'select',
        key: 'annualIncome',
        data: {
          id: 'annualIncome',
          inputText: 'What is your current marital status?',
          label: 'annualIncome',
          menuItems: generateMenuItems([
            'Under $25,000',
            '$25 000- $54,999',
            '$55 000- $79,999',
            '$80 000- $99,999',
            '100 000- $150 000',
            'Over $150 000',
          ]),
        },
      },
    ];
    return (
      <>
        <Signup
          title="Home Demographic Questions"
          submitText="Finish"
          formSchema={HomeDemographicSchema}
          formInitialValues={formInitialValues}
          formModel={formModel}
          formOnSubmit={formOnSubmit}
        ></Signup>
      </>
    );
  };

export default withRouter(HomeDemographicQuestions);
