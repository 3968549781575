import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {ENQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface ENForm {
  FP1_EN: string;
  FP2_EN: string;
  FP3_EN: string;
  IP1_EN: string;
  IP2_EN: string;
  ConP1_EN: string;
  ConP2_EN: string;
  ConP3_EN: string;
  RBP1_EN: string;
  GP1_EN: string;
}

const ENQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.sedQuestionnaire;
  const prev = route.teacher.elQuestionnaire;
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultENValues: ENForm = {
    FP1_EN: '',
    FP2_EN: '',
    FP3_EN: '',
    IP1_EN: '',
    IP2_EN: '',
    ConP1_EN: '',
    ConP2_EN: '',
    ConP3_EN: '',
    RBP1_EN: '',
    GP1_EN: '',
  };

  const defaultENExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childEN = childPLOTT.EN;
          if (childEN) {
            defaultENValues = childEN;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'EN');
          if (uploadedFiles)
            defaultENExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'EN'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'EN'
  );

  const formOnSubmit = async (values: ENForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('EN')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'EN'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'FP1_EN',
      data: {
        id: 'FP1_EN',
        label: 'Recognizes and read numbers from 1 to 10.',
        labelInfoPopup: 'e.g., ‘There is a 9 on that sign`​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP1_EN',
      data: {
        id: 'IP1_EN',
        label: 'Counts forward by route memorization. ​',
        labelInfoPopup:
          'Counts aloud from 1 to 10 to time cookies baking in a play oven',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_EN',
      data: {
        id: 'FP2_EN',
        label: 'Counts backwards​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP3_EN',
      data: {
        id: 'FP3_EN',
        label: 'Identify and draw shapes.​',
        labelInfoPopup: 'e.g. triangle, circle, square​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'UNDEF_1', // 'UNDEF' means the key hasn't been defined
      data: {
        id: 'UNDEF_1',
        label: 'Match numbers with objects in pictures',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'UNDEF_2', // 'UNDEF' means the key hasn't been defined
      data: {
        id: 'UNDEF_2',
        label: 'Write numbers',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP2_EN',
      data: {
        id: 'IP2_EN',
        label:
          'Understands numbers and simple operations, like addition or subtraction, in daily activities.',
        labelInfoPopup: 'e.g., Counts out 8 coins for a pretend ice cream',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP1_EN',
      data: {
        id: 'ConP1_EN',
        label:
          'Classifies or compares objects and ideas in relationship, such as ‘more’, ‘less’, and ‘same as’.​',
        labelInfoPopup:
          'e.g., Lines up sticks from shortest to longest; ‘I have more red paint than blue paint’',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'UNDEF_3', // 'UNDEF' means the key hasn't been defined
      data: {
        id: 'UNDEF_3',
        label: 'Arrange objects in size order such as big to small',
        labelInfoPopup: 'e.g. Lines up sticks from shortest to longest',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP2_EN',
      data: {
        id: 'ConP2_EN',
        label: 'Recognizes (describes) a pattern of 2-4 items.​',
        labelInfoPopup:
          'Creates a bead necklace with a consistent pattern of blue, red and yellow beads',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP3_EN',
      data: {
        id: 'ConP3_EN',
        label:
          'Engages in play as a means to develop questioning and problem-solving skills.​​',
        labelInfoPopup:
          'e.g., Uses trial and error to find an equivalent block when constructing a racetrack; asks ‘Why does the ice cube melt so fast?’',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP1_EN',
      data: {
        id: 'RBP1_EN',
        label: 'Recognizes quantities or sets of 2 to 8.',
        labelInfoPopup:
          'e.g., Here are the 4 tokens you need to play this game',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP1_EN',
      data: {
        id: 'GP1_EN',
        label: 'Understands basic time concepts like yesterday, today, winter.',
        labelInfoPopup: 'e.g., The boat will be ready for sailing tomorrow',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Numeracy through Play"
        submitText={'Submit Questionnaire'}
        formSchema={ENQuestionnaireSchema}
        formInitialValues={defaultENValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.en}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultENExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(ENQuestionnaire);
