import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import firebase from 'firebase/app';
import {usersCollection} from '../firebase-app';
import {InputModels} from '../components/FormInputs';
import {TeacherRegistrationSchema} from '../schema';
import Signup from '../components/Signup';
import store from '../features/store';
import {ACCOUNT_TYPES} from '../features/users';
import {store as storen} from 'react-notifications-component';

type TeacherRegistrationProps = RouteComponentProps<any>;

export interface FormValues {
  email: string;
  password: string;
  grades: string[];
  confirmPassword: string;
  firstname: string;
  lastname: string;
  gender: string;
  schoolName: string;
  userExperienceInterview: string;
  agreeDisseminationSpeech: string;
  agreeDisseminationVideo: string;
}

const TeacherRegistration: React.FunctionComponent<TeacherRegistrationProps> =
  () => {
    const initialFormValues: FormValues = {
      email: '',
      password: '',
      confirmPassword: '',
      firstname: '',
      lastname: '',
      gender: '',
      grades: [],
      schoolName: '',
      userExperienceInterview: '',
      agreeDisseminationSpeech: '',
      agreeDisseminationVideo: '',
    };

    const registrationFormOnSubmit = async (
      values: FormValues
      // formikBag: FormikBag<object, RegistrationFormValues>
    ) => {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(values['email'])
        .then((e: string[]) => {
          if (!(e && e[0] === 'password')) {
            // email isn't already registered
            createUser(values.email, values.password).then(
              (cred: firebase.auth.UserCredential) => {
                const unsubscribe = store.subscribe(() => {
                  const state = store.getState();
                  if (state.user.uid !== '' && state.user.uid) {
                    unsubscribe();
                    submitData(cred.user, values);
                  }
                });
              }
            );
          } else {
            // email already exists
            storen.addNotification({
              title: 'Email already exists!',
              message: `Please use a different email. Email: ${values.email} is in use.`,
              type: 'danger',
              insert: 'bottom',
              container: 'top-right',
              animationIn: ['animated', 'slideIn'],
              animationOut: ['animated', 'slideOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
          }
        });
    };

    const createUser = (email: string, password: string) => {
      return firebase.auth().createUserWithEmailAndPassword(email, password);
    };

    const submitData = (user: firebase.User | null, data: FormValues) => {
      if (user !== null) {
        usersCollection
          .doc(user.uid)
          .set({
            // registration
            email: data.email,
            firstname: data.firstname,
            lastname: data.lastname,
            gender: data.gender,
            grades: data.grades,
            classes: [],
            schoolName: data.schoolName,
            userExperienceInterview: data.userExperienceInterview,
            agreeDisseminationSpeech: data.agreeDisseminationSpeech,
            agreeDisseminationVideo: data.agreeDisseminationVideo,
            accountType: ACCOUNT_TYPES.TEACHER,
            dateSubmitted: firebase.firestore.Timestamp.now(),
          })
          .then(() => {
            storen.addNotification({
              title: 'Account successfully created!',
              message: 'Enjoy!',
              type: 'success',
              insert: 'bottom',
              container: 'top-right',
              animationIn: ['animated', 'slideIn'],
              animationOut: ['animated', 'slideOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
          })
          .catch(() => {
            storen.addNotification({
              title: 'Account creation failed!',
              message: 'Please try again.',
              type: 'danger',
              insert: 'bottom',
              container: 'top-right',
              animationIn: ['animated', 'slideIn'],
              animationOut: ['animated', 'slideOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
          });
      }
    };

    const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
      const lst = [];
      if (!labelArr) labelArr = valArr;
      for (let i = 0; i < valArr.length; i++)
        lst.push({value: valArr[i], label: labelArr[i]});
      return lst;
    };

    const formModel: InputModels[] = [
      {
        type: 'textfield',
        key: 'email',
        data: {
          id: 'email',
          label: 'Email',
          type: 'email',
        },
      },
      {
        type: 'textfield',
        key: 'password',
        data: {
          id: 'password',
          label: 'Password',
          type: 'password',
        },
      },
      {
        type: 'textfield',
        key: 'confirmPassword',
        data: {
          id: 'confirmPassword',
          label: 'Confirm Password',
          type: 'password',
        },
      },
      {
        type: 'textfield',
        key: 'firstname',
        data: {
          id: 'firstname',
          label: 'First Name',
          type: 'name',
        },
      },
      {
        type: 'textfield',
        key: 'lastname',
        data: {
          id: 'lastname',
          label: 'Last Name',
          type: 'name',
        },
      },
      {
        type: 'select',
        key: 'gender',
        data: {
          id: 'gender',
          label: 'Gender',
          menuItems: generateMenuItems([
            'Female',
            'Male',
            'Non-Binary',
            'Other',
          ]),
        },
      },
      {
        type: 'multiSelect',
        key: 'grades',
        data: {
          id: 'grades',
          label: 'Grades You Teach',
          menuItems: generateMenuItems([
            'Lower Kindergarten',
            'Upper Kindergarten',
            'Grade 1',
            'Grade 2',
          ]),
        },
      },
      {
        type: 'select',
        key: 'schoolName',
        data: {
          id: 'schoolName',
          label: 'School Name',
          type: 'activeSchool', // provide teacher key if using teacher input as well
        },
      },
      {
        type: 'radio',
        key: 'userExperienceInterview',
        data: {
          id: 'userExperienceInterview',
          label: 'I agree to participate in a learner experience interview.',
          radios: [
            {value: 'yes', label: 'Yes'},
            {value: 'no', label: 'No'},
          ],
        },
      },
      {
        type: 'radio',
        key: 'agreeDisseminationSpeech',
        data: {
          id: 'agreeDisseminationSpeech',
          label:
            'For data dissemination activities, I agree on the usage of my speech data for research purposes',
          radios: [
            {value: 'yes', label: 'Yes'},
            {value: 'no', label: 'No'},
          ],
        },
      },
      {
        type: 'radio',
        key: 'agreeDisseminationVideo',
        data: {
          id: 'agreeDisseminationVideo',
          label:
            'For data dissemination activities, I agree on the usage of my video data for research purposes',
          radios: [
            {value: 'yes', label: 'Yes'},
            {value: 'no', label: 'No'},
          ],
        },
      },
    ];

    return (
      <>
        <Signup
          title="Teacher Registration for BalanceAI Primary"
          submitText="Submit"
          recaptcha
          formSchema={TeacherRegistrationSchema}
          formInitialValues={initialFormValues}
          formModel={formModel}
          formOnSubmit={registrationFormOnSubmit}
        ></Signup>
      </>
    );
  };

export default withRouter(TeacherRegistration);
export type SignupFormValues = FormValues;
