import React, {useState, useEffect} from 'react';
import {RouteComponentProps, withRouter, useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import {
  childrenCollection,
  usersCollection,
  classesCollection,
} from '../firebase-app';
import {InputModels} from '../components/FormInputs';
import {ChildRegistrationSchema} from '../schema';
import Signup from '../components/Signup';
import store from '../features/store';
import route from '../routes/route';
import {fetchChildrenData} from '../features/children';
import {fetchUserInfo} from '../features/users';
import {createNotification} from '../notifications/notification';
import ChildRegistration, {
  FormValues as ChildRegistrationFormValues,
} from './signup_pages/ChildRegistration';
import HomeLearningDiscovery, {
  FormValues as HomeLearningFormValues,
} from './signup_pages/HomeLearningDiscovery';
import AboutMyChildsDevelopment, {
  FormValues as AboutMyChildFormValues,
} from './signup_pages/AboutMyChildsDevelopment';
import SignupHelpers, {questionnaireOnBack} from './SignupHelpers';
import Spinner from '../components/Spinner';
// import {questionnaireOnBack, childRegistrationFormOnSubmit, HomeLearningDiscoveryOnSubmit, AboutMyChildsDevelopmentOnSubmit,
//   childRegInitVals, homeLearningInitVals, aboutMyChildInitVals} from './ParentSignup'

type StudentSignupProps = RouteComponentProps<any>;

const AddNewChild: React.FunctionComponent<StudentSignupProps> = () => {
  const history = useHistory();
  const user = store.getState();

  const [currentPage, setCurrentPage] = useState(0);
  const pageNames = [
    'child registration',
    'home learning discovery',
    'about my childs development',
    'submit',
  ];

  const [childRegInitVals, SetChildRegInitVals] =
    useState<ChildRegistrationFormValues>(SignupHelpers.childRegSetup);
  const [homeLearningInitVals, SetHomeLearningInitVals] =
    useState<HomeLearningFormValues>(SignupHelpers.homeLearningSetup);
  const [aboutMyChildInitVals, SetAboutMyChildInitVals] =
    useState<AboutMyChildFormValues>(SignupHelpers.aboutMyChildSetup);

  const childRegistrationFormOnSubmit = async (
    values: ChildRegistrationFormValues
  ) => {
    await SetChildRegInitVals(values);
    // Look up teacher and see if classID provided
    const teacher = await (
      await usersCollection.doc(values.teacher.id).get()
    ).data();
    if (teacher) {
      if (teacher.classIDs) {
        const classID = values.classID.toUpperCase().trim();
        if (teacher.classIDs.includes(classID)) {
          const teacherClass = await (
            await classesCollection.doc(classID).get()
          ).data();
          if (teacherClass) {
            if (teacherClass.grade) {
              if (teacherClass.grade !== values.grade) {
                createNotification(
                  'The class code does not match the grade that the student is in',
                  'Please try again',
                  'danger'
                );
                return;
              }
            }
          }
        } else {
          createNotification(
            'The class code is wrong',
            'Please try again',
            'danger'
          );
          return;
        }
      }
    }
    // Class code is valid and the class matches the grade that the student is in
    setCurrentPage(currentPage + 1);
  };

  const HomeLearningDiscoveryOnSubmit = async (
    values: HomeLearningFormValues
  ) => {
    await SetHomeLearningInitVals(values);
    console.log('submitting home learning discovery');
    setCurrentPage(currentPage + 1);
  };

  const AboutMyChildsDevelopmentOnSubmit = async (
    values: AboutMyChildFormValues
  ) => {
    await SetAboutMyChildInitVals(values);

    console.log('submitting about my childs development');
    setCurrentPage(currentPage + 1);
  };

  const submitChildRegistrationData = async (
    userUID: string,
    child_registration_data: ChildRegistrationFormValues
  ) => {
    if (user === null) {
      return 'Error 4';
    }
    let childID = '';
    await childrenCollection
      .add({
        ...child_registration_data,
        parentID: userUID,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .then(async docRef => {
        // Add the child to the parent.
        childID = await docRef.id;
        usersCollection
          .doc(userUID)
          .update({
            childrenIDs: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          })
          .then(async () => {
            // Add the child to the class
            const classID = child_registration_data.classID
              .toUpperCase()
              .trim();
            await classesCollection
              .doc(classID)
              .update({
                childrenIDs: firebase.firestore.FieldValue.arrayUnion(childID),
              })
              .catch(() => {
                createNotification(
                  'Child registration failed. Error: 1',
                  'Please try again',
                  'danger'
                );
                return 'Error 1';
              });
            // Add success notification
            // Update our state variable of children to get the new one
            // await store.dispatch(fetchUserInfo(user));
            await store.dispatch(fetchChildrenData());
            console.log('Child added successfully');
            // history.push(`${route.home}`);
            return childID;
          })
          .catch(() => {
            createNotification(
              'Child registration failed. Error: 2',
              'Please try again',
              'danger'
            );
            return 'Error 2';
          });
      })
      .catch(() => {
        createNotification(
          'Child registration failed. Error: 3',
          'Please try again',
          'danger'
        );
        return 'Error 3';
      });
    return childID;
  };

  const submitHomeLearningData = async (
    userUID: string,
    childID: string,
    home_learning_data: HomeLearningFormValues
  ) => {
    if (user !== null) {
      await childrenCollection
        .doc(childID)
        .collection('Additional')
        .doc('HomeLearning')
        .set({
          ...home_learning_data,
          dateSubmitted: firebase.firestore.Timestamp.now(),
        })
        .catch(() => {
          createNotification(
            'Failed to fill out the questionnaire',
            'Please try again',
            'danger'
          );
        })
        .then(async () => {
          console.log(
            'Submitted Home Learning Discovery questionnaire succesfully'
          );
        });
    }
  };

  const submitMyChildData = async (
    userUID: string,
    childID: string,
    about_my_child_data: AboutMyChildFormValues
  ) => {
    if (userUID !== null) {
      await childrenCollection
        .doc(childID)
        .collection('Additional')
        .doc('AboutMyChild')
        .set({
          ...about_my_child_data,
          dateSubmitted: firebase.firestore.Timestamp.now(),
        })
        .catch(() => {
          createNotification(
            'Failed to fill out the questionnaire',
            'Please try again',
            'danger'
          );
        })
        .then(async () => {
          console.log('Submitted About My Child questionnaire succesfully');
        });
    }
  };

  const submitData = async (
    child_registration_data: ChildRegistrationFormValues,
    home_learning_data: HomeLearningFormValues,
    about_my_child_data: AboutMyChildFormValues
  ) => {
    console.log(
      child_registration_data,
      home_learning_data,
      about_my_child_data
    );
    const childID: string = await submitChildRegistrationData(
      user.user.uid,
      child_registration_data
    );
    await submitHomeLearningData(user.user.uid, childID, home_learning_data);
    await submitMyChildData(user.user.uid, childID, about_my_child_data);
    createNotification('Child added successfully', 'Thank you!', 'success');
    history.push(`/${route.childrensView}`);
    return;
  };

  useEffect(() => {
    if (pageNames[currentPage] === 'submit') {
      console.log('In use Effect');
      submitData(childRegInitVals, homeLearningInitVals, aboutMyChildInitVals);
    }
  }, [currentPage]);

  const renderPage = () => {
    console.log('Render');
    switch (pageNames[currentPage]) {
      case 'child registration':
        return (
          <ChildRegistration
            onBack={questionnaireOnBack}
            formOnSubmit={childRegistrationFormOnSubmit}
            formInitialValues={childRegInitVals}
          />
        );
      case 'home learning discovery':
        return (
          <HomeLearningDiscovery
            onBack={questionnaireOnBack}
            formOnSubmit={HomeLearningDiscoveryOnSubmit}
            formInitialValues={homeLearningInitVals}
          />
        );
      case 'about my childs development':
        return (
          <AboutMyChildsDevelopment
            onBack={questionnaireOnBack}
            formOnSubmit={AboutMyChildsDevelopmentOnSubmit}
            formInitialValues={aboutMyChildInitVals}
          />
        );
      case 'submit':
        return <Spinner />;
    }
    return <h1>Error Occured</h1>;
  };

  return renderPage();
};

export default withRouter(AddNewChild);
