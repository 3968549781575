import React from 'react';
import {
  Card,
  Typography,
  CardContent,
  Paper,
  CardActionArea,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ArrowBack} from '@material-ui/icons';

type TextAlignProperty =
  | 'center'
  | 'end'
  | 'justify'
  | 'left'
  | 'match-parent'
  | 'right'
  | 'start';

export interface CardCustomProps {
  // CSS Properties
  color?: string; // color of the heading background
  maxWidth?: string; // width of the card
  alignHeader?: TextAlignProperty; // align the header
  paddingHeader?: string; // padding of the header
  titleColor?: string; // title color
  width?: string; // initial width
  height?: string;
  backgroundColor?: string; // color of the entire card
  removeBorders?: boolean;

  // Card properties
  title: string; // title of the card
  cardBody: React.ReactNode;
  fullHeight?: boolean;
  image?: {
    src: string; // image source
    maxWidth: string; // maxwidth - css property
  }; // if provided, path to image to put on top of the card
  headerContent?: JSX.Element; // add stuff like buttons to the header;
  clickable?: () => void; // if a function is provided, then the entire card will be clickable, and on click will call this function
  showBackButton?: boolean; // if provided, will show the back button to navigate to the back page
}

const DEFAULT_CARD = {
  width: '345px',
  color: 'default',
  alignHeader: 'center' as TextAlignProperty,
  paddingHeader: '16px',
  fontWeight: '400',
  imageWidth: '345px',
};

const useStyle = makeStyles({
  paperBody: {
    maxWidth: (props: CardCustomProps) =>
      props.maxWidth ? props.maxWidth : DEFAULT_CARD.width,
    width: (props: CardCustomProps) => (props.width ? props.width : 'inherit'),
    border: (props: CardCustomProps) =>
      props.removeBorders ? 'unset' : 'auto',
  },
  cardBody: {
    width: (props: CardCustomProps) => (props.width ? props.width : 'auto'),
    height: (props: CardCustomProps) => {
      return props.height;
    },
    backgroundColor: (props: CardCustomProps) =>
      props.backgroundColor ? props.backgroundColor : '',
    boxShadow: (props: CardCustomProps) =>
      props.removeBorders ? 'unset' : 'auto',
  },
  cardHeader: {
    backgroundColor: (props: CardCustomProps) => props.color,
    textAlign: (props: CardCustomProps) =>
      props.alignHeader ? props.alignHeader : DEFAULT_CARD.alignHeader,
    padding: DEFAULT_CARD.paddingHeader,
    '& h6': {
      fontWeight: DEFAULT_CARD.fontWeight,
      color: (props: CardCustomProps) =>
        props.titleColor ? props.titleColor : 'inherit',
    },
  },
  cardContent: {
    padding: '0px !important',
    height: (props: CardCustomProps) => {
      return props.height;
    },
  },
  cardMedia: {
    maxWidth: (props: CardCustomProps) =>
      props.image ? props.image.maxWidth : DEFAULT_CARD.imageWidth,
    padding: '10px',
    margin: 'auto', // centralizes the image
  },
  backButton: {
    display: 'inline-flex',
    verticalAlign: 'sub',
    textAlign: 'left',
  },
  backButtonTitle: {
    display: 'inline-flex',
  },
});

const CardCustom: React.SFC<CardCustomProps> = (props: CardCustomProps) => {
  const classes = useStyle(props);
  const {clickable, title, cardBody, image, headerContent, showBackButton} =
    props;

  const titleImage = image && (
    <CardMedia
      component="img"
      image={image.src}
      title={title}
      className={classes.cardMedia}
    />
  );

  const cardContent = (
    <>
      {titleImage}
      <CardContent className={classes.cardContent}>
        <div className={classes.cardHeader}>
          {headerContent ? (
            headerContent
          ) : showBackButton ? (
            <>
              <IconButton className={classes.backButton}>
                <ArrowBack />
              </IconButton>
              <Typography className={classes.backButtonTitle} variant="h6">
                {title}
              </Typography>
            </>
          ) : (
            <Typography variant="h6">{title}</Typography>
          )}
        </div>
        {cardBody}
      </CardContent>
    </>
  );

  return (
    <>
      <Paper className={classes.paperBody} variant="outlined">
        <Paper className={classes.cardBody}>
          {clickable ? (
            <CardActionArea onClick={clickable}>{cardContent}</CardActionArea>
          ) : (
            cardContent
          )}
        </Paper>
      </Paper>
    </>
  );
};

export default CardCustom;
