import PlottFormFormat from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {MATSQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';
import {SkeletonProps} from '@material-ui/lab';
import Signup from '../../components/Signup';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface MATSForm {
  special_needs_MATS: string;
  exceptional_skills_MATS: string;
}

const MATSQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );

  let defaultMATSValues: MATSForm = {
    special_needs_MATS: '',
    exceptional_skills_MATS: '',
  };

  const defaultMATSExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childMATS = childPLOTT.MATS;
          if (childMATS) {
            defaultMATSValues = childMATS;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'MATS');
          if (uploadedFiles)
            defaultMATSExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'MATS'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'MATS'
  );

  const formOnSubmit = async (values: MATSForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('MATS')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'MATS'));
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'textfield',
      key: 'special_needs_MATS',
      data: {
        id: 'special_needs_MATS',
        inputText: 'Does this child have any documented special needs?',
        label: 'Needs',
        type: 'special_needs_MATS',
      },
    },
    {
      type: 'textfield',
      key: 'exceptional_skills_MATS',
      data: {
        id: 'exceptional_skills_MATS',
        inputText:
          'Does this child demonstrate unique or exceptional literacy or other academic skills or talents (that could be related to culture or second language abilities) Explain',
        label: 'Skills',
        type: 'exceptional_skills_MATS',
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <Signup
        title="More About the Student"
        submitText={'Submit Questionnaire'}
        formSchema={MATSQuestionnaireSchema}
        formInitialValues={defaultMATSValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="50vw"
        color={colors.mats}
      ></Signup>
    </>
  );
};

export default withRouter(MATSQuestionnaire);
