import React from 'react';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import route from '../routes/route';
import {Grid, Typography, Card} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {OLACForm} from '../signups/school_questionnaire/OLACQuestionnaire';
import {ELForm} from '../signups/school_questionnaire/ELQuestionnaire';
import {ENForm} from '../signups/school_questionnaire/ENQuestionnaire';
import {SEDForm} from '../signups/school_questionnaire/SEDQuestionnaire';
import {ATLForm} from '../signups/school_questionnaire/ATLQuestionnaire';
import {MATSForm} from '../signups/school_questionnaire/MATSQuestionnaire';
import {WTPForm} from '../signups/school_questionnaire/WTPQuestionnaire';
import OLAC_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_OLAC.png';
import OLAC_ribbon from '../static/img/teacher_discovery_questionnaire/tab_OLAC.png';
import EL_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_EL.png';
import EL_ribbon from '../static/img/teacher_discovery_questionnaire/tab_EL.png';
import EN_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_EN.png';
import EN_ribbon from '../static/img/teacher_discovery_questionnaire/tab_EN.png';
import SED_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_SED.png';
import SED_ribbon from '../static/img/teacher_discovery_questionnaire/tab_SED.png';
import ATL_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_ATL.png';
import ATL_ribbon from '../static/img/teacher_discovery_questionnaire/tab_ATL.png';
import MATS_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_MATS.png';
import MATS_ribbon from '../static/img/teacher_discovery_questionnaire/tab_MATS.png';
import WTP_picture from '../static/img/teacher_discovery_questionnaire/questionnaire_picture_WTP.png';
import WTP_ribbon from '../static/img/teacher_discovery_questionnaire/tab_WTP.png';

export const PlottTypesArray = [
  'OLAC',
  'EL',
  'EN',
  'SED',
  'ATL',
  'MATS',
  'WTP',
];

export type PlottTypes = 'OLAC' | 'EL' | 'EN' | 'SED' | 'ATL' | 'MATS' | 'WTP';
export type PlottForms =
  | OLACForm
  | ELForm
  | ENForm
  | SEDForm
  | ATLForm
  | MATSForm
  | WTPForm;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: '4rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0rem',
      },
    },
    displayImage: {
      width: '10rem',
      zIndex: 1,
    },
    cardContainer: {
      margin: '2rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    ribbonContainer: {
      position: 'relative',
      left: '-2rem',
      textAlign: 'center',
      justifyContent: 'center',
      width: '20rem',
    },
    ribbon: {
      width: '100%',
    },
    titleText: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      fontWeight: 'bold',
    },
  })
);

const PLOTTQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const childID = String(location.state);
  const goToOLACQuestionnaire = (e: any) => {
    history.push(route.teacher.olacQuestionnaire, childID);
  };
  const goToELQuestionnaire = (e: any) => {
    history.push(route.teacher.elQuestionnaire, childID);
  };
  const goToENQuestionnaire = (e: any) => {
    history.push(route.teacher.enQuestionnaire, childID);
  };
  const goToSEDQuestionnaire = (e: any) => {
    history.push(route.teacher.sedQuestionnaire, childID);
  };
  const goToATLQuestionnaire = (e: any) => {
    history.push(route.teacher.atlQuestionnaire, childID);
  };
  const goToWTPQuestionnaire = (e: any) => {
    history.push(route.teacher.wtpQuestionnaire, childID);
  };
  const goToMATSQuestionnaire = (e: any) => {
    history.push(route.teacher.matsQuestionnaire, childID);
  };

  return (
    <div className={classes.main}>
      <Grid container justifyContent="center" alignItems="center">
        <QuestionnaireCard
          title="Oral Language and Communication"
          func={goToOLACQuestionnaire}
          picture={OLAC_picture}
          ribbon={OLAC_ribbon}
        />
        <QuestionnaireCard
          title="Reading through Play"
          func={goToELQuestionnaire}
          picture={EL_picture}
          ribbon={EL_ribbon}
        />
        <QuestionnaireCard
          title="Numeracy through Play"
          func={goToENQuestionnaire}
          picture={EN_picture}
          ribbon={EN_ribbon}
        />
        <QuestionnaireCard
          title="Social and Emotional Engagement"
          func={goToSEDQuestionnaire}
          picture={SED_picture}
          ribbon={SED_ribbon}
        />
        <QuestionnaireCard
          title="Executive Functioning through Play"
          func={goToATLQuestionnaire}
          picture={ATL_picture}
          ribbon={ATL_ribbon}
        />
        <QuestionnaireCard
          title="Writing Through Play"
          func={goToWTPQuestionnaire}
          picture={WTP_picture}
          ribbon={WTP_ribbon}
        />
        <QuestionnaireCard
          title="More About the Student"
          func={goToMATSQuestionnaire}
          picture={MATS_picture}
          ribbon={MATS_ribbon}
        />
      </Grid>
    </div>
  );
};

const QuestionnaireCard: React.FunctionComponent<{
  title: string;
  func: any;
  picture: string;
  ribbon: string;
}> = ({title, func, picture, ribbon}) => {
  const classes = useStyles();

  return (
    <div onClick={func} className={classes.cardContainer}>
      <img src={picture} className={classes.displayImage}></img>
      <div className={classes.ribbonContainer}>
        <img src={ribbon} className={classes.ribbon}></img>
        <Typography variant="h6" color="primary" className={classes.titleText}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default withRouter(PLOTTQuestionnaire);
