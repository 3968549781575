import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ParentRegistrationSchema} from '../../schema';
import {FormikBag} from 'formik';
import {InputModels} from '../../components/FormInputs';
import Signup from '../../components/Signup';

// Used for documentation purposes
export interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  firstname: string;
  lastname: string;
  relationshipToChild: string;
  otherRelationship?: string;
  children: [];
  childExperienceInterview: string;
  agreeChildDisseminationSpeech: string;
  agreeChildDisseminationVideo: string;
}

type ParentRegistrationProps = RouteComponentProps<any> & {
  formOnSubmit: (
    values: FormValues,
    formikBag: FormikBag<object, FormValues>
  ) => void;
  formInitialValues: FormValues;
};

const ParentRegistration: React.FunctionComponent<ParentRegistrationProps> = (
  props: ParentRegistrationProps
) => {
  const {formOnSubmit, formInitialValues} = props;

  const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
    const lst = [];
    if (!labelArr) labelArr = valArr;
    for (let i = 0; i < valArr.length; i++)
      lst.push({value: valArr[i], label: labelArr[i]});
    return lst;
  };

  const formModel: InputModels[] = [
    {
      type: 'textfield',
      key: 'email',
      data: {
        id: 'email',
        label: 'Email',
        type: 'email',
      },
    },
    {
      type: 'textfield',
      key: 'password',
      data: {
        id: 'password',
        label: 'Password',
        type: 'password',
      },
    },
    {
      type: 'textfield',
      key: 'confirmPassword',
      data: {
        id: 'confirmPassword',
        label: 'Confirm Password',
        type: 'password',
      },
    },
    {
      type: 'textfield',
      key: 'firstname',
      data: {
        id: 'firstname',
        label: 'First Name',
        type: 'name',
      },
    },
    {
      type: 'textfield',
      key: 'lastname',
      data: {
        id: 'lastname',
        label: 'Last Name',
        type: 'name',
      },
    },
    {
      type: 'select',
      key: 'relationshipToChild',
      data: {
        id: 'relationshipToChild',
        label: 'Relationship to Child',
        menuItems: generateMenuItems(
          ['parent', 'guardian', 'grandparent', 'foster parent', 'other'],
          ['Parent', 'Guardian', 'Grandparent', 'Foster Parent', 'Other']
        ),
      },
    },
    {
      type: 'textfield',
      key: 'otherRelationship',
      data: {
        id: 'otherRelationship',
        inputText: 'Specify your relationship',
        label: 'Other Relationship to Child',
        type: 'number',
        showWhen: {
          type: 'isValue',
          id: 'relationshipToChild',
          value: 'other',
        },
      },
    },
    {
      type: 'radio',
      key: 'childExperienceInterview',
      data: {
        id: 'childExperienceInterview',
        label:
          'I agree for my child(ren) to participate in a learner experience interview.',
        radios: [
          {value: 'yes', label: 'Yes'},
          {value: 'no', label: 'No'},
        ],
      },
    },
    {
      type: 'radio',
      key: 'agreeChildDisseminationSpeech',
      data: {
        id: 'agreeChildDisseminationSpeech',
        label:
          'For data dissemination activities, I agree on the usage of my child(ren)s speech data for research purposes',
        radios: [
          {value: 'yes', label: 'Yes'},
          {value: 'no', label: 'No'},
        ],
      },
    },
    {
      type: 'radio',
      key: 'agreeChildDisseminationVideo',
      data: {
        id: 'agreeChildDisseminationVideo',
        label:
          'For data dissemination activities, I agree on the usage of my child(ren)s video data for research purposes',
        radios: [
          {value: 'yes', label: 'Yes'},
          {value: 'no', label: 'No'},
        ],
      },
    },
  ];

  return (
    <>
      <Signup
        title="Parent Registration and Consent"
        submitText="Continue to Child Registration"
        recaptcha
        formSchema={ParentRegistrationSchema}
        formInitialValues={formInitialValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
      ></Signup>
    </>
  );
};

export default withRouter(ParentRegistration);
