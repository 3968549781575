import React, {FunctionComponent} from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import route from '../routes/route';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import module0Img from '../static/img/modules/dashboard/Intro_icon.png';
import module1Img from '../static/img/modules/dashboard/Books_icon.png';
import module2Img from '../static/img/modules/dashboard/Phonics_icon.png';
import module3Img from '../static/img/modules/dashboard/Numbers_icon.png';
import module4Img from '../static/img/modules/dashboard/Reading_icon.png';
import module5Img from '../static/img/modules/dashboard/Spelling_icon.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: '4rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0rem',
      },
    },
    displayImage: {
      width: '30rem',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const ModuleDashboard: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  // This function goes to module 0 page
  const goToModule0 = (e: any) => {
    history.push(`/${route.modules.module0}`, e.currentTarget.id);
  };

  return (
    <div className={classes.main}>
      <Grid container justifyContent="center" alignItems="center">
        <ModuleCard title="START" img={module0Img} func={goToModule0} />
        <ModuleCard title="BOOKS" img={module1Img} />
        <ModuleCard title="LETTERS" img={module2Img} />
        <ModuleCard title="NUMBERS" img={module3Img} />
        <ModuleCard title="STORIES" img={module4Img} />
        <ModuleCard title="WRITING" img={module5Img} />
      </Grid>
    </div>
  );
};

const ModuleCard: FunctionComponent<{
  title: string;
  func?: any;
  img?: string;
}> = ({title, func, img}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Typography
        variant="h5"
        color="primary"
        style={{
          fontWeight: 'bold',
          position: 'absolute',
          top: '67%',
          left: 'auto',
        }}
      >
        {title}
      </Typography>
      <img src={img} onClick={func} className={classes.displayImage}></img>
    </div>
  );
};

export default withRouter(ModuleDashboard);
