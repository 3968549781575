import React, {useState} from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import route from '../../routes/route';
import {Button, Paper} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles, createStyles} from '@mui/styles';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from '../../theming/colors';
import {Page0} from './Page0';
import {Page1} from './Page1';
import {Page2} from './Page2';
import {Page3} from './Page3';
import {Page4} from './Page4';
import {Page5} from './Page5';
import {Page6} from './Page6';
import {Page7} from './Page7';
import {Page8} from './Page8';
import {Page9} from './Page9';
import {Page10} from './Page10';
import {Page11} from './Page11';
import {Page12} from './Page12';
import CancelIcon from '@mui/icons-material/Cancel';
import {IconButton} from '@mui/material';
import {Avatar} from '@mui/material';
import backgroundImg from '../../static/img/blue_sky.png';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      position: 'relative',
      margin: 'auto',
      backgroundImage: `url(${backgroundImg})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      overflowY: 'auto',
    },
    btnGroup: {
      position: 'absolute',
      bottom: '5%',
      right: '5%',
    },
    cancelIcon: {
      color: '#fff',
    },
  })
);

function LinearProgressWithLabel(props: any) {
  const percent = (props.value * 100) / props.total;
  const history = useHistory();
  const classes = useStyles();
  const handleExit = () => {
    history.push(`/${route.modules.moduleDashboard}`);
  };
  const handleAudioPlay = () => {
    props.setPlayingAudio(!props.playingAudio);
  };
  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Box sx={{minWidth: 35}}>
        <IconButton onClick={handleExit}>
          <CancelIcon className={classes.cancelIcon} />
        </IconButton>
      </Box>
      <Box sx={{width: '80%', mr: 1}}>
        <LinearProgress
          variant="determinate"
          value={percent}
          sx={{height: 8, backgroundColor: '#fff'}}
        />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2" sx={{color: '#fff'}}>{`${Math.round(
          percent
        )}% (${props.value + 1}/${props.total + 1})`}</Typography>
      </Box>
      <Box sx={{minWidth: 35}}>
        <IconButton onClick={handleAudioPlay}>
          {props.playingAudio ? (
            <PauseCircleIcon className={classes.cancelIcon} />
          ) : (
            <PlayCircleIcon className={classes.cancelIcon} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}

const Module0: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [playingAudio, setPlayingAudio] = useState(true);

  // Go to the next part of the module
  const updateProgress = (num: number) => {
    if (progress + num >= 0 && progress + num <= pagesNumber) {
      setProgress(progress + num);
      setPlayingAudio(true);
    }
    // keep this commented out so we don't have to wait for dialogue to continue while in development stage
    // setDisableButton(true);
  };
  const submit = () => {
    history.push(`/${route.modules.moduleDashboard}`);
  };

  const pageIndexer = [
    Page0,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
  ];

  const pageContent = () => {
    const Page = pageIndexer[progress];
    return (
      <Page
        setDisableButton={setDisableButton}
        playingAudio={playingAudio}
        setPlayingAudio={setPlayingAudio}
      />
    );
  };

  const pagesNumber = Object.keys(pageIndexer).length - 1;

  return (
    <Paper className={classes.container}>
      <LinearProgressWithLabel
        value={progress}
        total={pagesNumber}
        playingAudio={playingAudio}
        setPlayingAudio={setPlayingAudio}
      />
      {pageContent()}
      <div className={classes.btnGroup}>
        {progress !== pagesNumber ? (
          <IconButton
            disabled={disableButton}
            size="large"
            onClick={() => updateProgress(1)}
          >
            <Avatar
              sx={{
                bgcolor: disableButton ? colors.lightGrey : colors.success,
                height: 70,
                width: 70,
              }}
            >
              <ArrowRightAltIcon sx={{fontSize: 75}} />
            </Avatar>
          </IconButton>
        ) : (
          <Button
            disabled={disableButton}
            variant="contained"
            size="large"
            onClick={() => submit()}
            sx={{
              bgcolor: disableButton ? colors.lightGrey : '#D62246',
              '&:hover': {
                backgroundColor: '#b01c39',
              },
            }}
          >
            {' '}
            <DoneIcon /> &nbsp; Submit{' '}
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default withRouter(Module0);
