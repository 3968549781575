import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {OLACQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      padding: '6px',
      backgroundColor: colors.warning,
    },
  })
);

export interface OLACForm {
  GP1_OLAC: string;
  GP2_OLAC: string;
  FP1_OLAC: string;
  FP2_OLAC: string;
  FP3_OLAC: string;
  IP1_OLAC: string;
  IP2_OLAC: string;
  IP3_OLAC: string;
  IP4_OLAC: string;
  ConP1_OLAC: string;
  RBP1_OLAC: string;
  RBP2_OLAC: string;
}

const OLACQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.elQuestionnaire;
  const prev = 'None';
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultOLACValues: OLACForm = {
    GP1_OLAC: '',
    GP2_OLAC: '',
    FP1_OLAC: '',
    FP2_OLAC: '',
    FP3_OLAC: '',
    IP1_OLAC: '',
    IP2_OLAC: '',
    IP3_OLAC: '',
    IP4_OLAC: '',
    ConP1_OLAC: '',
    RBP1_OLAC: '',
    RBP2_OLAC: '',
  };

  const defaultOLACExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;

  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childOLAC = childPLOTT.OLAC;
          if (childOLAC) {
            defaultOLACValues = childOLAC;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'OLAC');
          if (uploadedFiles)
            defaultOLACExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'OLAC'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'OLAC'
  );

  const formOnSubmit = async (values: OLACForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('OLAC')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'OLAC'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'GP1_OLAC',
      data: {
        id: 'GP1_OLAC',
        label: 'Uses a wide range of vocabulary words while playing.​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP2_OLAC',
      data: {
        id: 'GP2_OLAC',
        label: 'Verbalizes actions with details​',
        labelInfoPopup:
          'e.g., I am trying to make a castle; Here is a menu; You take this shovel.',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP1_OLAC',
      data: {
        id: 'FP1_OLAC',
        label: 'Recognizes and manipulates individual sounds of words.​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_OLAC',
      data: {
        id: 'FP2_OLAC',
        label:
          'Pronounce words or articulates sounds clearly, without substituting sounds.​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP3_OLAC',
      data: {
        id: 'FP3_OLAC',
        label: 'Clearly expresses interest and/or intent in play.​',
        labelInfoPopup: 'e.g., Can I go outside?; I want to play with blocks.',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP1_OLAC',
      data: {
        id: 'IP1_OLAC',
        label: 'Negotiates multiple roles.​',
        labelInfoPopup: 'e.g., various roles in house',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP2_OLAC',
      data: {
        id: 'IP2_OLAC',
        label:
          'Retells, reenacts or dramatizes stories with or without picture cues.',
        labelInfoPopup:
          'e.g., Then the little pig runs to his brother’s house like this',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP3_OLAC',
      data: {
        id: 'IP3_OLAC',
        label: 'Tells a story/share a personal experience.​',
        labelInfoPopup: 'e.g., Yesterday my dad and I went to the bank...',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP4_OLAC',
      data: {
        id: 'IP4_OLAC',
        label: 'Demonstrates curiosity about the meanings of spoken words.​',
        labelInfoPopup:
          'e.g., Ask what words mean; experiment with new words when speaking.',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP1_OLAC',
      data: {
        id: 'ConP1_OLAC',
        label: 'Carries a conversation with another individual.​​',
        labelInfoPopup: 'e.g., send and receive related ideas',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP1_OLAC',
      data: {
        id: 'RBP1_OLAC',
        label: 'Negotiates rules and spontaneously invent strategies.​​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP2_OLAC',
      data: {
        id: 'RBP2_OLAC',
        label: 'Understands ideas said to them on the first try.​​​​',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Oral Language and Communication through Play"
        submitText={'Submit Questionnaire'}
        formSchema={OLACQuestionnaireSchema}
        formInitialValues={defaultOLACValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.olac}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultOLACExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(OLACQuestionnaire);
