import React, {FunctionComponent} from 'react';
import {withRouter, Redirect} from 'react-router';
import {useSelector} from 'react-redux';
import {RootState} from './features/rootReducer';
import {ACCOUNT_TYPES} from './features/users';
import route from './routes/route';
import TeacherHome from './components/TeacherHome';
const Home: FunctionComponent = () => {
  const user = useSelector((state: RootState) => state.user);
  const children213 = useSelector((state: RootState) => state.children);

  if (user.accountType === ACCOUNT_TYPES.PARENT) {
    if (Object.keys(children213).length > 0) {
      return <Redirect to={`/${route.childrensView}`} />;
    } else {
      return <Redirect to={`/${route.parentHome}`} />;
    }
  } else {
    return <Redirect to={`/${route.teacherHome}`} />;
  }
};

export default withRouter(Home);
