import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import store, {AppDispatch, AppThunk} from './store';
import {classesCollection} from '../firebase-app';
export interface Class {
  className: string;
  childrenIDs: string[];
  grade: string;
  classID: string;
}

const initialState: {[index: string]: Class} = {};

const classSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    loadData: (
      state: {[index: string]: Class},
      action: PayloadAction<{[index: string]: Class}>
    ) => {
      Object.assign(state, action.payload);
    },
  },
});

export const fetchClassesData = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    const classesList: {[index: string]: Class} = {};

    const state = store.getState();
    const classIDs = state.user.classIDs;

    if (classIDs) {
      for (let i = 0; i < classIDs.length; i++) {
        const classDocRef = (
          await classesCollection.doc(classIDs[i]).get()
        ).data();

        let currentClass: Class;
        if (classDocRef) {
          currentClass = {
            className: classDocRef.className,
            grade: classDocRef.grade,
            childrenIDs: classDocRef.childrenIDs,
            classID: classIDs[i],
          };

          classesList[classIDs[i]] = currentClass;
        }
      }
    }
    dispatch(classSlice.actions.loadData(classesList));
  };
};

export default classSlice.reducer;
