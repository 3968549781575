import React, {FunctionComponent, useEffect} from 'react';
import giraffe from '../../static/img/avatars/giraffe_transparent.png';
import reading_on_bed from '../../static/img/modules/reading_on_bed_1.png';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {CardMedia, Grid, Typography} from '@material-ui/core';
import {Button} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ReactHowler from 'react-howler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    octopus0: {
      width: '40vh',
      height: '40vh',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
    reading: {
      width: '30rem',
    },
    btn: {
      margin: '2rem 1rem',
    },
    yes: {
      color: 'green',
    },
    no: {
      color: 'red',
    },
  })
);

export const Page7: FunctionComponent<{
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  playingAudio: boolean;
  setPlayingAudio: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setDisableButton, playingAudio, setPlayingAudio}) => {
  const classes = useStyles();
  const endAudio = () => {
    setDisableButton(false);
    setPlayingAudio(false);
  };

  return (
    <Grid container className={classes.container}>
      <ReactHowler
        src={require('./mp3/module0audio7.mp3')}
        playing={playingAudio}
        onEnd={() => endAudio()}
      />
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.leftContainer}>
          <CardMedia image={giraffe} className={classes.octopus0} />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.rightContainer}>
          <img src={reading_on_bed} className={classes.reading} />

          <Grid container justifyContent="center" alignItems="center">
            <Grid item className={classes.btn}>
              <Button variant="outlined" color="success">
                <CheckIcon className={classes.yes} />
                <Typography>Yes</Typography>
              </Button>
            </Grid>
            <Grid item className={classes.btn}>
              <Button variant="outlined" color="error">
                <ClearIcon className={classes.no} />
                <Typography>No</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
