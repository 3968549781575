import {combineReducers} from '@reduxjs/toolkit';
import user from './users';
import children from './children';
import classes from './classes';
import configurations from './configurations';
import log from 'loglevel';

const rootReducer = combineReducers({user, children, classes, configurations});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

log.trace('Initiating root reducers.');
