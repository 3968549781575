import React, {useState} from 'react';
import {RouteComponentProps, withRouter, useHistory} from 'react-router-dom';
import ParentRegistration, {
  FormValues as RegistrationFormValues,
} from './signup_pages/ParentRegistration';
import ChildRegistration, {
  FormValues as ChildRegistrationFormValues,
} from './signup_pages/ChildRegistration';
import HomeLearningDiscovery, {
  FormValues as HomeLearningFormValues,
} from './signup_pages/HomeLearningDiscovery';
import AboutMyChildsDevelopment, {
  FormValues as AboutMyChildFormValues,
} from './signup_pages/AboutMyChildsDevelopment';
import HomeDemographicQuestions, {
  FormValues as HomeDemographicFormValues,
} from './signup_pages/HomeDemographicQuestions';
import firebase from 'firebase/app';
import store from '../features/store';
import {store as storen} from 'react-notifications-component';
import {
  parentConsentCollection,
  usersCollection,
  classesCollection,
  childrenCollection,
} from '../firebase-app';
import route from '../routes/route';
import {ACCOUNT_TYPES} from '../features/users';
import {fetchChildrenData} from '../features/children';
import {fetchUserInfo} from '../features/users';
import {createNotification} from '../notifications/notification';
import SignupHelpers, {questionnaireOnBack} from './SignupHelpers';
import Spinner from '../components/Spinner';

type StudentSignupProps = RouteComponentProps<any>;

const ParentSignup: React.FunctionComponent<StudentSignupProps> = () => {
  const pageNames = [
    'parent registration and consent',
    'child registration',
    'home learning discovery',
    'about my childs development',
    'home demographic questions',
    'submit',
  ];
  const history = useHistory();

  const [regisInitVals, SetRegisInitVals] = useState<RegistrationFormValues>(
    SignupHelpers.regisSetup
  );
  const [childRegInitVals, SetChildRegInitVals] =
    useState<ChildRegistrationFormValues>(SignupHelpers.childRegSetup);
  const [homeLearningInitVals, SetHomeLearningInitVals] =
    useState<HomeLearningFormValues>(SignupHelpers.homeLearningSetup);
  const [aboutMyChildInitVals, SetAboutMyChildInitVals] =
    useState<AboutMyChildFormValues>(SignupHelpers.aboutMyChildSetup);
  const [homeDemographicInitVals, setHomeDemographicInitVals] =
    useState<HomeDemographicFormValues>(SignupHelpers.homeDemographicSetup);

  const [currentPage, setCurrentPage] = useState(0);

  const registrationFormOnSubmit = async (values: RegistrationFormValues) => {
    await SetRegisInitVals(values);
    firebase
      .auth()
      .fetchSignInMethodsForEmail(values['email'])
      .then((e: string[]) => {
        if (e && e[0] === 'password') {
          // email already exists
          createNotification(
            'Email already exists!',
            `Please use a different email. Email: ${values.email} is in use.`,
            'danger'
          );
        } else {
          setCurrentPage(currentPage + 1);
        }
      });
  };

  const childRegistrationFormOnSubmit = async (
    values: ChildRegistrationFormValues
  ) => {
    await SetChildRegInitVals(values);
    // Look up teacher and see if classID provided
    const teacher = await (
      await usersCollection.doc(values.teacher.id).get()
    ).data();
    if (teacher) {
      if (teacher.classIDs) {
        const classID = values.classID.toUpperCase().trim();
        if (teacher.classIDs.includes(classID)) {
          const teacherClass = await (
            await classesCollection.doc(classID).get()
          ).data();
          if (teacherClass) {
            if (teacherClass.grade) {
              if (teacherClass.grade !== values.grade) {
                createNotification(
                  'The class code does not match the grade that the student is in',
                  'Please try again',
                  'danger'
                );
                return;
              }
            }
          }
        } else {
          createNotification(
            'The class code is wrong',
            'Please try again',
            'danger'
          );
          return;
        }
      }
    }
    // Class code is valid and the class matches the grade that the student is in
    setCurrentPage(currentPage + 1);
  };

  const HomeLearningDiscoveryOnSubmit = async (
    values: HomeLearningFormValues
  ) => {
    await SetHomeLearningInitVals(values);
    console.log('submitting home learning discovery');
    setCurrentPage(currentPage + 1);
  };

  const AboutMyChildsDevelopmentOnSubmit = async (
    values: AboutMyChildFormValues
  ) => {
    await SetAboutMyChildInitVals(values);

    console.log('submitting about my childs development');
    setCurrentPage(currentPage + 1);
  };

  const homeDemographicOnSubmit = async (values: HomeDemographicFormValues) => {
    await setHomeDemographicInitVals(values);

    console.log('submitting home demographic questions');
    console.log(
      regisInitVals,
      childRegInitVals,
      homeLearningInitVals,
      aboutMyChildInitVals,
      homeDemographicInitVals
    );
    setCurrentPage(currentPage + 1);
  };

  // Submit registration info for parent
  const submitRegistration = async (
    user: firebase.User | null,
    data: RegistrationFormValues
  ) => {
    if (user !== null) {
      return usersCollection.doc(user.uid).set({
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        relationshipToChild: data.relationshipToChild,
        otherRelationship: data.otherRelationship,
        children: [],
        childExperienceInterview: data.childExperienceInterview,
        agreeChildDisseminationSpeech: data.agreeChildDisseminationSpeech,
        agreeChildDisseminationVideo: data.agreeChildDisseminationVideo,
        accountType: ACCOUNT_TYPES.PARENT,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      });
    }
  };

  const submitChildRegistrationData = async (
    user: firebase.User | null,
    child_registration_data: ChildRegistrationFormValues
  ) => {
    if (user === null) {
      return 'Error 4';
    }
    let childID = '';
    await childrenCollection
      .add({
        ...child_registration_data,
        parentID: user.uid,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .then(async docRef => {
        // Add the child to the parent.
        childID = await docRef.id;
        usersCollection
          .doc(user.uid)
          .update({
            childrenIDs: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          })
          .then(async () => {
            // Add the child to the class
            const classID = child_registration_data.classID
              .toUpperCase()
              .trim();
            await classesCollection
              .doc(classID)
              .update({
                childrenIDs: firebase.firestore.FieldValue.arrayUnion(childID),
              })
              .catch(() => {
                createNotification(
                  'Child registration failed. Error: 1',
                  'Please try again',
                  'danger'
                );
                return 'Error 1';
              });
            // Add success notification
            // Update our state variable of children to get the new one
            // await store.dispatch(fetchUserInfo(user));
            await store.dispatch(fetchChildrenData());
            console.log('Child added successfully');
            // history.push(`${route.home}`);
            return childID;
          })
          .catch(() => {
            createNotification(
              'Child registration failed. Error: 2',
              'Please try again',
              'danger'
            );
            return 'Error 2';
          });
      })
      .catch(() => {
        createNotification(
          'Child registration failed. Error: 3',
          'Please try again',
          'danger'
        );
        return 'Error 3';
      });
    return childID;
  };

  const submitHomeLearningData = async (
    user: firebase.User | null,
    childID: string,
    home_learning_data: HomeLearningFormValues
  ) => {
    if (user !== null) {
      await childrenCollection
        .doc(childID)
        .collection('Additional')
        .doc('HomeLearning')
        .set({
          ...home_learning_data,
          dateSubmitted: firebase.firestore.Timestamp.now(),
        })
        .catch(() => {
          createNotification(
            'Failed to fill out the questionnaire',
            'Please try again',
            'danger'
          );
        })
        .then(async () => {
          console.log('Home learning form filled out successfully');
        });
    }
  };

  const submitMyChildData = async (
    user: firebase.User | null,
    childID: string,
    about_my_child_data: AboutMyChildFormValues
  ) => {
    if (user !== null) {
      await childrenCollection
        .doc(childID)
        .collection('Additional')
        .doc('AboutMyChild')
        .set({
          ...about_my_child_data,
          dateSubmitted: firebase.firestore.Timestamp.now(),
        })
        .catch(() => {
          createNotification(
            'Failed to fill out the questionnaire',
            'Please try again',
            'danger'
          );
        })
        .then(async () => {
          console.log('About My Child Form filled out successfully');
        });
    }
  };

  const submitHomeDemographicData = async (
    user: firebase.User | null,
    home_demographic_data: HomeDemographicFormValues
  ) => {
    if (user !== null) {
      await usersCollection
        .doc(user.uid)
        .update({
          ...home_demographic_data,
          dateSubmitted: firebase.firestore.Timestamp.now(),
        })
        .catch(() => {
          createNotification(
            'Failed to fill out the questionnaire',
            'Please try again',
            'danger'
          );
        })
        .then(async () => {
          console.log('Home Demographic Form filled out successfully');
        });
    }
  };

  // Submit questionnaire info for parent
  const submitData = async (
    registration_data: RegistrationFormValues,
    child_registration_data: ChildRegistrationFormValues,
    home_learning_data: HomeLearningFormValues,
    about_my_child_data: AboutMyChildFormValues,
    home_demographic_data: HomeDemographicFormValues
  ) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        registration_data.email,
        registration_data.password
      )
      .then((cred: firebase.auth.UserCredential) => {
        const unsubscribe = store.subscribe(async () => {
          const state = store.getState();
          if (state.user.uid !== '' && state.user.uid) {
            unsubscribe();

            await submitRegistration(cred.user, registration_data);
            const childID: string = await submitChildRegistrationData(
              cred.user,
              child_registration_data
            );
            await submitHomeLearningData(
              cred.user,
              childID,
              home_learning_data
            );
            await submitMyChildData(cred.user, childID, about_my_child_data);
            await submitHomeDemographicData(cred.user, home_demographic_data);

            createNotification(
              'Registration form successfully submitted!',
              'Thank you very much for participation!',
              'success'
            );
            history.push('/');
            return;
          }
        });
      })
      .catch(() => {
        console.log('firebase error');
        createNotification(
          'Failed to submit registration form! Error: 1',
          'Please try again!',
          'danger'
        );
        history.push(`/${route.signup.parent}`);
      });
  };

  const HandleSubmission: React.FunctionComponent = () => {
    submitData(
      regisInitVals,
      childRegInitVals,
      homeLearningInitVals,
      aboutMyChildInitVals,
      homeDemographicInitVals
    );
    return <Spinner />;
  };

  const renderPage = () => {
    switch (pageNames[currentPage]) {
      case 'parent registration and consent':
        return (
          <ParentRegistration
            formOnSubmit={registrationFormOnSubmit}
            formInitialValues={regisInitVals}
          />
        );
      case 'child registration':
        return (
          <ChildRegistration
            onBack={questionnaireOnBack}
            formOnSubmit={childRegistrationFormOnSubmit}
            formInitialValues={childRegInitVals}
          />
        );
      case 'home learning discovery':
        return (
          <HomeLearningDiscovery
            onBack={questionnaireOnBack}
            formOnSubmit={HomeLearningDiscoveryOnSubmit}
            formInitialValues={homeLearningInitVals}
          />
        );
      case 'about my childs development':
        return (
          <AboutMyChildsDevelopment
            onBack={questionnaireOnBack}
            formOnSubmit={AboutMyChildsDevelopmentOnSubmit}
            formInitialValues={aboutMyChildInitVals}
          />
        );
      case 'home demographic questions':
        return (
          <HomeDemographicQuestions
            onBack={questionnaireOnBack}
            formOnSubmit={homeDemographicOnSubmit}
            formInitialValues={homeDemographicInitVals}
          />
        );
      case 'submit':
        return <HandleSubmission />;
    }
    return <h1>Error Occured</h1>;
  };

  return renderPage();
};

export default withRouter(ParentSignup);
