import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {LoginSchema} from '../schema';
import {withFormik, FormikProps} from 'formik';
import {
  Button,
  TextField,
  CardContent,
  CardActions,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {compose} from 'redux';
import CardCustom from '../components/CardCustom';
import {Person, Lock} from '@material-ui/icons';
import firebase from 'firebase/app';
import 'firebase/auth';
import log from 'loglevel';
import '../static/css/WhitespaceFiller.css';
import colors from '../theming/colors';

interface FormValues {
  email: string;
  password: string;
}

type LoginProps = RouteComponentProps<any> & FormikProps<FormValues>;

const useStyle = makeStyles({
  card: {
    maxWidth: 420,
    marginTop: 50,
  },
  container: {
    display: 'Flex',
    justifyContent: 'center',
    height: '80vh',
  },
  actions: {
    float: 'right',
  },
  submitButton: {
    textTransform: 'none',
  },
});

const Login: React.SFC<LoginProps> = (props: LoginProps) => {
  const classes = useStyle();
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    dirty,
    isValid,
    isSubmitting,
    handleSubmit,
  } = props;

  const formFields = (
    <>
      <CardContent>
        <TextField
          id="email"
          label="Email"
          type="email"
          margin="dense"
          variant="outlined"
          autoComplete="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          margin="dense"
          variant="outlined"
          autoComplete="current-password"
          fullWidth
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Lock />
              </InputAdornment>
            ),
          }}
        />
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          id="login-submit-button"
          color="primary"
          variant="contained"
          disabled={!(isValid && dirty) || isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          className={classes.submitButton}
          type="submit"
        >
          Log In
        </Button>
      </CardActions>
    </>
  );

  return (
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justifyContent="center"
    >
      <form onSubmit={handleSubmit}>
        <CardCustom
          maxWidth="420px"
          color={colors.primary}
          alignHeader="center"
          title="Welcome to BalanceAI"
          titleColor={colors.white}
          cardBody={formFields}
        />
      </form>
    </Grid>
  );
};

const formikForm = withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: LoginSchema,
  handleSubmit: (values, formikBag) => {
    const {email, password} = values;
    // Log in the user
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(err => {
        // TODO: Add notification for error dispatched
        log.error(err);
      });
    formikBag.setSubmitting(false);
  },
});

export default compose(withRouter, formikForm)(Login);
