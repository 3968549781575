import React, {FunctionComponent, useState, useEffect} from 'react';
import giraffe from '../../static/img/avatars/giraffe_transparent.png';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import teacherImg from '../../static/img/avatars/owl_transparent.png';
import parentImg from '../../static/img/avatars/giraffe_transparent.png';
import dinosaurImg from '../../static/img/avatars/dinosaur.png';
import lionImg from '../../static/img/avatars/lion.png';
import robotImg from '../../static/img/avatars/robot_trasparent.png';
import pandaImg from '../../static/img/avatars/panda.png';
import tigerImg from '../../static/img/avatars/tiger.png';
import {CardMedia, Grid, Button} from '@material-ui/core';
import ReactHowler from 'react-howler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    octopus0: {
      width: '40vh',
      height: '40vh',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
    buttonsSelected: {
      height: 'auto',
      width: 'auto',
      margin: '0.5rem',
      variant: 'contained',
      display: ' inline-block',
      border: '0.1rem solid red',
    },
    buttons: {
      height: 'auto',
      width: 'auto',
      margin: '0.5rem',
      variant: 'contained',
      display: ' inline-block',
      border: '0.1rem solid #000',
    },
    avatarSelectionImage: {
      height: '10rem',
      width: '10rem',
    },
  })
);

export const Page1: FunctionComponent<{
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  playingAudio: boolean;
  setPlayingAudio: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setDisableButton, playingAudio, setPlayingAudio}) => {
  const [activeAvatar, setActiveAvatar] = useState('none');
  const classes = useStyles();

  const changeAvatarIndex = async () => {};

  const changeAvatarCached = async (newAvatar: string) => {
    setActiveAvatar(newAvatar);
  };

  const endAudio = () => {
    setDisableButton(false);
    setPlayingAudio(false);
  };
  return (
    <Grid container className={classes.container}>
      <ReactHowler
        src={require('./mp3/module0audio1.mp3')}
        playing={playingAudio}
        onEnd={() => endAudio()}
      />
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.leftContainer}>
          <CardMedia image={giraffe} className={classes.octopus0} />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.rightContainer}>
          <Grid container item xs={12} justifyContent="center" spacing={0}>
            <Button
              className={
                activeAvatar === 'teacherImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('teacherImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={teacherImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'parentImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('parentImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={parentImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'dinosaurImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('dinosaurImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={dinosaurImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'lionImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('lionImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={lionImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'robotImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('robotImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={robotImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'pandaImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('pandaImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={pandaImg}
              />
            </Button>
            <Button
              className={
                activeAvatar === 'tigerImg'
                  ? classes.buttonsSelected
                  : classes.buttons
              }
              onClick={() => changeAvatarCached('tigerImg')}
            >
              <CardMedia
                className={classes.avatarSelectionImage}
                image={tigerImg}
              />
            </Button>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
