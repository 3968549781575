import React, {FunctionComponent} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles({
  gridContainer: {
    minHeight: '100vh',
  },
});

const Spinner: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <CircularProgress size={80} />
    </Grid>
  );
};

export default Spinner;
