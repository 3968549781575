import React, {FunctionComponent} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {ChildRegistrationSchema} from '../../schema';
import Signup from '../../components/Signup';
import {FormikBag} from 'formik';

// Interface of what the registration form for a child looks like
export interface ChildInformation {
  firstname: string;
  lastname: string;
  birthdate: string;
  gender: string;
  grade: string;
  schoolName: string;
  teacher: {name: string; id: string};
  classID: string;
}

export interface ChildDemographic {
  country: string;
  city: string;
  bornInCanada: string;
  ageMoved?: number | any; // any is for default '' state;
  siblingsNumber: string;
  ethnicity: string;
  firstNations: string;
  familySpeakEnglish: string;
  childSpeakEnglish: string;
  moreAboutLanguagesAtHome: string;
  moreAboutChildLanguage: string;
}

export type FormValues = ChildInformation & ChildDemographic;

type ChildRegistrationProps = RouteComponentProps<any> & {
  formOnSubmit: (
    values: FormValues,
    formikBag: FormikBag<object, FormValues>
  ) => void;
  formInitialValues: FormValues;
  onBack: (values: FormValues) => void;
};

const ChildRegistration: React.FunctionComponent<ChildRegistrationProps> = (
  props: ChildRegistrationProps
) => {
  const {formOnSubmit, formInitialValues, onBack} = props;
  const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
    const lst = [];
    if (!labelArr) labelArr = valArr;
    for (let i = 0; i < valArr.length; i++)
      lst.push({value: valArr[i], label: labelArr[i]});
    return lst;
  };

  const formModel: InputModels[] = [
    {
      type: 'text',
      key: 'intro',
      data: {
        text: 'Kindly tell us about your child so we can better support them.',
      },
    },
    {
      type: 'textfield',
      key: 'firstname',
      data: {
        id: 'firstname',
        label: 'First Name',
        type: 'name',
      },
    },
    {
      type: 'textfield',
      key: 'lastname',
      data: {
        id: 'lastname',
        label: 'Last Name',
        type: 'name',
      },
    },
    {
      type: 'textfield',
      key: 'birthdate',
      data: {
        id: 'birthdate',
        label: 'Date of Birth',
        type: 'date',
      },
    },
    {
      type: 'select',
      key: 'grade',
      data: {
        id: 'grade',
        label: 'Grade',
        menuItems: generateMenuItems([
          'Jr. Kindergarten',
          'Sr. Kindergarten',
          'Kindergarten Exit',
        ]),
      },
    },
    {
      type: 'select',
      key: 'schoolName',
      data: {
        id: 'schoolName',
        inputText: 'Name of Early Childhood Programme or School',
        label: 'Name of School or Programme',
        type: 'activeSchool',
      },
    },
    {
      type: 'select',
      key: 'teacher',
      data: {
        id: 'teacher',
        label: 'Teacher Name',
        type: 'teacher',
        showWhen: {
          type: 'notEmptyString',
          id: 'schoolName',
        },
      },
    },
    {
      type: 'textfield',
      key: 'classID',
      data: {
        id: 'classID',
        label: 'Class ID',
        showWhen: {
          type: 'notValue',
          id: 'teacher',
          value: formInitialValues.teacher,
        },
      },
    },
    {
      type: 'textfield',
      key: 'country',
      data: {
        id: 'country',
        inputText: 'Where do you and your child live?',
        label: 'Country',
        type: 'country',
      },
    },
    {
      type: 'textfield',
      key: 'city',
      data: {
        id: 'city',
        label: 'City',
        type: 'city',
      },
    },
    {
      type: 'select',
      key: 'bornInCanada',
      data: {
        id: 'bornInCanada',
        label: 'Was your child born in canada?',
        menuItems: generateMenuItems(['Yes', 'No']),
        showWhen: {
          type: 'isValue',
          id: 'country',
          value: 'Canada',
        },
      },
    },
    {
      type: 'textfield',
      key: 'ageMoved',
      data: {
        id: 'ageMoved',
        inputText:
          'If they were not born in Canada, At what age did they move to Canada?',
        label: 'Age',
        // type: 'number',
        showWhen: {
          type: 'isValue',
          id: 'bornInCanada',
          value: 'No',
        },
      },
    },
    {
      type: 'select',
      key: 'gender',
      data: {
        id: 'gender',
        label: 'Gender',
        menuItems: generateMenuItems([
          'Female',
          'Male',
          'Non-Binary',
          'Other',
          'Prefer not to say',
        ]),
      },
    },
    {
      type: 'select',
      key: 'siblingsNumber',
      data: {
        id: 'siblingsNumber',
        inputText: 'How many other siblings does your child have',
        label: 'Number of Siblings',
        menuItems: generateMenuItems([
          'None',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10+',
        ]),
      },
    },
    {
      type: 'select',
      key: 'ethnicity',
      data: {
        id: 'ethnicity',
        inputText: 'Kindly indicate your child’s racial/ethnic identify',
        label: 'Race/Ethnicity',
        menuItems: generateMenuItems([
          'Arab ',
          'Black (e.g. African, American, Canadian, Caribbean)',
          'Chinese',
          'Filipino',
          'Japanese',
          'Korean ',
          'Latin American ',
          'Mixed/Bi-Racial/Multi-Racial',
          'South Asian (e.g. East Indian, Pakistani, Sri Lankan, Indo-Caribbean)',
          'Southeast Asian (e.g. Vietnamese, Cambodian, Laotian, Thai)',
          'West Asian (e.g. Afghan, Iranian)',
          'White',
          'Other',
          'Prefer not to answer',
        ]),
      },
    },
    {
      type: 'select',
      key: 'firstNations',
      data: {
        id: 'firstNations',
        inputText:
          'Does your child identify as First Nations, Metis, or Inuit?',
        label: 'First Nations',
        menuItems: generateMenuItems(
          ['no', 'firstnations', 'metis', 'intuit', 'other'],
          ['No', 'First Nations', 'Metis', 'Inuit', 'Other']
        ),
      },
    },
    {
      type: 'select',
      key: 'familySpeakEnglish',
      data: {
        id: 'familySpeakEnglish',
        inputText: 'Do you and other family members speak English at home?',
        label: 'Language Spoken at home',
        menuItems: generateMenuItems([
          'Yes, all the time.',
          'Sometimes. We speak other languages as well.',
          'No. We speak other languages most or all the time.',
        ]),
      },
    },
    {
      type: 'select',
      key: 'childSpeakEnglish',
      data: {
        id: 'childSpeakEnglish',
        inputText: 'Does your child speak English?',
        label: 'Child Language Speaking',
        menuItems: generateMenuItems([
          'Yes, all the time.',
          'Sometimes. My child speaks other language(s) as well.',
          'No. My child speaks other language(s) only.',
        ]),
      },
    },
    {
      type: 'textfield',
      key: 'moreAboutLanguagesAtHome',
      data: {
        id: 'moreAboutLanguagesAtHome',
        inputText:
          'Would you like to share more about languages used in your home?',
        label: 'More about languages at home',
        type: 'moreAboutLanguagesAtHome',
      },
    },
    {
      type: 'textfield',
      key: 'moreAboutChildLanguage',
      data: {
        id: 'moreAboutChildLanguage',
        inputText:
          'Would you like to share more about your child’s language skills?',
        label: 'More about childs language',
        type: 'moreAboutChildLanguage',
      },
    },
  ];
  return (
    <>
      <Signup
        title="Child Registration"
        submitText="Continue to Home Learning Discovery"
        formSchema={ChildRegistrationSchema}
        formInitialValues={formInitialValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
      ></Signup>
    </>
  );
};

export default withRouter(ChildRegistration);
