import React from 'react';
import {LinearProgress, LinearProgressProps} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import colors from '../theming/colors';

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: (props: ColoredLinearProgressProps) => props.primaryColor,
  },
  barColorPrimary: {
    backgroundColor: (props: ColoredLinearProgressProps) =>
      props.barColorPrimary ? props.barColorPrimary : '',
  },
  sticky: {
    position: 'sticky',
    top: 0,
  },
});

const useProgressBarStyles = makeStyles({
  progressBar: {
    height: '8px',
  },
});

type ColoredLinearProgressProps = LinearProgressProps & {
  primaryColor: string;
  barColorPrimary?: string;
  sticky?: boolean; // sticks to the top of the window when scrolling past it
};

const ColoredLinearProgress: React.FunctionComponent<any> = (
  props: ColoredLinearProgressProps
) => {
  const originalComponentProps: any = {...props}; // shallow copy
  delete originalComponentProps.primaryColor;
  delete originalComponentProps.barColorPrimary;
  delete originalComponentProps.sticky;

  const {sticky} = props;

  const classes = useStyles(props);

  return (
    <LinearProgress
      {...originalComponentProps}
      classes={{
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
      className={`${props.className} ${sticky && classes.sticky}`}
    />
  );
};

export default ColoredLinearProgress;

type StickyProgressBar = {
  value: number; // between 0 and 100
};

export const StickyProgressBar: React.FunctionComponent<any> = (
  props: StickyProgressBar
) => {
  const {value} = props;

  const classes = useProgressBarStyles();

  return (
    <ColoredLinearProgress
      variant="determinate"
      value={value}
      primaryColor={colors.successSecondary}
      barColorPrimary={colors.success}
      className={classes.progressBar}
      sticky
    />
  );
};
