import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItem,
  List,
  Drawer,
  ListItemText,
  ListItemIcon,
  ClickAwayListener,
  CardMedia,
} from '@material-ui/core';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import main_logo from '../static/img/main_logo.png';
import {
  RouteComponentProps,
  useHistory,
  withRouter,
  useLocation,
} from 'react-router';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  // Notifications,
  Inbox,
  Mail,
} from '@material-ui/icons';
import anime from 'animejs';
import animations from '../theming/animations';
import {Link} from 'react-router-dom';
import SelectSignup from './SelectSignup';
import SettingsIcon from '@material-ui/icons/Settings';
import SvgIcon from '@material-ui/core/SvgIcon';
import {ACCOUNT_TYPES} from '../features/users';
import route from '../routes/route';
import {useMediaQuery} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

type NavbarProps = RouteComponentProps<any>;
const drawerWidth = 240;
const iconsSize = '2.8rem';
const logoWidth = '5.42rem'; // iconSize * 3028/1564

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      position: 'absolute',
      width: '100vw',
    },
    nothing: {},
    logo: {
      height: iconsSize,
      width: logoWidth,
    },
    unauthButton: {
      margin: '0.5rem',
      border: '0.05rem solid #fff',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    hamburgerIcon: {
      margin: '0 0 0 6px',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      overflowX: 'hidden',
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 1,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    listContainer: {
      overflowX: 'hidden',
    },
    cogWheel: {
      width: iconsSize,
      height: iconsSize,
      color: 'white',
    },
  })
);

const Buttons: React.SFC<{isSmallScreen: boolean; text: string; to: any}> = ({
  isSmallScreen,
  text,
  to,
}) => {
  const classes = useStyles();
  return (
    <Button
      component={Link}
      to={to}
      variant="contained"
      color="primary"
      className={classes.unauthButton}
      size={isSmallScreen ? 'small' : 'medium'}
    >
      {text}
    </Button>
  );
};

const UnauthenticatedView: React.SFC<{}> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const themes = useTheme();
  const isSmallScreen = useMediaQuery(theme => themes.breakpoints.down('xs'));

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        className={classes.unauthButton}
        variant="contained"
        color="primary"
        onClick={handleOpen}
        size={isSmallScreen ? 'small' : 'medium'}
      >
        Sign Up
      </Button>
      {open && <SelectSignup open={open} setOpen={setOpen} />}
      <Buttons
        isSmallScreen={isSmallScreen}
        text="Log In"
        to={{pathname: '/login', state: {}}}
      />
      <Buttons
        isSmallScreen={isSmallScreen}
        text="About"
        to={{pathname: '/about', state: {}}}
      />
    </>
  );
};

const handleLogout = () => {
  // TODO add notification
  firebase.auth().signOut();
};

const ProfileMenu = forwardRef((props: {menuId: string}, ref) => {
  const user = useSelector((state: RootState) => state.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles(props);
  const history = useHistory();
  useImperativeHandle(ref, () => ({
    handleOpen: (event: React.MouseEvent<HTMLElement>) =>
      setAnchorEl(event.currentTarget),
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const goToParentHome = () => {
    history.push(`/${route.parentHome}`);
  };
  const handleClose = () => setAnchorEl(null);
  return (
    <>
      <Button
        aria-controls="Account options"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {/* <CardMedia image={SettingsIcon} className={classes.cogWheel} title="logo" /> */}
        <SvgIcon component={SettingsIcon} className={classes.cogWheel} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={props.menuId}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} title="See account details">
          My account
        </MenuItem>
        {user.accountType === ACCOUNT_TYPES.PARENT ? (
          <MenuItem onClick={goToParentHome} title="Go to parent home">
            Parent Home
          </MenuItem>
        ) : (
          <div></div> //fragment causes a console warning
        )}
        <MenuItem onClick={handleLogout} title="Log out of the current user">
          Log out
        </MenuItem>
      </Menu>
    </>
  );
});

const AuthenticatedViewDesktop: React.SFC<{}> = () => {
  // const classes = useStyles();
  const menuId = 'desktop-profile-menu';
  const profileMenuRef: any = useRef();

  return (
    <>
      <ProfileMenu menuId={menuId} ref={profileMenuRef} />
    </>
  );
};

const AppDrawer = forwardRef((props: {open: boolean}, ref) => {
  const classes = useStyles();
  const listClassName = 'navbar-stacked-list-item';

  useEffect(() => {
    const animation = anime.timeline({loop: false}).add({
      targets: `.${listClassName}`,
      translateX: [-70, 0],
      duration: 500,
      delay: anime.stagger(100),
      autoplay: false,
    });

    props.open ? animation.play() : animation.reverse();
  }, [props.open]);

  return (
    <>
      <Drawer
        ref={ref}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={props.open}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List className={classes.listContainer}>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
              <ListItem className={listClassName} button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <Inbox /> : <Mail />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
});

const Navbar: React.SFC<NavbarProps> = (props: NavbarProps) => {
  const classes = useStyles(props);
  const user = useSelector((state: RootState) => state.user);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const location = useLocation();
  const pathname = location.pathname;

  const handleSidebar = () => setSidebarOpen(!sidebarOpen);
  const appBarId = 'navigation-bar-main';

  useEffect(() => {
    anime.timeline({loop: false}).add({
      targets: `#${appBarId}`,
      translateY: [-30, 0],
      duration: animations.initEnterEffect,
      easing: 'easeInOutCubic',
    });
  }, []);

  return (
    <>
      {pathname.substring(0, 9) !== '/modules/' ? (
        <div className={classes.grow}>
          <ClickAwayListener
            onClickAway={handleSidebar}
            mouseEvent={sidebarOpen ? 'onClick' : false}
          >
            <AppDrawer open={sidebarOpen} />
          </ClickAwayListener>
          <AppBar
            position="relative"
            color="primary"
            id={appBarId}
            className={
              pathname === '/' || pathname === '/childrens_view'
                ? classes.appBar
                : classes.nothing
            }
          >
            <Toolbar disableGutters>
              <Button
                variant="text"
                color="primary"
                size="large"
                disableRipple
                title="Go to home page"
                component={Link}
                to="/"
              >
                <CardMedia
                  image={main_logo}
                  className={classes.logo}
                  title="logo"
                />
              </Button>
              <div className={classes.grow} />
              <div>
                {!user.loggedIn && <UnauthenticatedView />}
                {user.loggedIn && <AuthenticatedViewDesktop />}
              </div>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Navbar);
