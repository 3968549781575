import React, {useState} from 'react';
import {Typography, CardMedia, Button, IconButton} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import colors from '../theming/colors';
import {loadAvatarImage} from '../common-function/loadAvatarImage';
import {SelectChildAvatar} from './SelectChildAvatar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import route from '../routes/route';
import {useHistory, useLocation} from 'react-router';

const childEntrySize = '7rem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      background: 'white',
      margin: '1rem',
      padding: '1rem',
      display: 'flex',
      borderRadius: 10,
      boxShadow: '2px 3px 8px #000',
    },
    cardRightSide: {},
    cardButton: {
      width: '100%',
      marginBottom: '0.5rem',
      borderRadius: 10,
    },
    childListImg: {
      margin: 'auto',
      height: childEntrySize,
      width: childEntrySize,
      border: '0.15rem solid #000',
      cursor: 'pointer',
    },
    childListText: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    avatarContainer: {
      height: childEntrySize,
      width: childEntrySize,
      position: 'relative',
    },
    cameraIcon: {
      height: '1.5rem',
      width: '1.5rem',
      position: 'absolute',
      border: '0.1rem solid black',
      padding: '0.1rem',
      borderRadius: 50,
      bottom: '-0.7rem',
      right: '-0.7rem',
      backgroundColor: colors.white,
      color: '#656565',
      '&:hover': {
        backgroundColor: '#ECECEC',
        boxShadow:
          '0 0.2rem 0.4rem rgba(0,0,0,0.25), 0 0.1rem 0.1rem rgba(0,0,0,0.22)',
      },
    },
  })
);

export interface ChildCard {
  name: string;
  childID: string;
  avatar: string;
  buttons: ChildCardButton[];
}

export interface ChildCardButton {
  label: string;
  func?: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
}

const LoadChildCard = (childInfo: ChildCard) => {
  const history = useHistory();
  const buttonElements: Array<any> = [];
  const img = loadAvatarImage(childInfo.avatar);
  const classes = useStyles();
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [childIDselection, setchildIDselection] = useState('');

  const handleOpen = (childID: string) => {
    setchildIDselection(childID);
    setOpen(true);
  };

  const loadButtons = () => {
    childInfo.buttons.forEach(button => {
      buttonElements.push(
        <Button
          className={classes.cardButton}
          variant="contained"
          onClick={button.func}
          color={button.color ? button.color : 'default'}
          key={childInfo.childID + button.label}
          id={childInfo.childID}
        >
          {' '}
          {button.label}
        </Button>
      );
    });
  };

  // This function goes to the main module selection page
  const goToModuleDashBoard = (e: any) => {
    history.push(`/${route.modules.moduleDashboard}`, e.currentTarget.id);
  };

  loadButtons();

  return (
    <div
      className={classes.cardContainer}
      style={
        pathname === '/childrens_view' ? {width: 'auto'} : {width: '25rem'}
      }
    >
      <div
        style={
          pathname === '/childrens_view'
            ? {marginRight: '0.3rem'}
            : {marginRight: '1rem'}
        }
      >
        <div className={classes.avatarContainer}>
          {pathname !== '/class_dashboard' && (
            <IconButton
              className={classes.cameraIcon}
              onClick={() => handleOpen(childInfo.childID)}
              component="span"
              key={childInfo.childID}
            >
              <CameraAltIcon />
            </IconButton>
          )}
          <CardMedia
            image={img}
            className={classes.childListImg}
            title="logo"
            onClick={
              pathname !== '/class_dashboard' ? goToModuleDashBoard : void 0
            }
          />
        </div>
        {open && (
          <SelectChildAvatar
            childID={childIDselection}
            open={open}
            setOpen={setOpen}
          />
        )}
        <Typography className={classes.childListText}>
          {childInfo.name}
        </Typography>
      </div>
      <div className={classes.cardRightSide}>{buttonElements}</div>
    </div>
  );
};
export default LoadChildCard;
