import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {ATLQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface ATLForm {
  FP1_ATL: string;
  FP2_ATL: string;
  FP3_ATL: string;
  IP1_ATL: string;
  IP2_ATL: string;
  ConP1_ATL: string;
  ColP1_ATL: string;
  ColP2_ATL: string;
  GP1_ATL: string;
  GP2_ATL: string;
}

const ATLQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.wtpQuestionnaire;
  const prev = route.teacher.sedQuestionnaire;
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultATLValues: ATLForm = {
    FP1_ATL: '',
    FP2_ATL: '',
    FP3_ATL: '',
    IP1_ATL: '',
    IP2_ATL: '',
    ConP1_ATL: '',
    ColP1_ATL: '',
    ColP2_ATL: '',
    GP1_ATL: '',
    GP2_ATL: '',
  };

  const defaultATLExtrasValues: any = {
    uploadedFiles: [],
  };
  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childATL = childPLOTT.ATL;
          if (childATL) {
            defaultATLValues = childATL;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'ATL');
          if (uploadedFiles)
            defaultATLExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'ATL'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'ATL'
  );

  const formOnSubmit = async (values: ATLForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('ATL')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'ATL'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'FP1_ATL',
      data: {
        id: 'FP1_ATL',
        label: 'Settles down after exciting activities.​​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_ATL',
      data: {
        id: 'FP2_ATL',
        label:
          'Adapts to change in the environment/situation. (e.g., Shifts attention from a puzzle during free play to a cleaning up for lunch time)​​​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP3_ATL',
      data: {
        id: 'FP3_ATL',
        label:
          'Self-monitors learning, pace and quality of learning. (e.g., Chooses increasingly difficult color mosaic puzzles as skills improve) ​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP1_ATL',
      data: {
        id: 'IP1_ATL',
        label:
          'Engages in symbolic play with objects which are increasingly unlike the object represented. (e.g., Uses a block for a phone)​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP2_ATL',
      data: {
        id: 'IP2_ATL',
        label:
          'Engages in symbolic role play by creating roles and situations using gestures and intonation. (e.g., Stirring a pretend cauldron and cackling like a witch)​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP1_ATL',
      data: {
        id: 'ConP1_ATL',
        label:
          'Demonstrates goal setting and persistence in play and projects. (e.g., Plays for 3 days on a road project, adding signs, buildings, bridges, tracks, etc.)​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ColP1_ATL',
      data: {
        id: 'ColP1_ATL',
        label:
          'Remains on-task/focused while working with others- sustained shared thinking. (e.g., Focuses attention to a shared oral story; maintains a superhero play scenario with two friends)​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ColP2_ATL',
      data: {
        id: 'ColP2_ATL',
        label:
          'Assists others such as peer-to-peer learning. (e.g., Teach another child how to complete a numerical task)​​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP1_ATL',
      data: {
        id: 'GP1_ATL',
        label:
          'Retains and recall information. (e.g., Recalls the rules of a game; remembers the preferences of a friend)​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP2_ATL',
      data: {
        id: 'GP2_ATL',
        label:
          'Applies different rules to different settings. (e.g., Differentiates table manners practiced at home from those at school; plays various games that have different rules)​',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Executive Functioning through Play"
        submitText={'Submit Questionnaire'}
        formSchema={ATLQuestionnaireSchema}
        formInitialValues={defaultATLValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.atl}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultATLExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(ATLQuestionnaire);
