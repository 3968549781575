export default {
  regisSetup: {
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    relationshipToChild: '',
    otherRelationship: '',
    children: [] as any,
    childExperienceInterview: '',
    agreeChildDisseminationSpeech: '',
    agreeChildDisseminationVideo: '',
  },
  childRegSetup: {
    firstname: '',
    lastname: '',
    birthdate: '',
    gender: '',
    grade: '',
    schoolName: '',
    teacher: {name: '', id: ''},
    classID: '',
    country: '',
    city: '',
    bornInCanada: '',
    ageMoved: '',
    siblingsNumber: '',
    ethnicity: '',
    firstNations: '',
    familySpeakEnglish: '',
    childSpeakEnglish: '',
    moreAboutLanguagesAtHome: '',
    moreAboutChildLanguage: '',
  },
  homeLearningSetup: {
    childrenBooks: '',
    activity1: '',
    activity2: '',
    activity3: '',
    activity4: '',
    activity5: '',
    readingToChild: '',
  },
  aboutMyChildSetup: {
    OLC1: '',
    OLC2: '',
    OLC3: '',
    OLC4: '',
    OLC5: '',
    OLC6: '',
    OLC7: '',
    OLC8: '',
    OLC9: '',

    ERW1: '',
    ERW2: '',
    ERW3: '',
    ERW4: '',
    ERW5: '',
    ERW6: '',
    ERW7: '',
    ERW8: '',
    ERW9: '',
    ERW10: '',
    ERW11: '',
    ERW12: '',
    ERW13: '',

    EN1: '',
    EN2: '',
    EN3: '',
    EN4: '',
    EN5: '',
    EN6: '',
    EN7: '',
    EN8: '',
    EN9: '',
    EN10: '',
    EN11: '',

    SEEFS1: '',
    SEEFS2: '',
    SEEFS3: '',
    SEEFS4: '',
    SEEFS5: '',
    SEEFS6: '',
    SEEFS7: '',
    SEEFS8: '',
    SEEFS9: '',
    SEEFS10: '',
    SEEFS11: '',
    SEEFS12: '',
    SEEFS13: '',
    SEEFS14: '',
    SEEFS15: '',

    MS1: '',
    MS2: '',
    MS3: '',
    MS4: '',
    MS5: '',
    MS6: '',
    MS7: '',
    MS8: '',
    MS9: '',
  },
  homeDemographicSetup: {
    educationParent1: '',
    educationParent2: '',
    maritalStatus: '',
    annualIncome: '',
  },
};

export const questionnaireOnBack = (values: any) => {
  // setQuestionInitVals(values);
  // setCurrentPage(currentPage - 1);
};
