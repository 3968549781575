import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {SEDQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface SEDForm {
  FP1_SED: string;
  FP2_SED: string;
  FP3_SED: string;
  RBP1_SED: string;
  RBP2_SED: string;
  ColP1_SED: string;
  ColP2_SED: string;
  IP1_SED: string;
  ConP1_SED: string;
  GP1_SED: string;
}

const SEDQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.atlQuestionnaire;
  const prev = route.teacher.enQuestionnaire;
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultSEDValues: SEDForm = {
    FP1_SED: '',
    FP2_SED: '',
    FP3_SED: '',
    RBP1_SED: '',
    RBP2_SED: '',
    ColP1_SED: '',
    ColP2_SED: '',
    IP1_SED: '',
    ConP1_SED: '',
    GP1_SED: '',
  };

  const defaultSEDExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childSED = childPLOTT.SED;
          if (childSED) {
            defaultSEDValues = childSED;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'SED');
          if (uploadedFiles)
            defaultSEDExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'SED'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'SED'
  );

  const formOnSubmit = async (values: SEDForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('SED')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'SED'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'FP1_SED',
      data: {
        id: 'FP1_SED',
        label: 'Recognizes & communicates how they feel.​​',
        labelInfoPopup:
          'e.g., happy, sad, frustrated, angry, hungry, tired, thirsty, etc.',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_SED',
      data: {
        id: 'FP2_SED',
        label: 'Follows rules and routines.​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP3_SED',
      data: {
        id: 'FP3_SED',
        label:
          'Demonstrates respect for differences or diversities in background or abilities.​',
        labelInfoPopup:
          'e.g., Tilts an easel so a child in a wheelchair can reach the paint cups',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP1_SED',
      data: {
        id: 'RBP1_SED',
        label: 'Try to regulates emotions and articulate feeling in words.​',
        labelInfoPopup: 'e.g., when frustrated, upset, excited',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP2_SED',
      data: {
        id: 'RBP2_SED',
        label:
          'Reflects on relationships of all players within the framework of the rules and use that to formulate a strategy.​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ColP1_SED',
      data: {
        id: 'ColP1_SED',
        label: 'Accepts responsibility for their own actions.​',
        labelInfoPopup: '',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ColP2_SED',
      data: {
        id: 'ColP2_SED',
        label:
          'Respect others’ rights and property Shows respect for others’ belongings.',
        labelInfoPopup:
          'e.g., Taking care to walk a wide circle around a tall block tower made by another child',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP1_SED',
      data: {
        id: 'IP1_SED',
        label:
          'Negotiates with peers to resolve social conflicts and cooperate in play.​',
        labelInfoPopup:
          'e.g., Negotiate roles of pilot and co-pilot in spaceship play',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'ConP1_SED',
      data: {
        id: 'ConP1_SED',
        label:
          'Demonstrates curiosity and knowledge about their environment and the world.​',
        labelInfoPopup: 'e.g., There are many fires burning up our forests’',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP1_SED',
      data: {
        id: 'GP1_SED',
        label: 'Expresses empathy or caring for others.​​',
        labelInfoPopup:
          'e.g., Gives a hug to a child that falls and hurts his knee',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Social and Emotional Engagement through Play​"
        submitText={'Submit Questionnaire'}
        formSchema={SEDQuestionnaireSchema}
        formInitialValues={defaultSEDValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.sed}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultSEDExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(SEDQuestionnaire);
