/**
 * 1. User can upload files
 * 2. User can view uploaded files
 * 3. User can delete uploaded files
 */

import React, {useCallback} from 'react';
import {DropzoneDialog} from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import FileBrowser from 'react-keyed-file-browser';

import {downloadFromUrl} from '../functions/fileStorage';
import {Grid} from '@mui/material';

export interface PlottFile {
  name: string;
  downloadUrl: string;
  size: number;
  modified?: string;
}

export type PlottFiles = {[name: string]: PlottFile};

interface FileUploadFormProps {
  acceptedFiles: string[]; // accepted file MIME Types
  maxFileSize: number;
  showPreviews: boolean;
  buttonText: string;
  onSave: (files: File[]) => any;
}

export const FileUploadForm: React.FunctionComponent<FileUploadFormProps> = (
  props: FileUploadFormProps
) => {
  const [open, setOpen] = React.useState(false);

  const {onSave, acceptedFiles, maxFileSize, buttonText} = props;

  const defaultOptions = {
    acceptedFiles: acceptedFiles || ['image/*', '.pdf'],
    maxFileSize: maxFileSize || 3 * 2 ** 20, // 3
    buttonText: buttonText || 'Upload Files',
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        {defaultOptions.buttonText}
      </Button>

      <DropzoneDialog
        acceptedFiles={defaultOptions.acceptedFiles}
        cancelButtonText={'Cancel'}
        submitButtonText={'Save'}
        maxFileSize={defaultOptions.maxFileSize}
        open={open}
        onClose={() => setOpen(false)}
        onSave={files => {
          onSave(files);
          setOpen(false);
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </div>
  );
};

interface PlottFileDirectoryProps {
  files: {key: string; size: number; modified?: string; downloadUrl: string}[];
  onDeleteFiles: (keys: string[]) => any;
}

export const PlottFileDirectory: React.FunctionComponent<PlottFileDirectoryProps> =
  (props: PlottFileDirectoryProps) => {
    const {files, onDeleteFiles} = props;

    const getFileFromKey = (key: string) =>
      files.find(file => file.key === key);

    // props defined in documentation for FileBrowser
    const ConfirmDeletionButton = (props: {handleDeleteSubmit: any}) => {
      const {handleDeleteSubmit} = props;

      return (
        <Button
          color="primary"
          variant="outlined"
          onClick={handleDeleteSubmit}
          style={{height: '30px'}}
        >
          Confirm Deletion
        </Button>
      );
    };

    const ConfirmMultipleDeletionRenderer = ConfirmDeletionButton;

    // basic template retrieved from https://github.com/uptick/react-keyed-file-browser/blob/master/src/confirmations/default.js
    const ConfirmDeletionRenderer = (props: {
      children: any;
      handleDeleteSubmit: any;
    }) => {
      const {children, handleDeleteSubmit} = props;

      return (
        <form className="deleting" onSubmit={handleDeleteSubmit}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item>
              <a>{children}</a>
            </Grid>
            <Grid item>
              <ConfirmDeletionButton handleDeleteSubmit={handleDeleteSubmit} />
            </Grid>
          </Grid>
        </form>
      );
    };

    // documentation for FileBrowser component:
    // https://github.com/uptick/react-keyed-file-browser/blob/master/src/browser.js
    return (
      // <FileBrowser
      //   files={files}
      //   // icons={{
      //   // //   File: <InsertDriveFileIcon />,
      //   // //   Image: <ImageIcon />,
      //   // //   PDF: <PictureAsPdfIcon />,
      //   // //   Delete: <span />,
      //   // //   Loading: <DownloadingIcon />,
      //   // }}
      //   canFilter={false}
      //   showActionBar={true}
      //   detailRenderer={() => <></>} // remove the item detail visual
      //   confirmDeletionRenderer={ConfirmDeletionRenderer}
      //   confirmMultipleDeletionRenderer={ConfirmMultipleDeletionRenderer}
      //   onDeleteFile={onDeleteFiles} // called on empty list if more than one file
      //   onDeleteFolder={() => null} // also called when multiple files are deleted at once but with empty list
      //   onDownloadFile={(keys: string[]) => {
      //     keys.forEach(key => {
      //       const file = getFileFromKey(key);

      //       console.log(file);
      //       if (!file) return;

      //       downloadFromUrl(file.downloadUrl, key);
      //     });
      //   }}
      // />
      <></>
    );
  };
