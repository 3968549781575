import React, {FunctionComponent} from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  CardMedia,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import colors from '../theming/colors';
import teacherImg from '../static/img/avatars/owl_transparent.png';
import parentImg from '../static/img/avatars/giraffe_transparent.png';
import {Link} from 'react-router-dom';
import route from '../routes/route';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialogTitle: {
    backgroundColor: colors.primary,
    color: colors.white,
    textAlign: 'center',
  },
  dialogContentText: {
    textAlign: 'center',
    color: colors.black,
    fontWeight: 'bold',
    fontSize: '2.4rem',
  },
  dialogContent: {
    display: 'inline',
  },
  imageOwl: {
    height: '14rem',
    width: '14rem',
  },

  imageGiraffe: {
    height: '14rem',
    width: '14rem',
  },
  buttons: {
    height: 'auto',
    width: 'auto',
    marginRight: '20px',
    variant: 'contained',
    display: ' inline-block',
    border: '0.1rem solid #000',
  },
  buttonText: {
    textAlign: 'center',
    color: colors.black,
    fontWeight: 'bold',
    fontSize: '2rem',
  },
});

// This component loads a modal and the user chooses if they are a
// Parent or a teacher and that would lead them to the forms
const SelectSignup: FunctionComponent<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({open, setOpen}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Sign Up
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          <Typography className={classes.dialogContentText}>
            I am a...
          </Typography>
        </DialogContentText>
        <Button
          className={classes.buttons}
          component={Link}
          to={{pathname: `/${route.consent.teacher}`, state: {}}}
          onClick={handleClose}
        >
          <DialogContentText>
            <Typography className={classes.buttonText}>Teacher</Typography>
          </DialogContentText>
          <CardMedia className={classes.imageOwl} image={teacherImg} />
        </Button>
        <Button
          className={classes.buttons}
          component={Link}
          to={{pathname: `/${route.consent.parent}`, state: {}}}
          onClick={handleClose}
        >
          <DialogContentText>
            <Typography className={classes.buttonText}>Parent</Typography>
          </DialogContentText>
          <CardMedia className={classes.imageGiraffe} image={parentImg} />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSignup;
