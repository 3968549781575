import React, {FunctionComponent, useEffect} from 'react';
import giraffe from '../../static/img/avatars/giraffe_transparent.png';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {CardMedia, Grid, Button} from '@material-ui/core';
import book0 from '../../static/img/modules/book0.png';
import book1 from '../../static/img/modules/book1.png';
import book2 from '../../static/img/modules/book2.png';
import book3 from '../../static/img/modules/book3.png';
import book4 from '../../static/img/modules/book4.png';
import book5 from '../../static/img/modules/book5.png';
import book6 from '../../static/img/modules/book6.png';
import book7 from '../../static/img/modules/book7.png';
import ReactHowler from 'react-howler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    octopus0: {
      width: '40vh',
      height: '40vh',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
    booksContainer: {},
    book: {
      width: '10rem',
      height: 'auto',
      margin: '1rem',
    },
  })
);

export const Page6: FunctionComponent<{
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  playingAudio: boolean;
  setPlayingAudio: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setDisableButton, playingAudio, setPlayingAudio}) => {
  const classes = useStyles();
  const endAudio = () => {
    setDisableButton(false);
    setPlayingAudio(false);
  };

  return (
    <Grid container className={classes.container}>
      <ReactHowler
        src={require('./mp3/module0audio6.mp3')}
        playing={playingAudio}
        onEnd={() => endAudio()}
      />
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.leftContainer}>
          <CardMedia image={giraffe} className={classes.octopus0} />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.rightContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.booksContainer}
          >
            <Grid item>
              <Button>
                <img src={book0} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book1} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book2} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book3} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book4} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book5} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book6} className={classes.book} />
              </Button>
            </Grid>
            <Grid item>
              <Button>
                <img src={book7} className={classes.book} />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
