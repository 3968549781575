import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppThunk} from './store';
import {usersCollection} from '../firebase-app';
import log from 'loglevel';

export enum ACCOUNT_TYPES {
  PARENT = 'parent',
  TEACHER = 'teacher',
}
export interface User {
  email: string;
  loggedIn: boolean;
  accountType: ACCOUNT_TYPES | null;
  uid: string;
  name: {first: string; last: string};
  childrenIDs?: [];
  grades?: [];
  classIDs?: string[];
}

const initialState: User = {
  email: '',
  loggedIn: false,
  uid: '',
  accountType: null,
  name: {
    first: '',
    last: '',
  },
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser: (state: User, action: PayloadAction<User>) => {
      Object.assign(state, action.payload);
    },
    setUserInfo: (
      state: User,
      action: PayloadAction<{
        accountType: User['accountType'];
        name: User['name'];
      }>
    ) => {
      Object.assign(state, {
        accountType: action.payload.accountType,
        name: action.payload.name,
      });
    },
    setParentInfo: (
      state: User,
      action: PayloadAction<{
        childrenIDs: User['childrenIDs'];
      }>
    ) => {
      Object.assign(state, {
        childrenIDs: action.payload.childrenIDs,
      });
    },
    setTeacherInfo: (
      state: User,
      action: PayloadAction<{
        grades: User['grades'];
        classIDs: User['classIDs'];
      }>
    ) => {
      Object.assign(state, {
        grades: action.payload.grades,
        classIDs: action.payload.classIDs,
      });
    },
    updateTeacherclassIDs: (
      state: User,
      action: PayloadAction<{
        classIDs: User['classIDs'];
      }>
    ) => {
      Object.assign(state, {
        classIDs: action.payload.classIDs,
      });
    },
  },
});

export const setUser = (user: User): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(userSlice.actions.setUser(user));
  };
};

export const fetchUserInfo = (user: User): AppThunk => {
  return async (dispatch: AppDispatch) => {
    if (user && user.uid) {
      return usersCollection
        .doc(user.uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data: any = doc.data();
            if (data['accountType'] === ACCOUNT_TYPES.PARENT) {
              dispatch(
                userSlice.actions.setParentInfo({
                  childrenIDs: data['childrenIDs'],
                })
              );
            } else if (data['accountType'] === ACCOUNT_TYPES.TEACHER) {
              dispatch(
                userSlice.actions.setTeacherInfo({
                  grades: data['grades'],
                  classIDs: data['classIDs'],
                })
              );
            }
            dispatch(
              userSlice.actions.setUserInfo({
                accountType: data['accountType'],
                name: {first: data['firstname'], last: data['lastname']},
              })
            );
          }
        });
    }
  };
};

export const updateTeacherclassIDs = (updatedclassIDs: string[]): AppThunk => {
  return async (dispatch: AppDispatch) => {
    return dispatch(
      userSlice.actions.updateTeacherclassIDs({
        classIDs: updatedclassIDs,
      })
    );
  };
};
export default userSlice.reducer;

log.trace('Initiating User Slice.');
