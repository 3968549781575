import React from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import {Typography, Grid, CardMedia} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import colors from './theming/colors';
import brain from './static/img/apple-icon.png';
import landingPage_desktop from './static/img/landingpage/landingpage_desktop.png';
import landingPage_mobile from './static/img/landingpage/landingpage_mobile.png';
import landingPage_tablet from './static/img/landingpage/landingpage_tablet.png';
import Paper from '@material-ui/core/Paper';

type LandingPageProps = RouteComponentProps<any>;
interface CommonCardLayoutProps extends LandingPageProps {
  title: string;
  color: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBody: {
      display: 'flex',
      textAlign: 'center',
      margin: '2rem',
      alignItems: 'center',
    },
    text: {
      fontSize: '0.85rem',
    },
    gridContainer: {
      backgroundImage: `url(${landingPage_desktop})`,
      '@media (min-width: 768px) and (max-width: 1365px)': {
        backgroundImage: `url(${landingPage_tablet})`,
      },
      '@media (max-width: 767px)': {
        backgroundImage: `url(${landingPage_mobile})`,
      },

      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      overflowY: 'auto',
    },
    gridItem: {
      width: '350px',
      [theme.breakpoints.up('md')]: {
        '&:not(:last-child)': {
          marginRight: '60px',
        },
      },
    },
    logo: {
      width: '10vh',
      height: '10vh',
      margin: 'auto',
      marginRight: '1rem',
    },
    icon: {
      fontSize: 40,
      margin: '0 0 4px 0',
      color: colors.darkGrey,
    },
    videoContainer: {
      minHeight: '60vh',
      minWidth: '80vh',
      backgroundColor: colors.darkGrey,
    },
    titleText: {
      color: colors.primary,
      margin: 'auto',
      fontSize: '450%',
    },
  })
);

const LandingPage: React.SFC<LandingPageProps> = (props: LandingPageProps) => {
  const classes = useStyles(props);

  return <Paper className={classes.gridContainer}></Paper>;
};

export default withRouter(LandingPage);
