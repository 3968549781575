import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppThunk} from './store';
import {configurationCollection} from '../firebase-app';
import log from 'loglevel';

export interface Configurations {
  plottFilesStoragePath: string; // path to folder in which all uploaded plott files are stored
}

const initialState: Configurations = {
  plottFilesStoragePath: '',
};

const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setConfiguration: (
      state: Configurations,
      action: PayloadAction<Configurations>
    ) => {
      Object.assign(state, action.payload);
    },
  },
});

export const fetchAndSetConfigurations = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    configurationCollection
      .doc('storage_folder')
      .get()
      .then((snapshot: any) => {
        const data = snapshot.data();
        const plottFilesStoragePath = data['plottFiles'];
        dispatch(
          configurationsSlice.actions.setConfiguration({plottFilesStoragePath})
        );
      });
  };
};

export default configurationsSlice.reducer;

log.trace('Initiating Configuration Slice.');
