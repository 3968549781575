import React, {FunctionComponent, useState} from 'react';
import {Grid, Typography, CardMedia, Button} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {withRouter, useLocation} from 'react-router';
import colors from '../theming/colors';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import {loadAvatarImage} from '../common-function/loadAvatarImage';
import LoadChildCard, {ChildCard} from './ChildCard';
import {useHistory} from 'react-router';
import route from '../routes/route';
const childEntrySize = '15vh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBody: {
      textAlign: 'center',
      margin: '16px 0px 24px 0px',
    },
    childListText: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    text: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    gridContainer: {
      margin: '5vh 0',
    },
    gridItem: {
      width: '340px',
      [theme.breakpoints.up('md')]: {
        '&:not(:last-child)': {
          marginRight: '60px',
        },
      },
    },
    icon: {
      fontSize: 40,
      margin: '0 0 4px 0',
      color: colors.darkGrey,
    },
    media: {
      height: '80px',
      objectFit: 'contain',
    },
    titleText: {
      marginTop: '5vh',
      color: colors.primary,
      margin: 'auto',
      fontSize: '400%',
      textAlign: 'center',
    },
    childrenViewButton: {
      backgroundColor: '#FFA216',
      color: 'white',
      fontSize: '200%',
      padding: '5px 30px',
      borderRadius: 50,
    },
    addChildButton: {
      marginTop: '2vh',
      backgroundColor: '#A8A8A8',
      color: 'white',
      fontSize: '150%',
      padding: '5px 30px',
      borderRadius: 50,
    },
    guardianAvatar: {
      height: '35vh',
      width: '35vh',
      position: 'absolute',
      marginLeft: '5vh',
      borderRadius: '50%',
      border: '0.15rem solid #003459',
    },
    childListContainer: {
      marginTop: '10vh',
    },
    childList: {
      marginTop: '1vh',
    },
    childListImg: {
      height: childEntrySize,
      width: childEntrySize,
    },
    childListEntry: {
      margin: '0.5rem',
      height: childEntrySize,
      width: childEntrySize,
      backgroundColor: 'white',
      borderRadius: '20%',
      boxShadow: '0.1rem 0.1rem',
    },
    plus: {
      height: '2.5rem',
      width: '2.5rem',
      marginRight: '0.5rem',
    },
    arrangement: {},
    classTitles: {
      backgroundColor: 'white',
      width: '25rem',
      margin: 'auto',
      padding: '1rem',
      borderRadius: 10,
      boxShadow: '2px 3px 8px #000',
      marginBottom: '3rem',
    },
  })
);

const ClassDashboard: FunctionComponent = () => {
  const classes = useStyles();
  // Get the class ID that we are looking at through the id that got pushed in history.push
  const location = useLocation();
  const classID = String(location.state);
  // use useSelect if we are in a react function componenet
  const childrenState = useSelector((state: RootState) => state.children);
  const classesState = useSelector((state: RootState) => state.classes);
  const history = useHistory();

  const childElements: Array<any> = [];

  const goToPLOTT = (e: any) => {
    history.push(`/${route.teacher.plottQuestionnaire}`, e.currentTarget.id);
  };

  const goToStudentProgress = (e: any) => {
    history.push(`/${route.teacher.studentProgress}`, e.currentTarget.id);
  };

  const createChildElement = () => {
    // Look through the childrenIDs of that class (basically the students that are in it)
    for (const id of classesState[classID].childrenIDs) {
      const img = loadAvatarImage(childrenState[id].avatar);
      const childInfo: ChildCard = {
        name: childrenState[id].name.first,
        avatar: childrenState[id].avatar,
        childID: id,
        buttons: [
          {
            label: 'Student Progress',
            color: 'primary',
            func: goToStudentProgress,
          },
          {
            label: 'School Learning Discovery',
            func: goToPLOTT,
          },
        ],
      };

      childElements.push(
        <div key={childInfo.childID}>{LoadChildCard(childInfo)}</div>
      );
    }
  };

  createChildElement();

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className={classes.gridContainer}
      >
        <div className={classes.childListContainer}>
          <div className={classes.classTitles}>
            <Typography className={classes.text} variant="h3">
              <b>Class name:</b> {classesState[classID].className}
            </Typography>
            <Typography className={classes.text} variant="h3">
              <b>Class code:</b> {classesState[classID].classID}
            </Typography>
            <Typography className={classes.text} variant="h3">
              <b>Class grade:</b> {classesState[classID].grade}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
            className={classes.childList}
          >
            {childElements}
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default withRouter(ClassDashboard);
