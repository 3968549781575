import React, {FunctionComponent, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import {Grid, Typography, CardMedia, Button} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import colors from '../theming/colors';
import teacherImg from '../static/img/avatars/owl_transparent.png';
import parentImg from '../static/img/avatars/giraffe_transparent.png';
import dinosaurImg from '../static/img/avatars/dinosaur.png';
import lionImg from '../static/img/avatars/lion.png';
import robotImg from '../static/img/avatars/robot_trasparent.png';
import pandaImg from '../static/img/avatars/panda.png';
import tigerImg from '../static/img/avatars/tiger.png';
import {childrenCollection} from '../firebase-app';
import {fetchChildrenData} from '../features/children';
import store from '../features/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: colors.primary,
      color: colors.white,
      textAlign: 'center',
    },
    dialogContentText: {
      textAlign: 'center',
      color: colors.black,
      fontWeight: 'bold',
      fontSize: '2.4rem',
    },
    dialogContent: {
      display: 'inline',
    },
    avatarSelectionImage: {
      height: '10rem',
      width: '10rem',
    },
    buttons: {
      height: 'auto',
      width: 'auto',
      margin: '0.5rem',
      variant: 'contained',
      display: ' inline-block',
      border: '0.1rem solid #000',
    },
    buttonsSelected: {
      height: 'auto',
      width: 'auto',
      margin: '0.5rem',
      variant: 'contained',
      display: ' inline-block',
      border: '0.1rem solid red',
    },
    buttonText: {
      textAlign: 'center',
      color: colors.black,
      fontWeight: 'bold',
      fontSize: '2rem',
    },
    imagesContainer: {},
    actionButton: {
      border: '0.1rem solid #000',
      backgroundColor: colors.primary,
      color: colors.white,
      marginLeft: '1rem',
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      margin: '1rem',
    },
  })
);

export const SelectChildAvatar: FunctionComponent<{
  childID: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({childID, open, setOpen}) => {
  const classes = useStyles();
  const [activeAvatar, setActiveAvatar] = useState('none');

  const handleClose = () => {
    setOpen(false);
  };

  const changeAvatarIndex = async () => {
    childrenCollection.doc(childID).update({
      avatar: activeAvatar,
    });
    await store.dispatch(fetchChildrenData());
    handleClose();
  };

  const changeAvatarCached = async (newAvatar: string) => {
    setActiveAvatar(newAvatar);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        Avatar Selection
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          <Typography component={'span'} className={classes.dialogContentText}>
            Select an image
          </Typography>
        </DialogContentText>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          spacing={0}
          className={classes.imagesContainer}
        >
          <Button
            className={
              activeAvatar === 'teacherImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('teacherImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={teacherImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'parentImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('parentImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={parentImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'dinosaurImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('dinosaurImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={dinosaurImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'lionImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('lionImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={lionImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'robotImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('robotImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={robotImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'pandaImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('pandaImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={pandaImg}
            />
          </Button>
          <Button
            className={
              activeAvatar === 'tigerImg'
                ? classes.buttonsSelected
                : classes.buttons
            }
            onClick={() => changeAvatarCached('tigerImg')}
          >
            <CardMedia
              className={classes.avatarSelectionImage}
              image={tigerImg}
            />
          </Button>
        </Grid>
      </DialogContent>
      <div className={classes.actionContainer}>
        <Button
          onClick={activeAvatar !== 'none' ? changeAvatarIndex : handleClose}
          color="primary"
          variant="contained"
          className={classes.actionButton}
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          color="primary"
          variant="contained"
          className={classes.actionButton}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};
