import React, {useState} from 'react';
import CardCustom from '../components/CardCustom';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button, Typography} from '@material-ui/core';
import InfoPopup from '../components/InfoPopup';
import {InfoPopupProps} from '../components/InfoPopup';
import oiseLogo from '../static/img/oise-logo.png';
import {FormikProps, FormikBag, Formik} from 'formik';
import {Schema} from 'yup';
import RecaptchaContainer from './RecaptchaContainer';
import colors from '../theming/colors';
import {
  typeToInput,
  FormStateProps,
  InputModels,
  ExtendedFormState,
} from './FormInputs';

interface Props<T> {
  recaptcha?: boolean; // whether to have a recaptcha or not
  title: string; // title of the consent form
  description: JSX.Element; // text of the description
  postDescription?: string; // text of the description after the infoPopups
  infoPopups: InfoPopupProps[]; // information popup that show up under the text
  formSchema: Schema<any>; // form schema from schema.ts
  formInitialValues: T; // initial values of the form where keys are identical to model key
  formModel: InputModels[]; // model to build form off
  formOnSubmit: (values: T, formikBag: FormikBag<object, T>) => void;
}

type ConsentFormProps = Props<any>;

const useStyles = makeStyles({
  cardBody: {
    textAlign: 'center',
    margin: '16px 0px 24px 0px',
    padding: '0px 24px 0px 24px',
  },
  cardActions: {
    textAlign: 'center',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
  },
  form: {
    margin: '24px auto',
    width: '50%',
  },
  formInput: {
    alignItems: 'right',
  },
  submitButton: {
    color: colors.primary,
  },
  body: {
    textAlign: 'justify',
  },
});

const ConsentForm: React.FunctionComponent<ConsentFormProps> = (
  props: ConsentFormProps
) => {
  const classes = useStyles(props);
  const {
    recaptcha,
    description,
    postDescription,
    infoPopups,
    formSchema,
    formInitialValues,
    formModel,
    formOnSubmit,
    title,
  } = props;

  const [recaptchaComplete, setRecaptchaComplete] = useState(false);

  const recaptchaHandler = (success: boolean) => {
    if (success) setRecaptchaComplete(true);
  };

  const desc = (
    <div>
      <Typography className={classes.body} variant="body1">
        {description}
      </Typography>
      <br />
      <Grid container spacing={2} justify="center">
        {infoPopups.map((item: InfoPopupProps, i) => (
          // if the array mutates, must supply key other than array index
          <Grid item key={i}>
            <InfoPopup {...item} />
          </Grid>
        ))}
      </Grid>
      {postDescription && (
        <>
          <br />
          <Typography variant="body1" display="block" gutterBottom>
            {postDescription}
          </Typography>
        </>
      )}
    </div>
  );

  const form = (props: FormikProps<any> & FormStateProps) => {
    return (
      <div className={classes.form}>
        <Grid
          container
          direction="column"
          spacing={2}
          justify="center"
          alignItems="center"
        >
          {props.formModel.map((item: InputModels) => (
            <Grid item key={item.key} className={classes.formInput}>
              {typeToInput[item.type]({...props, ...item.data} as any)}
            </Grid>
          ))}
          {recaptcha && <RecaptchaContainer onVerify={recaptchaHandler} />}
        </Grid>
      </div>
    );
  };

  const body = (
    <div className={classes.cardBody}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formSchema}
        onSubmit={formOnSubmit as any}
      >
        {(props: FormikProps<any>) => {
          return (
            <ExtendedFormState {...props} formModel={formModel}>
              {(formProps: FormStateProps) => {
                return (
                  <>
                    {desc}
                    {form({...props, ...formProps})}
                    <div className={classes.cardActions}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={recaptcha && !recaptchaComplete}
                        onClick={() => props.handleSubmit()}
                      >
                        <Typography variant="button">Submit</Typography>
                      </Button>
                    </div>
                  </>
                );
              }}
            </ExtendedFormState>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <CardCustom
        title={title}
        cardBody={body}
        image={{
          src: oiseLogo,
          maxWidth: '500px',
        }}
        maxWidth="750px"
      ></CardCustom>
    </Grid>
  );
};

export default ConsentForm;
