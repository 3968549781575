export default {
  consent: {
    parent: 'parent_consent',
    teacher: 'teacher_consent',
  },
  signup: {
    parent: 'parent_signup',
    teacher: 'teacher_signup',
    child: 'child_signup',
    childDemographic: 'child_demographic',
    createClass: 'create_class',
    homeLearningDiscovery: 'home_learning_discovery',
    aboutMyChildsDevelopment: 'about_my_childs_development',
    addNewChild: 'add_new_child',
  },
  teacher: {
    classDashboard: 'class_dashboard',
    studentProgress: 'student_progress',
    plottQuestionnaire: 'plott_questionnaire',
    olacQuestionnaire: 'olac_questionnaire',
    elQuestionnaire: 'el_questionnaire',
    enQuestionnaire: 'en_questionnaire',
    sedQuestionnaire: 'sed_questionnaire',
    atlQuestionnaire: 'atl_questionnaire',
    wtpQuestionnaire: 'wtp_questionnaire',
    matsQuestionnaire: 'mats_questionnaire',
  },
  home: 'home',
  parentHome: 'parent_home',
  teacherHome: 'teacher_home',
  login: 'login',
  about: 'about',
  childrensView: 'childrens_view',
  modules: {
    moduleDashboard: 'module_dashboard',
    module0: 'modules/module0',
  },
};
