// IMPORTANT: Only use hex colors //

/**
 *
 * @param color must be a valid 6-character hex color
 * @param opacity must be between 0 and 100
 * @returns hex color code
 */
export const changeOpacity = (color: string, opacity: number) => {
  if (opacity < 0 || opacity > 100)
    throw Error('Opacity must be between 0 and 100');

  const decimalToBase16 = (num: number) => parseInt(num.toString(16), 16);
  const hexAlpha = decimalToBase16(Math.floor(255 * (opacity / 100)));

  return `${color}${hexAlpha}`;
};

export default {
  primary: '#003459', // primary color for all components
  secondary: '#FFA216',
  warning: '#e9c46a',
  orange: '#ff9824',
  darkOrange: '#e76f51',
  red: '#e63946',
  white: '#ffffff',
  lightGrey: '#dcdcdd',
  darkGrey: '#5c677d',
  splashBackground: '#a8dadc',
  balli: '#05a5f6',
  balla: '#c9eb59',
  t2mejr: '#3be040',
  blue: '#0000FF',
  black: '#000000',
  olac: '#FFC0CB',
  el: '#92d050',
  en: '#f7951d',
  atl: '#ffbf00',
  mats: 'grey',
  sed: '#BAAAED',
  wtp: '#d8e020',
  success: '#4db34d',
  successSecondary: '#c1ffb5',
};
