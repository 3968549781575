import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppDispatch, AppThunk} from './store';
import {childrenCollection, childDemographic} from '../firebase-app';
import {ChildDemographic} from '../signups/signup_pages/ChildRegistration';
import {configurationCollection} from '../firebase-app';
import store from './store';
import {ACCOUNT_TYPES} from './users';
import {OLACForm} from '../signups/school_questionnaire/OLACQuestionnaire';
import {ELForm} from '../signups/school_questionnaire/ELQuestionnaire';
import {ENForm} from '../signups/school_questionnaire/ENQuestionnaire';
import {MATSForm} from '../signups/school_questionnaire/MATSQuestionnaire';
import {SEDForm} from '../signups/school_questionnaire/SEDQuestionnaire';
import {ATLForm} from '../signups/school_questionnaire/ATLQuestionnaire';
import {WTPForm} from '../signups/school_questionnaire/WTPQuestionnaire';
import path from 'path';

import {
  PlottTypes,
  PlottForms,
  PlottTypesArray,
} from '../components/PLOTTQuestionnaire';
import {PlottFiles} from '../components/FilesUpload';
import {getsFilesFromStorageFolder} from '../functions/fileStorage';

type PLOTTExtras = {
  uploadedFiles: PlottFiles;
};
export interface Child {
  school: string;
  teacher: {id: string; name: string};
  birthdate: string;
  grade: string;
  uid: string;
  name: {first: string; last: string};
  childDemographic?: ChildDemographic;
  classID: string;
  avatar: string;
  PLOTT?: {
    OLAC?: OLACForm;
    OLACExtras?: PLOTTExtras;
    EL?: ELForm;
    ELExtras?: PLOTTExtras;
    EN?: ENForm;
    ENExtras?: PLOTTExtras;
    MATS?: MATSForm;
    MATSExtras?: PLOTTExtras;
    ATL?: ATLForm;
    ATLExtras?: PLOTTExtras;
    SED?: SEDForm;
    SEDExtras?: PLOTTExtras;
    WTP?: WTPForm;
    WTPExtras?: PLOTTExtras;
  };
}

const initialState: {[index: string]: Child} = {};

const childSlice = createSlice({
  name: 'children',
  initialState,
  reducers: {
    loadData: (
      state: {[index: string]: Child},
      action: PayloadAction<{[index: string]: Child}>
    ) => {
      Object.assign(state, action.payload);
    },
    updateDemographicQuestionnaireReducer: (
      state: {[index: string]: Child},
      action: PayloadAction<{
        childDemographic: Child['childDemographic'];
        childID: string;
      }>
    ) => {
      // Find the child and update the key
      Object.assign(state[action.payload.childID], {
        childDemographic: action.payload.childDemographic,
      });
    },
    updatePLOTTReducer: (
      state: {[index: string]: Child},
      action: PayloadAction<{
        answers: PlottForms;
        plottType: PlottTypes;
        childID: string;
      }>
    ) => {
      const type: PlottTypes = action.payload.plottType;
      // Find the child and update the key
      Object.assign(state[action.payload.childID]['PLOTT'], {
        [type]: action.payload.answers,
      });
    },
    updatePLOTTExtrasReducer: (
      state: {[index: string]: Child},
      action: PayloadAction<{
        files: PlottFiles;
        plottType: PlottTypes;
        childID: string;
      }>
    ) => {
      const type: PlottTypes = action.payload.plottType;
      const plottExtrasType = `${type}Extras`;
      const plottObject: any = state[action.payload.childID]['PLOTT'];

      if (
        plottObject === undefined ||
        plottObject[plottExtrasType] === undefined
      ) {
        Object.assign(plottObject, {
          [plottExtrasType]: {
            uploadedFiles: action.payload.files,
          },
        });
      } else if (plottObject[plottExtrasType].uploadedFiles === undefined) {
        Object.assign(plottObject[plottExtrasType], {
          uploadedFiles: action.payload.files,
        });
      } else {
        Object.assign(plottObject[plottExtrasType]['uploadedFiles'], {
          ...action.payload.files,
        });
      }
    },
    deletePLOTTExtrasReducer: (
      state: {[index: string]: Child},
      action: PayloadAction<{
        plottType: PlottTypes;
        childID: string;
        key: string;
      }>
    ) => {
      const type: PlottTypes = action.payload.plottType;
      const plottExtrasType = `${type}Extras`;
      const plottObject: any = state[action.payload.childID]['PLOTT'];
      const name = action.payload.key;

      if (plottObject[plottExtrasType]?.uploadedFiles[name] !== undefined) {
        delete plottObject[plottExtrasType].uploadedFiles[name];
      }
    },
  },
});

export const updateChildDemographicQuestionnaire = (
  childID: string,
  demographicAnswers: ChildDemographic
): AppThunk => {
  return async (dispatch: any) => {
    dispatch(
      childSlice.actions.updateDemographicQuestionnaireReducer({
        childDemographic: demographicAnswers,
        childID,
      })
    );
  };
};

export const updatePLOTT = (
  childID: string,
  plottAnswers: PlottForms,
  plottType: PlottTypes
): AppThunk => {
  return async (dispatch: any) => {
    dispatch(
      childSlice.actions.updatePLOTTReducer({
        answers: plottAnswers,
        childID,
        plottType,
      })
    );
  };
};

export const updatePLOTTExtras = (
  childID: string,
  plottFiles: PlottFiles,
  plottType: PlottTypes
): AppThunk => {
  return async (dispatch: any) => {
    dispatch(
      childSlice.actions.updatePLOTTExtrasReducer({
        files: plottFiles,
        childID,
        plottType,
      })
    );
  };
};

export const deletePLOTTExtras = (
  childID: string,
  filename: string,
  plottType: PlottTypes
): AppThunk => {
  return async (dispatch: any) => {
    dispatch(
      childSlice.actions.deletePLOTTExtrasReducer({
        plottType,
        childID,
        key: filename,
      })
    );
  };
};

export const fetchChildrenData = (): AppThunk => {
  return async (dispatch: any) => {
    const childrenList: {[index: string]: Child} = {};
    const state = store.getState();
    let childrenIDs: string[] = [];
    // At this point we have the user data
    // If the account is a teacher go through each class that teacher teacher
    // and get the list of all the students that the teacher teaches
    if (state.user.accountType === ACCOUNT_TYPES.TEACHER) {
      for (const key in state.classes) {
        childrenIDs = childrenIDs.concat(state.classes[key].childrenIDs);
      }
    }
    // If account type is parent then just easily get the children ids since we have it.
    else if (state.user.accountType === ACCOUNT_TYPES.PARENT) {
      if (state.user.childrenIDs) {
        childrenIDs = state.user.childrenIDs;
      }
    }

    // Loop the children ids and query them
    if (childrenIDs) {
      for (let i = 0; i < childrenIDs.length; i++) {
        // Get each child
        const childDocRef = (
          await childrenCollection.doc(childrenIDs[i]).get()
        ).data();

        const PLOTTCollection = await childrenCollection
          .doc(childrenIDs[i])
          .collection('PLOTT')
          .get();

        // Get child demographic questionnaire
        const childDemographicQuest = (
          await childDemographic.doc(childrenIDs[i]).get()
        ).data();
        //
        let currentChild: Child;
        if (childDocRef) {
          currentChild = {
            birthdate: childDocRef.birthdate,
            grade: childDocRef.grade,
            school: childDocRef.schoolName,
            teacher: childDocRef.teacher,
            uid: childrenIDs[i],
            avatar: childDocRef.avatar,
            name: {
              first: childDocRef.firstname,
              last: childDocRef.lastname,
            },
            classID: childDocRef.classID,
          };
          // Only need demographic questionnaire for parent accounts
          if (
            childDemographicQuest &&
            state.user.accountType === ACCOUNT_TYPES.PARENT
          ) {
            currentChild['childDemographic'] = {
              country: childDemographicQuest.country,
              city: childDemographicQuest.city,
              bornInCanada: childDemographicQuest.bornInCanada,
              ageMoved: childDemographicQuest.ageMoved,
              siblingsNumber: childDemographicQuest.siblingsNumber,
              ethnicity: childDemographicQuest.ethnicity,
              firstNations: childDemographicQuest.firstNations,
              familySpeakEnglish: childDemographicQuest.familySpeakEnglish,
              childSpeakEnglish: childDemographicQuest.childSpeakEnglish,
              moreAboutLanguagesAtHome:
                childDemographicQuest.moreAboutLanguagesAtHome,
              moreAboutChildLanguage:
                childDemographicQuest.moreAboutChildLanguage,
            };
          }

          // We only need the PLOTT questionnaire for when a teacher is logged in and the PLOTT collection
          // Exists on the child
          if (
            state.user.accountType === ACCOUNT_TYPES.TEACHER &&
            PLOTTCollection
          ) {
            // Go through each doc in the collection
            PLOTTCollection.forEach(doc => {
              const data = doc.data();
              // Make sure the ID is of type plotTypes
              if (
                doc.id === 'OLAC' ||
                doc.id === 'EL' ||
                doc.id === 'EN' ||
                doc.id === 'SED' ||
                doc.id === 'ATL' ||
                doc.id === 'MATS'
              ) {
                const id: PlottTypes = doc.id;
                if (currentChild['PLOTT']) {
                  // Depending on each ID set the data for that form accordingly
                  if (id === 'OLAC') {
                    currentChild['PLOTT'][id] = {
                      GP1_OLAC: data.GP1_OLAC,
                      GP2_OLAC: data.GP2_OLAC,
                      FP1_OLAC: data.FP1_OLAC,
                      FP2_OLAC: data.FP2_OLAC,
                      FP3_OLAC: data.FP3_OLAC,
                      IP1_OLAC: data.IP1_OLAC,
                      IP2_OLAC: data.IP2_OLAC,
                      IP3_OLAC: data.IP3_OLAC,
                      IP4_OLAC: data.IP4_OLAC,
                      ConP1_OLAC: data.ConP1_OLAC,
                      RBP1_OLAC: data.RBP1_OLAC,
                      RBP2_OLAC: data.RBP2_OLAC,
                    };
                  }
                  if (id === 'EL') {
                    currentChild['PLOTT'][id] = {
                      GP1_EL: data.GP1_EL,
                      GP2_EL: data.GP2_EL,
                      GP3_EL: data.GP3_EL,
                      FP1_EL: data.FP1_EL,
                      FP2_EL: data.FP2_EL,
                      FP3_EL: data.FP3_EL,
                      FP4_EL: data.FP4_EL,
                      FP5_EL: data.FP5_EL,
                      FP6_EL: data.FP6_EL,
                      FP8_EL: data.FP8_EL,
                      IP1_EL: data.IP1_EL,
                      IP2_EL: data.IP2_EL,
                      FP1_EL_W: data.FP1_EL_W,
                      FP2_EL_W: data.FP2_EL_W,
                      GP1_EL_W: data.GP1_EL_W,
                      GP2_EL_W: data.GP2_EL_W,
                      RBP1_EL_W: data.RBP1_EL_W,
                    };
                  }
                  if (id === 'EN') {
                    currentChild['PLOTT'][id] = {
                      FP1_EN: data.FP1_EN,
                      FP2_EN: data.FP2_EN,
                      FP3_EN: data.FP3_EN,
                      IP1_EN: data.IP1_EN,
                      IP2_EN: data.IP2_EN,
                      ConP1_EN: data.ConP1_EN,
                      ConP2_EN: data.ConP2_EN,
                      ConP3_EN: data.ConP3_EN,
                      RBP1_EN: data.RBP1_EN,
                      GP1_EN: data.GP1_EN,
                    };
                  }
                  if (id === 'SED') {
                    currentChild['PLOTT'][id] = {
                      FP1_SED: data.FP1_SED,
                      FP2_SED: data.FP2_SED,
                      FP3_SED: data.FP3_SED,
                      RBP1_SED: data.RBP1_SED,
                      RBP2_SED: data.RBP2_SED,
                      ColP1_SED: data.ColP1_SED,
                      ColP2_SED: data.ColP2_SED,
                      IP1_SED: data.IP1_SED,
                      ConP1_SED: data.ConP1_SED,
                      GP1_SED: data.GP1_SED,
                    };
                  }
                  if (id === 'ATL') {
                    currentChild['PLOTT'][id] = {
                      FP1_ATL: data.FP1_ATL,
                      FP2_ATL: data.FP2_ATL,
                      FP3_ATL: data.FP3_ATL,
                      IP1_ATL: data.IP1_ATL,
                      IP2_ATL: data.IP2_ATL,
                      ConP1_ATL: data.ConP1_ATL,
                      ColP1_ATL: data.ColP1_ATL,
                      ColP2_ATL: data.ColP2_ATL,
                      GP1_ATL: data.GP1_ATL,
                      GP2_ATL: data.GP2_ATL,
                    };
                  }
                  if (id === 'MATS') {
                    currentChild['PLOTT'][id] = {
                      special_needs_MATS: data.special_needs_MATS,
                      exceptional_skills_MATS: data.exceptional_skills_MATS,
                    };
                  }
                }
              }
            });
          }

          // We need the PLOTT questionnaire uploaded files for when a teacher is logged in
          if (state.user.accountType === ACCOUNT_TYPES.TEACHER) {
            currentChild['PLOTT'] = {};
            // get storage folder
            const plottFilesStoragePath = await configurationCollection
              .doc('storage_folder')
              .get()
              .then((snapshot: any) => {
                const data = snapshot.data();
                return data['plottFiles'];
              });

            await Promise.all(
              PlottTypesArray.map(async type => {
                const plottFilesPath = path.join(
                  plottFilesStoragePath,
                  childrenIDs[i],
                  type
                );
                const plottFiles = await getsFilesFromStorageFolder(
                  plottFilesPath
                );

                if (plottFiles === null) return;

                console.log(plottFiles);

                if (currentChild['PLOTT']) {
                  const plottExtras = {
                    uploadedFiles: plottFiles,
                  };
                  (currentChild['PLOTT'] as any)[`${type}Extras`] = plottExtras;
                }
              })
            );
          }
          // Populate each child in
          childrenList[childrenIDs[i]] = currentChild;
        }
      }
      // reducer
      await dispatch(childSlice.actions.loadData(childrenList));
    }
  };
};

export default childSlice.reducer;
