import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../theming/colors';

const useStyles = makeStyles(() =>
  createStyles({
    cardBody: {
      display: 'flex',
      textAlign: 'center',
      margin: '16px 0px 24px 0px',
      alignItems: 'center',
    },
    titleText: {
      color: colors.primary,
      margin: 'auto',
      fontSize: '400%',
    },
    textContainer: {
      fontSize: '1.5rem',
      color: colors.primary,
      display: 'flex',
      margin: '2rem',
    },
  })
);

type AboutProps = RouteComponentProps<any>;

const About: React.FunctionComponent<AboutProps> = (props: AboutProps) => {
  const classes = useStyles(props);

  return (
    <div>
      <div className={classes.cardBody}>
        <Typography className={classes.titleText} variant="h3">
          <b>About BalanceAI Discovery</b>
        </Typography>
      </div>
      <div className={classes.textContainer}>{AboutLoad}</div>
    </div>
  );
};

const AboutLoad = (
  <>
    <Typography paragraph>Dear Parents or Guardians,</Typography>
    <Typography paragraph>
      Thank you for your interest in BalanceAI Discovery.
    </Typography>
  </>
);

export default withRouter(About);
