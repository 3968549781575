import {storage} from '../firebase-app';
import path from 'path';
import log from 'loglevel';
import {PlottFiles} from '../components/FilesUpload';
import {isEmpty} from 'lodash';
import Axios from 'axios';
import fileDownload from 'js-file-download';

export const uploadFileToStorage = async (
  filename: string,
  file: File,
  storageFolderPath: string
): Promise<null | {downloadUrl: string}> => {
  const fileRef = storage.ref(path.join(storageFolderPath, filename));
  log.debug(`Uplaoding ${filename} to storage folder ${storageFolderPath}`);

  return await fileRef
    .put(file)
    .then(async (snapshot: firebase.storage.UploadTaskSnapshot) => {
      const downloadUrl = await snapshot.ref.getDownloadURL();
      // success
      return {
        downloadUrl: downloadUrl,
      };
    })
    .catch(() => {
      // error
      return null;
    });
};

export const getsFilesFromStorageFolder = async (
  storageFolderPath: string
): Promise<null | PlottFiles> => {
  const folderRef = storage.ref(path.join(storageFolderPath));

  log.debug(
    `Retrieving file metadatas from storage folder ${storageFolderPath}`
  );

  return await folderRef
    .listAll()
    .then(async res => {
      const files: PlottFiles = {};

      await Promise.all(
        res.items.map(async itemRef => {
          const metadata = await itemRef.getMetadata();
          const downloadUrl = await itemRef.getDownloadURL();
          const name: string = metadata.name;
          const size = metadata.size;
          const modified = metadata.timeCreated;

          files[name] = {name, size, modified, downloadUrl};
        })
      );

      if (isEmpty(files)) return null;

      return files;
    })
    .catch(() => {
      // error
      return null;
    });
};

export const deleteFileInStorageFolder = async (
  storageFilePath: string
): Promise<boolean> => {
  const fileRef = storage.ref(path.join(storageFilePath));

  log.debug(`Deleting storage file ${storageFilePath}`);

  return await fileRef
    .delete()
    .then(() => {
      // file successfully deleted
      return true;
    })
    .catch(() => {
      // error
      return false;
    });
};

export const downloadFromUrl = (url: string, filename: string) => {
  Axios.get(url, {responseType: 'blob'}).then((res: any) => {
    console.log(res);
    fileDownload(res.data, filename);
  });
};
