import React from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import firebase from 'firebase/app';
import {InputModels} from './FormInputs';
import Signup from './Signup';
import store from '../features/store';
import {store as storen} from 'react-notifications-component';
import route from '../routes/route';
import {updateTeacherclassIDs} from '../features/users';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import {CreateClassSchema} from '../schema';
import {classesCollection, usersCollection} from '../firebase-app';
import {fetchClassesData} from '../features/classes';
// Interface of what the registration form for a child looks like
export interface CreateClassForm {
  className: string;
  grade: string;
}

const CreateClass: React.FunctionComponent = () => {
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user);
  const initialFormValues: CreateClassForm = {
    className: '',
    grade: '',
  };
  const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
    const lst = [];
    if (!labelArr) labelArr = valArr;
    for (let i = 0; i < valArr.length; i++)
      lst.push({value: valArr[i], label: labelArr[i]});
    return lst;
  };

  const registrationFormOnSubmit = async (values: CreateClassForm) => {
    // Create 5 char alphanumeric class ID
    let classID = Math.random().toString(36).substr(2, 5).toUpperCase();
    let classRef = await classesCollection.doc(classID).get();
    while (classRef.exists) {
      classID = Math.random().toString(36).substr(2, 5).toUpperCase();
      classRef = await classesCollection.doc(classID).get();
    }
    // We have a unique ID
    // Update the teacher classes field to have the new ID
    await usersCollection
      .doc(user.uid)
      .update({
        classIDs: firebase.firestore.FieldValue.arrayUnion(classID),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to create class',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        // Create the actual class now
        await classesCollection
          .doc(classID)
          .set({
            ...values,
            childrenIDs: [],
            dateSubmitted: firebase.firestore.Timestamp.now(),
          })
          .catch(() => {
            storen.addNotification({
              title: 'Failed to create class',
              message: 'Please try again',
              type: 'danger',
              insert: 'bottom',
              container: 'top-right',
              animationIn: ['animated', 'slideIn'],
              animationOut: ['animated', 'slideOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
          })
          .finally(async () => {
            // Update the teacher state to have the new class
            const updatedclassIDs = Object.assign([], user.classIDs);
            updatedclassIDs.push(classID);
            await store.dispatch(updateTeacherclassIDs(updatedclassIDs));
            await store.dispatch(fetchClassesData());

            storen.addNotification({
              title: 'Class created successfully',
              message: `Your class signup ID is: ${classID}`,
              type: 'success',
              insert: 'bottom',
              container: 'top-right',
              animationIn: ['animated', 'slideIn'],
              animationOut: ['animated', 'slideOut'],
              dismiss: {
                duration: 3000,
                onScreen: true,
                showIcon: true,
              },
            });
            history.push(route.home);
          });
      });
  };
  // Create a list of grades that the teacher teachers.
  // Default list
  let gradesThought = [
    ['Lower Kindergarten', 'Upper Kindergarten', 'Grade 1', 'Grade 2'],
  ];
  if (user.grades) {
    gradesThought = user.grades;
  }
  const formModel: InputModels[] = [
    {
      type: 'textfield',
      key: 'className',
      data: {
        id: 'className',
        label: 'Name of your class',
        type: 'name',
      },
    },
    {
      type: 'select',
      key: 'grade',
      data: {
        id: 'grade',
        label: 'Grade',
        menuItems: generateMenuItems(gradesThought),
      },
    },
  ];
  return (
    <>
      <Signup
        title="Create a new class"
        submitText="Submit"
        formSchema={CreateClassSchema}
        formInitialValues={initialFormValues}
        formModel={formModel}
        formOnSubmit={registrationFormOnSubmit}
      ></Signup>
    </>
  );
};

export default withRouter(CreateClass);
