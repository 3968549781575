import React, {FunctionComponent, useState, Component} from 'react';
import {withRouter, useLocation} from 'react-router';
import {Bar, PolarArea} from 'react-chartjs-2';
import type {ChartProps} from 'react-chartjs-2';
import {Paper} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import colors from '../theming/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '3rem',
    },
    titleTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.primary,
      padding: '2rem 0',
    },
  })
);

const StudentProgress: React.FunctionComponent = () => {
  const childrenState = useSelector((state: RootState) => state.children);
  const location = useLocation();
  const childID = String(location.state);
  const classes = useStyles();

  const average = (childQuestionnaireResult: any) => {
    if (!childQuestionnaireResult) {
      return 0;
    }
    let count = 0;
    let sum = 0;
    for (const index in childQuestionnaireResult) {
      const value = childQuestionnaireResult[index];
      count = count + 1;
      sum = sum + Number(value);
    }
    return sum / count;
  };

  const graphsData = () => {
    const averageValues: Array<number> = [];
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        console.log(key, childID); // need to remove the key forloop from all code since these 2 variables are the same
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          averageValues.push(average(childPLOTT.OLAC));
          averageValues.push(average(childPLOTT.EL));
          averageValues.push(average(childPLOTT.EN));
          averageValues.push(average(childPLOTT.SED));
          averageValues.push(average(childPLOTT.ATL));
        }
      }
    }

    const colorsArray = [
      colors.olac,
      colors.el,
      colors.en,
      colors.sed,
      colors.atl,
    ];

    const color1 = Array(5).fill('#e94f37');
    const color2 = Array(5).fill('#3f88c5');
    const color3 = Array(5).fill('#44bba4');

    const borderColorsArray = color1.concat(color2, color3);

    const barLabels = ['Oral', 'Literacy', 'Numeracy', 'SED', 'ATL'];
    const jrkBarLabels = barLabels.map(label => 'Jr. K ' + label);
    const srkBarLabels = barLabels.map(label => 'Sr. K ' + label);
    const kexitBarLabels = barLabels.map(label => 'K exit ' + label);
    const finalBarLabels = jrkBarLabels.concat(srkBarLabels, kexitBarLabels);

    const PolarAreaData: any = {
      labels: barLabels,
      datasets: [
        {
          label: 'School Learning Discovery',
          data: averageValues,
          backgroundColor: colorsArray,
          borderColor: colorsArray,
          borderWidth: 1,
        },
      ],
    };

    const BarData: any = {
      labels: finalBarLabels,
      datasets: [
        {
          label: 'Learning Discovery',
          data: averageValues.concat(averageValues, averageValues),
          backgroundColor: borderColorsArray,
          borderColor: borderColorsArray,
          borderWidth: 1,
        },
      ],
    };

    const options: any = {
      scales: {
        y: {
          max: 3,
          ticks: {
            callback: function (value: any, index: any, values: any) {
              if (value === 1) return 'emerging (1)';
              if (value === 2) return 'Developing (2)';
              if (value === 3) return 'Proficient (3)';
              // return value;
            },
          },
        },
      },
    };

    return {PolarAreaData, BarData, options};
  };

  return (
    <Paper className={classes.container}>
      <div className={classes.titleTextContainer}>
        <Typography variant="h4">
          <b>{`${childrenState[childID].name.first}`}&apos;s Progress</b>
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={3}>
          <PolarArea data={graphsData().PolarAreaData} />
        </Grid>
        <Grid item xs={3}>
          <PolarArea data={graphsData().PolarAreaData} />
        </Grid>
        <Grid item xs={3}>
          <PolarArea data={graphsData().PolarAreaData} />
        </Grid>
        <Grid item xs={3}>
          <PolarArea data={graphsData().PolarAreaData} />
        </Grid>
      </Grid>
      <Bar
        data={graphsData().BarData}
        options={graphsData().options}
        width={80}
        height={30}
      />
    </Paper>
  );
};

export default withRouter(StudentProgress);
