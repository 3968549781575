import React, {useState} from 'react';
import CardCustom from '../components/CardCustom';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Dialog, Typography} from '@material-ui/core';
import colors from '../theming/colors';

export type InfoPopupProps = {
  // Component Properties
  title: string; // title displayed on the button and possibly on the card
  popupTitle?: string; // if provided will display this title on the card.
  text: JSX.Element; // text info to display on the card.
  ButtonComponent?: React.FunctionComponent<{onClick: () => void}>; // component to use instead of default button;
};

const useStyles = makeStyles({
  cardBody: {
    margin: '16px 0px 24px 0px',
    textAlign: 'justify',
    padding: '0px 24px 0px 24px',
  },
});

const InfoPopup: React.FunctionComponent<InfoPopupProps> = (
  props: InfoPopupProps
) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {title, text, popupTitle, ButtonComponent} = props;

  const dialogBody = (
    <div className={classes.cardBody}>
      <Typography>{text}</Typography>
    </div>
  );

  return (
    <>
      {ButtonComponent ? (
        React.createElement(ButtonComponent, {onClick: () => setOpen(true)})
      ) : (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <Typography variant="button">{title}</Typography>
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth={true}
        maxWidth="md"
      >
        <CardCustom
          title={popupTitle ? popupTitle : title}
          color={colors.lightGrey}
          maxWidth="unset"
          width="auto"
          cardBody={dialogBody}
        />
      </Dialog>
    </>
  );
};

export default InfoPopup;
