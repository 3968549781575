import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {InfoPopupProps} from '../components/InfoPopup';
import {TeacherConsentSchema} from '../schema';
import ConsentForm from '../components/ConsentForm';
import {InputModels} from '../components/FormInputs';
import route from '../routes/route';
import {Link, Typography} from '@material-ui/core';

// Used for documentation purposes
interface FormValues {
  readInfo: boolean;
  agree: 'yes' | 'no';
}

type TeacherConsentProps = RouteComponentProps<any>;

const TeacherConsent: React.FunctionComponent<TeacherConsentProps> = (
  props: TeacherConsentProps
) => {
  const {history} = props;

  const who = (
    <Typography>
      BalanceAI Discovery is part of a larger group of assessments called
      BalanceAI (Blended Assessment of Literacy and Numeracy in Computerized
      Environments with Artificial Intelligence). This project is led by Dr.
      Eunice Eunhee Jang, a Professor in the Department of Applied Psychology
      and Human Development at the Ontario Institute for Studies in Education,
      University of Toronto. Dr. Jang’s research focuses on improving assessment
      practices and student learning by examining ways to integrate assessment
      into teaching and learning. Her book, Focus on Assessment (2014), provides
      practical guidelines for K-12 teachers based on her research on young
      learners. If you would like to learn more about Dr. Jang’s research and
      publications, please visit{' '}
      <Link href="https://www.oise.utoronto.ca/ejanglab/lab-members/eunice/">
        https://www.oise.utoronto.ca/ejanglab/lab-members/eunice/
      </Link>
      .
    </Typography>
  );

  const why = (
    <Typography>
      Many large-scale assessments only provide a fraction of information on
      children’s knowledge and literacy skills. There is a critical need for
      21st century assessments that can gather data on broader areas of student
      learning, including students’ ability to take up and synthesize new
      information, their abilities to think about thinking (metacognition),
      their engagement with the subject matter, their ability to communicate
      competently in an online interface, and their capacity to self-regulate.
      We have developed BalanceAI in response to these needs. BalanceAI can
      assess foundational literacy skills and provide scaffolded metacognitive
      and self-regulated learning interventions that can support students’
      literacy development. BalanceAI uses fun, engaging, age-appropriate tasks
      to build students’ motivation for literacy learning. If you would like to
      learn more about the Balance AI project, please visit{' '}
      <Link href="https://www.oise.utoronto.ca/ejanglab/research/balance/">
        https://www.oise.utoronto.ca/ejanglab/research/balance/
      </Link>{' '}
      for more information.
    </Typography>
  );

  const about = (
    <>
      <Typography paragraph>
        The pre-kindergarten assessment consists of five modules that gauge
        children’s receptive and expressive language ability, book awareness,
        reading ability, letter and phonological awareness, listening
        comprehension, ability to retell a story, rapid autonomized naming
        capacity, early spelling skills, as well as their level of
        self-awareness and interest in reading. Each sub-module contains several
        integrated and scaffolded tasks that can be completed at home or in a
        physical or virtual classroom. The modules are also guided by a friendly
        interactive avatar that helps children navigate the platform, and
        automated feedback on students’ performance will be provided to parents
        and teachers. This assessment is delivered fully online and can be
        accessed from any device connected to the internet. Children’s speech
        will be recorded, stored, and analyzed by an
        artificial-intelligence-assisted speech diagnostic platform.
      </Typography>
      <Typography paragraph>
        We are also inviting teachers to complete brief questionnaires based on
        their observations of children’s language and emergent literacy skills,
        social and emotional awareness, and executive functioning. The
        information you provide in the questionnaire will help us to combine the
        data collected from parents’ reports and children’s performance, to gain
        a more holistic understanding of each child’s learning potential.
      </Typography>
    </>
  );

  const data = (
    <>
      <Typography paragraph>
        Students’ responses on all tasks, including verbal responses to oral
        language tasks, will be recorded and used to provide high-quality
        feedback to students, parents and teachers. At the end of the project,
        we will also invite teachers and students to participate in user and
        learner experience interviews. These interviews will be conducted by
        researchers online via Zoom. Students’ and teachers’ participation may
        also be video recorded.
      </Typography>
      <Typography paragraph>
        All data will be securely stored in an encrypted cloud-based database,
        accessible only to the research team, and feedback on students’
        performance will be made available to students, parents, teachers, and
        administrators. Data, including speech and video data, will be used
        mainly for generating feedback for users. All participants will have the
        option to consent to specific data dissemination for research purposes
        including conference presentations and academic publications. All data
        will be kept up to five years after the project ends and thereafter will
        be permanently deleted to ensure secure disposal.
      </Typography>
      <Typography paragraph>
        This research and related data may be reviewed by University of
        Toronto’s Human Research Ethics Program (HREP) for quality assurance and
        to ensure that the required laws and guidelines are followed. If chosen,
        representatives of the HREP may access study-related data and/or consent
        materials as part of the review. All information accessed by the HREP
        will be upheld to the same level of confidentiality that the research
        team has stated.
      </Typography>
      <Typography paragraph>
        Participation in the research project is voluntary. All participants
        have the right to withdraw from the research at any time before the data
        is anonymized. There are no negative consequences to withdrawal.
        Children are welcome to continue using the BalanceAI platform, even if
        their parents prefer that they are exempted from the research.
        Performance data will be used to provide feedback to students, teachers,
        and parents, but all information collected from withdrawn participants
        will be removed from the data depository and securely destroyed after
        feedback is provided. Please note that there is no monetary compensation
        provided for participation.
      </Typography>
    </>
  );

  const contact = (
    <>
      <Typography paragraph>
        This study has been approved by the University of Toronto Research
        Ethics Board and Toronto District School Board’s Ethics External
        Research Review Committee*. For questions about research participants’
        rights, you are welcome to contact the Office of Research Ethics at the
        University of Toronto at{' '}
        <Link href="mailto:ethics.review@utoronto.ca">
          ethics.review@utoronto.ca
        </Link>{' '}
        or 416-946-3273. We are also happy to address and discuss any questions
        or concerns you may have about the research and can be reached by phone
        or email listed below.
      </Typography>
      <Typography paragraph>
        If you are willing to participate, please read and sign the consent form
        below.
      </Typography>
      <Typography paragraph>Thank you kindly for your support.</Typography>
      <Typography paragraph>Sincerely,</Typography>
      <Typography>Dr. Eunice Eunhee Jang</Typography>
      <Typography>Professor</Typography>
      <Typography>Ontario Institute for Studies in Education</Typography>
      <Typography>University of Toronto</Typography>
      <Typography>252 Bloor Street West, 9th floor</Typography>
      <Typography>Toronto, Ontario, Canada M5S 1V6</Typography>
      <Typography>
        Email:{' '}
        <Link href="mailto:eun.jang@utoronto.ca">eun.jang@utoronto.ca</Link>
      </Typography>
      <Typography>Phone: 416-978-0238</Typography>
      <Typography>
        Website:{' '}
        <Link href="https://www.oise.utoronto.ca/ejanglab/lab-members/eunice/">
          https://www.oise.utoronto.ca/ejanglab/lab-members/eunice/
        </Link>
      </Typography>
    </>
  );

  const infoPopups: InfoPopupProps[] = [
    {title: 'Who we are?', text: who},
    {title: 'Why BALANCEAI', text: why},
    {title: 'About the study', text: about},
    {title: 'Data usage', text: data},
    {title: 'Contact Us', text: contact},
  ];

  const description = (
    <>
      <Typography paragraph>Dear Teachers,</Typography>
      <Typography paragraph>
        Thank you for your interest in BalanceAI Discovery.
      </Typography>
      <Typography paragraph>
        BalanceAI Discovery is an innovative, computer-based language and
        literacy assessment specifically designed for young children ages 4 to 6
        years. This dynamic assessment combines cutting edge technology with
        developmentally appropriate tasks that are anchored in theory and
        educational research. BalanceAI Discovery aims to assess children’s
        skills and abilities at three key time-points:
      </Typography>
      <Typography>
        1. Pre-kindergarten – to assess children’s readiness for kindergarten \n
      </Typography>
      <Typography>
        2. Mid-kindergarten – to assess children’ progress
      </Typography>
      <Typography paragraph>
        3. Kindergarten-completion – to assess children’s readiness for
        elementary school
      </Typography>
      <Typography paragraph>
        Longitudinal and cross-sectional data on children’s language, literacy,
        cognitive, and social-emotional development will be collected and used
        to provide strategic, individualized feedback to parents. In addition,
        both student and classroom-level feedback will be available to teachers.
        Data collected from this platform will be used to support students,
        improve the platform, and extend early literacy research.
      </Typography>
      <Typography paragraph>
        We would like to invite you and our students to participate in our
        study. If you would like to be a part of our research, kindly click on
        the buttons below to read more about who we are, the details of our
        study, and to indicate your consent at the end of this page. Then please
        proceed to the Teachers’ Questionnaire.
      </Typography>
    </>
  );

  const formInitialValues = {
    readInfo: false,
    agree: null,
  };

  const formModel: InputModels[] = [
    {
      type: 'checkbox',
      key: 'readInfo',
      data: {
        id: 'readInfo',
        label: 'I have read the information above.',
      },
    },
    {
      type: 'radio',
      key: 'agree',
      data: {
        id: 'agree',
        label: 'I voluntarily agree to participate in the project.',
        radios: [
          {value: 'yes', label: 'Yes'},
          {value: 'no', label: 'No'},
        ],
      },
    },
  ];

  const formOnSubmit = (values: FormValues) => {
    // if yes, redirect to signup and questionnaire;
    if (values.agree === 'yes') history.push(`${route.signup.teacher}`);
    else history.push('/');
  };

  return (
    <ConsentForm
      // recaptcha // uncomment to use recaptcha
      title="Teacher Consent Form"
      description={description}
      infoPopups={infoPopups}
      formSchema={TeacherConsentSchema}
      formInitialValues={formInitialValues}
      formModel={formModel}
      formOnSubmit={formOnSubmit}
    ></ConsentForm>
  );
};

export default withRouter(TeacherConsent);
