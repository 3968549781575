import React from 'react';

import ReCAPTCHA from 'react-google-recaptcha';

interface RecaptchaContainerProps {
  onVerify: (success: boolean) => void;
  onError?: () => void;
  onExpire?: () => void;
}

const RecaptchaContainer: React.FunctionComponent<RecaptchaContainerProps> = (
  props: RecaptchaContainerProps
) => {
  const {onVerify, onError, onExpire} = props;

  const verify = async (token: string | null) => {
    fetch(
      'https://us-central1-balance-257015.cloudfunctions.net/verifyRecaptcha?response=' +
        token,
      {
        method: 'GET',
      }
    ).then(async (res: any) => {
      res = await res.json();
      onVerify(res.success);
    });
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
      onChange={verify}
      onErrored={onError}
      onExpired={onExpire}
      size="normal"
      type="image"
    />
  );
};

export default RecaptchaContainer;
