import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {WTPQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';
import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface WTPForm {
  FP1_WTP: string;
  FP2_WTP: string;
  GP1_WTP: string;
  GP2_WTP: string;
  RBP1_WTP: string;
}

const WTPQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.matsQuestionnaire;
  const prev = route.teacher.atlQuestionnaire;
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultWTPValues: WTPForm = {
    FP1_WTP: '',
    FP2_WTP: '',
    GP1_WTP: '',
    GP2_WTP: '',
    RBP1_WTP: '',
  };

  const defaultWTPExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childWTP = childPLOTT.WTP;
          if (childWTP) {
            defaultWTPValues = childWTP;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'WTP');
          if (uploadedFiles)
            defaultWTPExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'WTP'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'WTP'
  );

  const formOnSubmit = async (values: WTPForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('WTP')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'WTP'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'FP1_WTP',
      data: {
        id: 'FP1_WTP',
        label:
          'Initiate writing activities without a teacher’s direction or suggestion.',
        labelInfoPopup:
          'e.g., Sits with a tray of sand and writes letters in the sand with her finger',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_WTP',
      data: {
        id: 'FP2_WTP',
        label: 'Attempt or pretend to write.',
        labelInfoPopup:
          'e.g., Writes squiggles or near-letter shapes from left to right, top to bottom of a page​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP1_WTP',
      data: {
        id: 'GP1_WTP',
        label: 'Write their name.',
        labelInfoPopup: 'e.g., Writes first and last name during play',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP2_WTP',
      data: {
        id: 'GP2_WTP',
        label:
          'Use symbols and forms of early writing to create more complex play.',
        labelInfoPopup:
          'e.g. letter-sound correspondences, Creates a road sign during construction play',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP1_WTP',
      data: {
        id: 'RBP1_WTP',
        label: 'Use letters and/or invented spelling and basic punctuation.',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Writing Through Play"
        submitText={'Submit Questionnaire'}
        formSchema={WTPQuestionnaireSchema}
        formInitialValues={defaultWTPValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.wtp}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultWTPExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(WTPQuestionnaire);
