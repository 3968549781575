import React, {useCallback, useRef, useState, useEffect} from 'react';
import CardCustom from './CardCustom';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button, Typography} from '@material-ui/core';
import {FormikProps, FormikBag, Formik} from 'formik';
import {Schema} from 'yup';
import colors, {changeOpacity} from '../theming/colors';
import {ExtendedFormState, FormStateProps, InputModels} from './FormInputs';
import {
  RadioModel,
  getFormCompletionPercentage,
} from '../components/FormInputs';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import {StickyProgressBar} from './ColoredLinearProgress';
import HelpIconOutline from '@material-ui/icons/HelpOutline';
import InfoPopup from './InfoPopup';
import {useLocation} from 'react-router';
import {FileUploadForm, PlottFileDirectory} from './FilesUpload';

interface Descriptor {
  name: string;
  content: string;
  rating: Rating;
}

export interface CustomDescriptors {
  customDescriptors: Descriptor[];
}

enum Rating {
  NOT_OBSERVABLE = '-1',
  NOT_YET = '0',
  RARELY = '1',
  SOMETIMES = '2',
  OFTEN = '3',
  ALWAYS = '4',
}

const UPLOAD_OPTIONS = {
  acceptedFiles: ['image/*, .pdf'],
  maxFileSize: 3 * 2 ** 20, // 3 MB
  showPreviews: true,
  buttonText: 'Upload Files',
};

interface PlottFormFormat<T> {
  uploadedFiles: {
    key: string;
    size: number;
    modified?: string;
    downloadUrl: string;
  }[];
  title: string; // title of the questionnaire;
  color: string; // primary color of the module
  submitText?: string; // text on the submit button;
  recaptcha?: boolean; // whether to have a google recaptcha
  backButton?: {label: string; callback: (values: T) => void}; // if the form has a back button
  formSchema: Schema<any>; // form schema from schema.ts
  formInitialValues: T; // initial values of the form where keys are identical to model keys
  formModel: InputModels[]; // model to build form off
  formOnSubmit: (values: T, formikBag: FormikBag<object, T>) => void;
  formWidth?: string;
  fileUploadHandler: (files: File[]) => any; // handler to call when files are uploaded
  fileDeleteHandler: (filenames: string[]) => any; // handler to all when files are deleted
  next: string;
  prev: string;
  setGoTo: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles({
  cardBody: {
    margin: '16px 0px 0px 0px',
    padding: '0px 24px 0px 24px',
  },
  cardActions: {
    // textAlign: 'center',
  },
  gridContainer: {},
  repatchaContainer: {
    display: 'inline-block',
  },
  form: {
    margin: '24px auto',
  },
  submitButton: {
    backgroundColor: colors.primary,
    color: colors.white,
    marginLeft: 'auto',
    marginBottom: '2rem',
  },
  backButton: {
    marginRight: '60px',
  },
  formControl: {
    width: 'inherit',
    padding: '18px',
  },
  questionsContainer: {
    padding: '1rem',
  },
  radioButton: {
    margin: '0 auto',
  },
  tableHeaders: {
    textAlign: 'center',
  },
  tableHeaderRow: {
    padding: '12px',
    position: 'sticky',
    top: '8px',
    backgroundColor: (
      props: PlottFormFormat<any> | TableHeaderProps | RadioInputProps
    ) => props.color,
  },
  tableRowBackground: {
    backgroundColor: (
      props: PlottFormFormat<any> | TableHeaderProps | RadioInputProps
    ) => changeOpacity(props.color, 10),
  },
  errorMessage: {
    height: '24px',
    marginBottom: '8px',
  },
  progressBar: {
    height: '8px',
  },
  infoButton: {
    verticalAlign: 'sub',
  },
  fileDirectory: {
    fontFamily: 'Roboto',
    backgroundColor: '#F5F5F5',
    borderRadius: '10px',
    padding: '12px',
    // width: '500px',
    '& a': {
      textDecoration: 'None',
      color: colors.primary,
    },
  },
  fileUploadButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

type RadioInputPropsExtension = {
  labelInfoPopup?: string; // adds a '?' button with popup modal next to the text.
  rowNumber: number;
  color: string;
};

type RadioInputProps = RadioModel['data'] &
  FormikProps<any> &
  FormStateProps &
  RadioInputPropsExtension;

type TableHeaderProps = {
  title: string; // title of table
  options: string[]; // header names
  color: string;
};

export const PlottMenuItems = [
  {value: Rating.ALWAYS, label: 'Always'},
  {value: Rating.OFTEN, label: 'Often'},
  {value: Rating.SOMETIMES, label: 'Sometimes'},
  {value: Rating.RARELY, label: 'Rarely'},
  {value: Rating.NOT_YET, label: 'Not Yet'},
  {value: Rating.NOT_OBSERVABLE, label: 'Not Observable'},
];

const TableHeader = (props: TableHeaderProps) => {
  const {title, options} = props;
  const classes = useStyles(props);
  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      alignItems="flex-start"
      className={classes.tableHeaderRow}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle2">{title}</Typography>
      </Grid>
      {options.map((header, i) => (
        <Grid
          item
          xs
          container
          key={i}
          className={classes.radioButton}
          direction="row"
          justifyContent="space-around"
        >
          <Grid item className={classes.tableHeaders}>
            <Typography variant="subtitle2">{header}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const RadioInputRow = (props: RadioInputProps) => {
  const {
    touched,
    errors,
    handleChange,
    values,
    id,
    label,
    radios,
    rowNumber,
    labelInfoPopup,
  } = props;
  const classes = useStyles(props);
  return (
    <FormControl
      error={touched[id] && Boolean(errors[id])}
      className={`${classes.formControl} ${
        rowNumber % 2 === 0 && classes.tableRowBackground
      }`}
    >
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        alignItems="flex-start"
      >
        <Grid item xs={6}>
          <Typography variant="body1">
            {`${label}  `}
            <FormHelperText component="span">
              {touched[id] ? errors[id] : ''}
            </FormHelperText>
            {labelInfoPopup && (
              <span className={classes.infoButton}>
                <InfoPopup
                  title="More Information"
                  text={<Typography>{labelInfoPopup}</Typography>}
                  ButtonComponent={HelpIconOutline}
                />
              </span>
            )}
          </Typography>
        </Grid>
        {radios.map((item, i) => (
          <Grid
            item
            xs
            container
            key={i}
            className={classes.radioButton}
            direction="row"
            justifyContent="space-around"
          >
            <Grid item>
              <RadioGroup
                id={id}
                name={id}
                value={values[id]}
                onChange={handleChange}
              >
                <Radio value={item.value} />
              </RadioGroup>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
};

const PlottFormFormat: React.FunctionComponent<any> = (
  props: PlottFormFormat<any>
) => {
  const classes = useStyles(props);

  const [completionPercentage, setCompletionPercentage] = useState<number>(0);

  const {
    color,
    recaptcha,
    formSchema,
    formInitialValues,
    formModel,
    formOnSubmit,
    title,
    submitText,
    backButton,
    fileUploadHandler,
    fileDeleteHandler,
    uploadedFiles,
    next,
    prev,
    setGoTo,
  } = props;

  const Form: React.FunctionComponent<FormikProps<any> & FormStateProps> = (
    props: FormikProps<any> & FormStateProps
  ) => {
    useEffect(() => {
      setCompletionPercentage(getFormCompletionPercentage(props));
    }, [props]);

    const Spacing = () => <div style={{height: '30px'}}></div>;

    const RadioTable = () => {
      const rows = props.formModel.map((item: InputModels, i: number) =>
        item.type === 'radio' ? (
          <RadioInputRow
            {...{...props, ...item.data}}
            rowNumber={i}
            key={item.key}
            color={color}
          />
        ) : (
          <Typography>An error has occured</Typography>
        )
      );

      return (
        <Grid
          container
          direction="row"
          spacing={4}
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.questionsContainer}
        >
          <TableHeader
            title={'This Child Can'}
            options={PlottMenuItems.map(obj => obj.label)}
            color={color}
          />
          {rows}
        </Grid>
      );
    };

    const FileUpload = () => {
      return (
        <>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h6">
                Uploaded files related to students
              </Typography>
            </Grid>
            <Grid item className={classes.fileDirectory}>
              <PlottFileDirectory
                files={uploadedFiles}
                onDeleteFiles={fileDeleteHandler}
              />
              <div className={classes.fileUploadButton}>
                <FileUploadForm
                  {...UPLOAD_OPTIONS}
                  onSave={fileUploadHandler}
                />
              </div>
            </Grid>
          </Grid>
        </>
      );
    };

    return (
      <div className={classes.form}>
        <RadioTable />
        <Spacing />
        <FileUpload />
      </div>
    );
  };

  const body = (
    <div className={classes.cardBody}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formSchema}
        onSubmit={formOnSubmit as any}
      >
        {(props: FormikProps<any>) => {
          return (
            <ExtendedFormState {...props} formModel={formModel}>
              {(formProps: FormStateProps) => {
                return (
                  <div className={classes.cardActions}>
                    {/** Radio form */}
                    <Form {...props} {...formProps} />
                    {backButton && (
                      <Button
                        className={classes.backButton}
                        color="primary"
                        variant="contained"
                        onClick={() => backButton.callback(props.values)}
                      >
                        {backButton.label}
                      </Button>
                    )}

                    {/** Footer */}
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.errorMessage}
                    >
                      <Grid item>
                        <Typography color="error">
                          {!props.isValid && 'Please answer all the questions'}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                      {/** Previous button */}
                      <Grid item>
                        {prev !== 'None' && (
                          <Button
                            className={classes.submitButton}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setGoTo('prev');
                              props.handleSubmit();
                            }}
                          >
                            Previous
                          </Button>
                        )}
                      </Grid>
                      {/** Submit button */}
                      <Grid item>
                        <Button
                          className={classes.submitButton}
                          color="primary"
                          variant="contained"
                          onClick={() => props.handleSubmit()}
                        >
                          {submitText ? submitText : 'Submit'}
                        </Button>
                      </Grid>
                      {/** Next button */}
                      <Grid item>
                        {next !== 'None' && (
                          <Button
                            className={classes.submitButton}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setGoTo('next');
                              props.handleSubmit();
                            }}
                          >
                            Next
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                );
              }}
            </ExtendedFormState>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <>
      <StickyProgressBar value={completionPercentage} />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridContainer}
      >
        <CardCustom
          title={title}
          color={color}
          titleColor={colors.black}
          cardBody={body}
          width="100%"
          maxWidth="unset"
        ></CardCustom>
      </Grid>
    </>
  );
};

export default PlottFormFormat;
