import React, {FunctionComponent} from 'react';
import {Grid, Typography, CardMedia, Button} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {useHistory, withRouter} from 'react-router';
import colors from '../theming/colors';
import teacherImg from '../static/img/avatars/owl.png';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import SvgIcon from '@material-ui/core/SvgIcon';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import route from '../routes/route';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBody: {
      textAlign: 'center',
      margin: '16px 0px 24px 0px',
    },
    childListText: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    text: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
    },
    gridContainer: {
      margin: '3vh 0',
      width: 'fit-content',
    },
    gridItem: {
      width: '340px',
      [theme.breakpoints.up('md')]: {
        '&:not(:last-child)': {
          marginRight: '60px',
        },
      },
    },
    media: {
      height: '80px',
      objectFit: 'contain',
    },
    titleText: {
      color: colors.primary,
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
      },
    },
    classButton: {
      marginTop: '3vh',
      fontSize: '200%',
      padding: '1vh 10vh',
      borderRadius: 10,
    },
    topGrid: {
      marginTop: 32,
      marginBottom: 48,
      minWidth: '375px',
      // backgroundColor: 'white',
    },
    topButton: {
      // marginTop: '2vh',
      borderRadius: 10,
      marginRight: 12,
      color: 'white',
    },
    teacherAvatarContainer: {
      margin: 'auto',
      marginTop: '1vh',
      flex: 1,
    },
    teacherAvatar: {
      margin: 'auto',
      height: '12rem',
      width: '12rem',
      borderRadius: '50%',
      border: '0.15rem solid #003459',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '5vh',
    },
    menuContent: {
      maxWidth: '500px',
      marginLeft: '24px',
    },
    pageContent: {
      marginLeft: 24,
      marginRight: 24,
      background: 'white',
      padding: '1.5rem',
      display: 'flex',
      borderRadius: 10,
      boxShadow: '2px 3px 8px #000',
      maxWidth: 'inherit',
      width: 'fit-content',
      // backgroundColor: 'green',
    },
    filler: {
      minWidth: '35vh',
      flex: 1,
    },
    classListContainer: {
      margin: '0 24px',
    },
  })
);

const TeacherHome: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const classesUIArray = [];
  const createNewClass = () => {
    history.push(route.signup.createClass);
  };
  const user = useSelector((state: RootState) => state.user);
  const classesList = useSelector((state: RootState) => state.classes);
  const goToClassDashboard = (e: any) => {
    history.push(route.teacher.classDashboard, e.currentTarget.id);
  };
  const theme = useTheme();
  const isMDorLower = useMediaQuery(theme.breakpoints.down('sm'));

  if (user.classIDs) {
    for (const key in classesList) {
      classesUIArray.push(
        <Button
          className={classes.classButton}
          variant="contained"
          id={key}
          key={key}
          color="primary"
          onClick={goToClassDashboard}
        >
          {' '}
          {classesList[key].className}
        </Button>
      );
    }
  }

  const gridLayoutConfig = isMDorLower ? 'center' : 'flex-start';

  return (
    <>
      <Grid
        container
        className={classes.topGrid}
        direction="row"
        justifyContent="center"
      >
        <Grid
          item
          container
          className={classes.pageContent}
          direction="row"
          justifyContent={gridLayoutConfig}
          alignItems={gridLayoutConfig}
        >
          <Grid item>
            <CardMedia
              image={teacherImg}
              className={classes.teacherAvatar}
              title="logo"
            />
          </Grid>
          <Grid item className={classes.menuContent}>
            <Grid
              container
              direction="column"
              justifyContent={gridLayoutConfig}
              alignItems={gridLayoutConfig}
            >
              <Grid item>
                <Typography className={classes.titleText} variant="h4">
                  <b>{`${user.name.first} ${user.name.last}`}</b>
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent={gridLayoutConfig}
                alignItems="center"
                className={classes.gridContainer}
              >
                <Grid item>
                  <Button
                    className={classes.topButton}
                    variant="contained"
                    color="secondary"
                    onClick={createNewClass}
                  >
                    <SvgIcon component={ControlPointIcon} />
                    Add Class
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.classListContainer}>
        <Typography className={classes.text} variant="h3">
          <b>Select Class</b>
        </Typography>
        <Grid container item xs={12} spacing={3} justifyContent="center">
          {classesUIArray.map((button, i) => (
            <Grid item key={i}>
              {button}
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default withRouter(TeacherHome);
