import * as firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// Firebase references
export const db = firebase.firestore();
export const configurationCollection = db.collection('configuration');
export const usersCollection = db.collection('users');
export const parentConsentCollection = db.collection('parent_consent');
export const childrenCollection = db.collection('children');
export const childDemographic = db.collection('child_demographic');
export const classesCollection = db.collection('classes');

// Storage references
export const storage = firebase.storage(firebaseApp);
