import teacherImg from '../static/img/avatars/owl_transparent.png';
import parentImg from '../static/img/avatars/giraffe_transparent.png';
import dinosaurImg from '../static/img/avatars/dinosaur.png';
import lionImg from '../static/img/avatars/lion.png';
import robotImg from '../static/img/avatars/robot_trasparent.png';
import pandaImg from '../static/img/avatars/panda.png';
import tigerImg from '../static/img/avatars/tiger.png';
import defaultImg from '../static/img/avatars/default-avatar.png';

export const loadAvatarImage = (avatar: string) => {
  switch (avatar) {
    case 'teacherImg':
      return teacherImg;
    case 'parentImg':
      return parentImg;
    case 'dinosaurImg':
      return dinosaurImg;
    case 'lionImg':
      return lionImg;
    case 'robotImg':
      return robotImg;
    case 'pandaImg':
      return pandaImg;
    case 'tigerImg':
      return tigerImg;
  }
  return defaultImg;
};
