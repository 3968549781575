import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './firebase-app';
import './static/css/Global.css';
import store from './features/store';
import {Provider} from 'react-redux';
import log from 'loglevel';
import firebase from 'firebase/app';
import 'firebase/auth';
import {User, setUser, fetchUserInfo} from './features/users';
import {fetchChildrenData} from './features/children';
import {fetchClassesData} from './features/classes';
import {BrowserRouter as Router} from 'react-router-dom';
import {ACCOUNT_TYPES} from './features/users';
import Spinner from './components/Spinner';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css';
import {fetchAndSetConfigurations} from './features/configurations';

// Set logging
log.setLevel(process.env.NODE_ENV === 'development' ? 'DEBUG' : 'SILENT');
log.trace('Starting application. First render.');

firebase.auth().onAuthStateChanged(async user => {
  loadSpinner();

  let userRep: User = {
    email: '',
    loggedIn: false,
    uid: '',
    accountType: null,
    name: {first: '', last: ''},
  };
  if (user)
    userRep = {
      email: String(user.email),
      loggedIn: true,
      accountType: null,
      uid: String(user.uid),
      name: {first: '', last: ''},
    };
  await store.dispatch(setUser(userRep));

  if (user) {
    await store.dispatch(fetchUserInfo(userRep));
  }
  const accountType = store.getState().user.accountType;
  if (accountType === ACCOUNT_TYPES.TEACHER) {
    await store.dispatch(fetchClassesData());
  }
  await store.dispatch(fetchChildrenData());
  await store.dispatch(fetchAndSetConfigurations());

  log.debug('App state was just lost. Try to minimize this happening.');
  loadApp();
});

const loadApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('app-root')
  );
};

const loadSpinner = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Spinner />
    </React.StrictMode>,
    document.getElementById('app-root')
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
