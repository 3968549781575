import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
/**
 * A function wrapper that can be used to generate public or private routes, and
 * if not logged in it redirects to a different page.
 * @param comparator The state that determines which route to render
 */
const RouteHandler = (comparator: 'PUBLIC' | 'PRIVATE') => {
  return ({component: Component, accountType, ...rest}: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useSelector((state: RootState) => state.user);
    const {loggedIn} = user;
    const rejectedDest = comparator === 'PRIVATE' ? '/login' : '/home';
    let proceed = false;
    if (comparator === 'PRIVATE') {
      if (accountType) {
        if (
          loggedIn &&
          comparator === 'PRIVATE' &&
          accountType === user.accountType
        )
          proceed = true;
      } else {
        // Need this case if we are loading a private route that is common between both account types
        if (loggedIn && comparator === 'PRIVATE') proceed = true;
      }
    }

    if (!loggedIn && comparator === 'PUBLIC') proceed = true;

    return (
      <Route
        {...rest}
        render={props =>
          proceed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: rejectedDest,
                state: {from: props.location},
              }}
            />
          )
        }
      />
    );
  };
};

/**
 * The PublicRoute can be used to generate public routes or redirect to the home page
 * if the user is logged in.
 * The PrivateRoute is the opposite of PublicRoute and redirects to the login page
 * if the user is not logged in.
 *
 * Example usage:
 * <PrivateRoute [push] component={COMPONENT_NAME}/>
 * <PrivateRoute [push] component={() => {<div>Hello private route!</div>}}/>
 *
 * [push] is an optional boolean flag to indicate if to push to history. Learn
 * more about it here: https://reacttraining.com/react-router/web/api/Redirect/push-bool
 * PublicRoute usage is same as above.
 */
export const PublicRoute = RouteHandler('PUBLIC');
export const PrivateRoute = RouteHandler('PRIVATE');
