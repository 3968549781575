import React, {FunctionComponent, useState, useEffect} from 'react';
import giraffe from '../../static/img/avatars/giraffe_transparent.png';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {CardMedia, Grid, Button} from '@material-ui/core';
import story_books from '../../static/img/modules/story_books.png';
import face_smile from '../../static/img/modules/face_smile.png';
import face_neutral from '../../static/img/modules/face_neutral.png';
import face_sad from '../../static/img/modules/face_sad.png';
import ReactHowler from 'react-howler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    octopus0: {
      width: '40vh',
      height: '40vh',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
    story_books: {
      width: '70vh',
      height: '40vh',
    },
    btn: {
      margin: '1rem 0',
      padding: '1rem',
    },
    face: {
      width: '10vh',
      height: '10vh',
    },
  })
);

export const Page4: FunctionComponent<{
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  playingAudio: boolean;
  setPlayingAudio: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setDisableButton, playingAudio, setPlayingAudio}) => {
  const classes = useStyles();
  const endAudio = () => {
    setDisableButton(false);
    setPlayingAudio(false);
  };

  return (
    <Grid container className={classes.container}>
      <ReactHowler
        src={require('./mp3/module0audio4.mp3')}
        playing={playingAudio}
        onEnd={() => endAudio()}
      />
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.leftContainer}>
          <CardMedia image={giraffe} className={classes.octopus0} />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.rightContainer}>
          <CardMedia image={story_books} className={classes.story_books} />
          <Grid container justifyContent="center" alignItems="center">
            <Button className={classes.btn}>
              <CardMedia image={face_smile} className={classes.face} />
            </Button>
            <Button className={classes.btn}>
              <CardMedia image={face_neutral} className={classes.face} />
            </Button>
            <Button className={classes.btn}>
              <CardMedia image={face_sad} className={classes.face} />
            </Button>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
