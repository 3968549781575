/**
 * The file will contain all the schemas used by the forms.
 */

/**
 * Create a schema that will be used during the validation process
 * by formik. The error messages need to be chained here in order to work.
 */
import * as Yup from 'yup';

const RuleSet = {
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(8, 'Too short! Atleast 8 characters')
    .required('Required'),
  date: Yup.date().required('Required'),
  age: Yup.number().min(0, 'Age must be 0 or greater').required('Required'),
  string: Yup.string().required('Required'),
  stringNR: Yup.string(), // not required
  stringList: Yup.array().required('Required'),
};

export const LoginSchema = Yup.object().shape({
  email: RuleSet.email,
  password: RuleSet.password,
});

export const ParentConsentSchema = Yup.object().shape({
  readInfo: Yup.boolean().oneOf([true], 'Field must be checked'),
  agree: Yup.string().ensure().required('Required'),
});

export const TeacherConsentSchema = Yup.object().shape({
  readInfo: Yup.boolean().oneOf([true], 'Field must be checked'),
  agree: Yup.string().ensure().required('Required'),
});
export const ParentRegistrationSchema = Yup.object().shape({
  email: RuleSet.email,
  password: RuleSet.password,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Required!'), // for confirm password to work, the password key must be 'password'
  firstname: RuleSet.string,
  lastname: RuleSet.string,
  relationshipToChild: RuleSet.string,
  otherRelationship: Yup.string().when('relationshipToChild', {
    is: 'yes',
    then: RuleSet.string,
    otherwise: Yup.string().nullable(),
  }),
  childExperienceInterview: RuleSet.string,
  agreeChildDisseminationSpeech: RuleSet.string,
  agreeChildDisseminationVideo: RuleSet.string,
});

export const TeacherRegistrationSchema = Yup.object().shape({
  email: RuleSet.email,
  password: RuleSet.password,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords don't match")
    .required('Required!'), // for confirm password to work, the password key must be 'password'
  firstname: RuleSet.string,
  lastname: RuleSet.string,
  gender: RuleSet.string,
  grades: RuleSet.stringList,
  schoolName: RuleSet.string,
  userExperienceInterview: RuleSet.string,
  agreeDisseminationSpeech: RuleSet.string,
  agreeDisseminationVideo: RuleSet.string,
});

export const CreateClassSchema = Yup.object().shape({
  grade: RuleSet.string,
  className: RuleSet.string,
});

const _ChildDemoGraphicQuestionnaireSchema = {
  // child demographic
  country: RuleSet.string,
  city: RuleSet.string,
  bornInCanada: Yup.string().when('country', {
    is: 'Canada',
    then: RuleSet.string,
    otherwise: Yup.string().nullable(),
  }),
  ageMoved: Yup.string().when('bornInCanada', {
    is: 'No',
    then: RuleSet.string,
    otherwise: Yup.string().nullable(),
  }),
  gender: RuleSet.string,
  siblingsNumber: RuleSet.string,
  ethnicity: RuleSet.string,
  firstNations: RuleSet.string,
  familySpeakEnglish: RuleSet.string,
  childSpeakEnglish: RuleSet.string,
  moreAboutLanguagesAtHome: RuleSet.string,
  moreAboutChildLanguage: RuleSet.string,
};

export const ChildRegistrationSchema = Yup.object().shape({
  // child personal information
  firstname: RuleSet.string,
  lastname: RuleSet.string,
  birthdate: RuleSet.string,
  grade: RuleSet.string,
  schoolName: RuleSet.string,
  teacher: RuleSet.string,
  classID: RuleSet.string,
  ..._ChildDemoGraphicQuestionnaireSchema,
});

export const HomeLearningDiscoverySchema = Yup.object().shape({
  childrenBooks: RuleSet.string,
  activity1: RuleSet.string,
  activity2: RuleSet.string,
  activity3: RuleSet.string,
  activity4: RuleSet.string,
  activity5: RuleSet.string,
  readingToChild: Yup.string().when('activity1', {
    is: 'Daily',
    then: RuleSet.string,
    otherwise: Yup.string().nullable(),
  }),
});

export const AboutMyChildSchema = Yup.object().shape({
  OLC1: RuleSet.string,
  OLC2: RuleSet.string,
  OLC3: RuleSet.string,
  OLC4: RuleSet.string,
  OLC5: RuleSet.string,
  OLC6: RuleSet.string,
  OLC7: RuleSet.string,
  OLC8: RuleSet.string,
  OLC9: RuleSet.string,

  ERW1: RuleSet.string,
  ERW2: RuleSet.string,
  ERW3: RuleSet.string,
  ERW4: RuleSet.string,
  ERW5: RuleSet.string,
  ERW6: RuleSet.string,
  ERW7: RuleSet.string,
  ERW8: RuleSet.string,
  ERW9: RuleSet.string,
  ERW10: RuleSet.string,
  ERW11: RuleSet.string,
  ERW12: RuleSet.string,
  ERW13: RuleSet.string,

  EN1: RuleSet.string,
  EN2: RuleSet.string,
  EN3: RuleSet.string,
  EN4: RuleSet.string,
  EN5: RuleSet.string,
  EN6: RuleSet.string,
  EN7: RuleSet.string,
  EN8: RuleSet.string,
  EN9: RuleSet.string,
  EN10: RuleSet.string,
  EN11: RuleSet.string,

  SEEFS1: RuleSet.string,
  SEEFS2: RuleSet.string,
  SEEFS3: RuleSet.string,
  SEEFS4: RuleSet.string,
  SEEFS5: RuleSet.string,
  SEEFS6: RuleSet.string,
  SEEFS7: RuleSet.string,
  SEEFS8: RuleSet.string,
  SEEFS9: RuleSet.string,
  SEEFS10: RuleSet.string,
  SEEFS11: RuleSet.string,
  SEEFS12: RuleSet.string,
  SEEFS13: RuleSet.string,
  SEEFS14: RuleSet.string,
  SEEFS15: RuleSet.string,

  MS1: RuleSet.string,
  MS2: RuleSet.string,
  MS3: RuleSet.string,
  MS4: RuleSet.string,
  MS5: RuleSet.string,
  MS6: RuleSet.string,
  MS7: RuleSet.string,
  MS8: RuleSet.string,
  MS9: RuleSet.string,
});

export const HomeDemographicSchema = Yup.object().shape({
  educationParent1: RuleSet.string,
  educationParent2: RuleSet.string,
  maritalStatus: RuleSet.string,
  annualIncome: RuleSet.string,
});

export const ChildDemographicQuestionnaireSchema = Yup.object().shape(
  _ChildDemoGraphicQuestionnaireSchema
);

export const OLACQuestionnaireSchema = Yup.object().shape({
  GP1_OLAC: RuleSet.string,
  GP2_OLAC: RuleSet.string,
  FP1_OLAC: RuleSet.string,
  FP2_OLAC: RuleSet.string,
  FP3_OLAC: RuleSet.string,
  IP1_OLAC: RuleSet.string,
  IP2_OLAC: RuleSet.string,
  IP3_OLAC: RuleSet.string,
  IP4_OLAC: RuleSet.string,
  ConP1_OLAC: RuleSet.string,
  RBP1_OLAC: RuleSet.string,
  RBP2_OLAC: RuleSet.string,
});

export const ELQuestionnaireSchema = Yup.object().shape({
  GP1_EL: RuleSet.string,
  GP2_EL: RuleSet.string,
  GP3_EL: RuleSet.string,
  FP1_EL: RuleSet.string,
  FP2_EL: RuleSet.string,
  FP3_EL: RuleSet.string,
  FP4_EL: RuleSet.string,
  FP5_EL: RuleSet.string,
  FP6_EL: RuleSet.string,
  FP8_EL: RuleSet.string,
  IP1_EL: RuleSet.string,
  IP2_EL: RuleSet.string,
  FP1_EL_W: RuleSet.string,
  FP2_EL_W: RuleSet.string,
  GP1_EL_W: RuleSet.string,
  GP2_EL_W: RuleSet.string,
  RBP1_EL_W: RuleSet.string,
});

export const ENQuestionnaireSchema = Yup.object().shape({
  FP1_EN: RuleSet.string,
  FP2_EN: RuleSet.string,
  FP3_EN: RuleSet.string,
  IP1_EN: RuleSet.string,
  IP2_EN: RuleSet.string,
  ConP1_EN: RuleSet.string,
  ConP2_EN: RuleSet.string,
  ConP3_EN: RuleSet.string,
  RBP1_EN: RuleSet.string,
  GP1_EN: RuleSet.string,
});

export const WTPQuestionnaireSchema = Yup.object().shape({
  FP1_WTP: RuleSet.string,
  FP2_WTP: RuleSet.string,
  GP1_WTP: RuleSet.string,
  GP2_WTP: RuleSet.string,
  RBP1_WTP: RuleSet.string,
});

export const SEDQuestionnaireSchema = Yup.object().shape({
  FP1_SED: RuleSet.string,
  FP2_SED: RuleSet.string,
  FP3_SED: RuleSet.string,
  RBP1_SED: RuleSet.string,
  RBP2_SED: RuleSet.string,
  ColP1_SED: RuleSet.string,
  ColP2_SED: RuleSet.string,
  IP1_SED: RuleSet.string,
  ConP1_SED: RuleSet.string,
  GP1_SED: RuleSet.string,
});

export const ATLQuestionnaireSchema = Yup.object().shape({
  FP1_ATL: RuleSet.string,
  FP2_ATL: RuleSet.string,
  FP3_ATL: RuleSet.string,
  IP1_ATL: RuleSet.string,
  IP2_ATL: RuleSet.string,
  ConP1_ATL: RuleSet.string,
  ColP1_ATL: RuleSet.string,
  ColP2_ATL: RuleSet.string,
  GP1_ATL: RuleSet.string,
  GP2_ATL: RuleSet.string,
});

export const MATSQuestionnaireSchema = Yup.object().shape({
  special_needs_MATS: RuleSet.string,
  exceptional_skills_MATS: RuleSet.string,
});
