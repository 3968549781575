import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import {Grid, Button, Typography, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ErrorOutline} from '@material-ui/icons';
import {Link} from 'react-router-dom';

type ErrorPageProps = RouteComponentProps<any>;

const useStyle = makeStyles({
  container: {
    height: '90vh',
  },
  paperBody: {
    height: '350px',
    width: '350px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  items: {
    width: '100%',
  },
  button: {
    width: '90%',
    margin: '25px 0 0 0',
  },
  innerIcon: {
    display: 'inline-flex',
  },
});

const ErrorPage: React.SFC<ErrorPageProps> = () => {
  const classes = useStyle();
  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Paper className={classes.paperBody} elevation={10}>
        <Grid item className={classes.items}>
          <div className={classes.innerIcon}>
            <Typography variant="h1">
              <ErrorOutline color="secondary" fontSize="inherit" />
            </Typography>
            <Typography color="secondary" variant="h1">
              404
            </Typography>
          </div>
          <Typography component="h1" color="secondary" variant="subtitle1">
            Sorry, the page you are trying to access does not exist or has been
            deleted.
          </Typography>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            color="secondary"
            className={classes.button}
            component={Link}
            to={'/'}
          >
            Go Back Home
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default withRouter(ErrorPage);
