import React, {FunctionComponent, useEffect} from 'react';
import giraffe from '../../static/img/avatars/giraffe_transparent.png';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {CardMedia, Grid} from '@material-ui/core';
import ReactHowler from 'react-howler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    octopus0: {
      width: '40vh',
      height: '40vh',
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
    },
  })
);

export const Page2: FunctionComponent<{
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
  playingAudio: boolean;
  setPlayingAudio: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({setDisableButton, playingAudio, setPlayingAudio}) => {
  const classes = useStyles();
  const endAudio = () => {
    setDisableButton(false);
    setPlayingAudio(false);
  };
  return (
    <Grid container className={classes.container}>
      <ReactHowler
        src={require('./mp3/module0audio2.mp3')}
        playing={playingAudio}
        onEnd={() => endAudio()}
      />
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.leftContainer}>
          <CardMedia image={giraffe} className={classes.octopus0} />
        </div>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <div className={classes.rightContainer}></div>
      </Grid>
    </Grid>
  );
};
