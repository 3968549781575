import path from 'path';
import log from 'loglevel';
import {deleteFileInStorageFolder, uploadFileToStorage} from './fileStorage';
import {deletePLOTTExtras, updatePLOTTExtras} from '../features/children';
import {
  failureNotification,
  successNotification,
} from '../notifications/notification';
import store from '../features/store';
import {PlottTypes} from '../components/PLOTTQuestionnaire';

/**
 * Handles file upload to cloud storage when "saved" on file upload dialog
 * @param files files to upload
 */

/**
 * Gets file upload handler for plott file upload functionality
 * @param plottFilesStoragePath path to folder in cloud storage that holds plott file uploads
 * @param childId id of the child for the plott form
 * @param plottType plott form type
 * @returns function that handles file uploads
 */
export const getFileUploadHandler =
  (plottFilesStoragePath: string, childID: string, plottType: PlottTypes) =>
  async (files: File[]) => {
    const storageFolderPath = path.join(
      plottFilesStoragePath,
      childID,
      plottType
    );

    log.debug(`file upload storage folder ${storageFolderPath}`);

    let failureExists = false;

    await Promise.all(
      files.map(file => {
        uploadFileToStorage(file.name, file, storageFolderPath).then(res => {
          if (res === null) {
            failureExists = true;
            return;
          }

          // update global state & add uploaded files to database
          store.dispatch(
            updatePLOTTExtras(
              childID,
              {
                [file.name]: {
                  name: file.name,
                  downloadUrl: res.downloadUrl,
                  size: file.size,
                },
              },
              plottType
            )
          );
        });
      })
    );

    if (failureExists) {
      // At least one of the files failed to upload successfully
      failureNotification(
        'Partial Success',
        'Some files failed to upload, please try again'
      );
    } else {
      // All files uploaded successfully
      successNotification('Success!', 'All files uploaded successfully');
    }
  };

/**
 * Handles file upload to cloud storage when "saved" on file upload dialog
 * @param files files to upload
 */

/**
 * Gets file delete handler for plott file directory functionality
 * @param plottFilesStoragePath path to folder in cloud storage that holds plott uploaded files
 * @param childId id of the child for the plott form
 * @param plottType plott form type
 * @returns function that handles deletion of files uploaded to the cloud
 */
export const getFileDeleteHandler =
  (plottFilesStoragePath: string, childID: string, plottType: PlottTypes) =>
  async (files: string[]) => {
    const storageFolderPath = path.join(
      plottFilesStoragePath,
      childID,
      plottType
    );

    log.debug(`Deleting files from storage folder ${storageFolderPath}`);

    let failureExists = false;

    await Promise.all(
      files.map(filename => {
        deleteFileInStorageFolder(path.join(storageFolderPath, filename)).then(
          res => {
            if (res === false) {
              failureExists = true;
              return;
            }

            // update global state
            store.dispatch(deletePLOTTExtras(childID, filename, plottType));
          }
        );
      })
    );

    if (failureExists) {
      // At least one of the files failed to upload successfully
      failureNotification(
        'Failure',
        'Some files failed to be deleted, please try again'
      );
    } else {
      // All files uploaded successfully
      successNotification('Success!', 'All files deleted successfully');
    }
  };

/**
 *
 * @param childPLOTT PLOTT object of the `children` global state
 * @param plottTypes the plott type
 */
export const getPLOTTUploadedFiles = (
  childPLOTT: any,
  plottType: PlottTypes
) => {
  const childPLOTTUploadedFiles =
    childPLOTT[`${plottType}Extras`]?.uploadedFiles;
  if (childPLOTTUploadedFiles) {
    const uploadedFiles = Object.keys(childPLOTTUploadedFiles).map(filename => {
      const plottFile = childPLOTTUploadedFiles[filename];

      return {
        key: filename,
        size: plottFile.size,
        downloadUrl: plottFile.downloadUrl,
      };
    });

    return uploadedFiles;
  }

  return null;
};
