import React, {FunctionComponent} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {HomeLearningDiscoverySchema} from '../../schema';
import Signup from '../../components/Signup';
import {FormikBag} from 'formik';

export interface FormValues {
  childrenBooks: string;
  activity1: string;
  activity2: string;
  activity3: string;
  activity4: string;
  activity5: string;
  readingToChild: string;
}

type HomeLearningProps = RouteComponentProps<any> & {
  formOnSubmit: (
    values: FormValues,
    formikBag: FormikBag<object, FormValues>
  ) => void;
  formInitialValues: FormValues;
  onBack: (values: FormValues) => void;
};

const HomeLearningDiscovery: React.FunctionComponent<HomeLearningProps> = (
  props: HomeLearningProps
) => {
  const {formOnSubmit, formInitialValues, onBack} = props;
  const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
    const lst = [];
    if (!labelArr) labelArr = valArr;
    for (let i = 0; i < valArr.length; i++)
      lst.push({value: valArr[i], label: labelArr[i]});
    return lst;
  };

  const formModel: InputModels[] = [
    {
      type: 'text',
      key: 'intro',
      data: {
        text: 'Welcome to Home Learning Discovery! Home plays an important role in your child’s growth. We invite you to think about your child’s daily interactions, home environments, and most importantly your child’s development.',
      },
    },
    {
      type: 'select',
      key: 'childrenBooks',
      data: {
        id: 'childrenBooks',
        inputText: 'Do you have children’s books at home?',
        label: 'Children Books',
        menuItems: generateMenuItems([
          'No, I don’t have children’s books at home currently',
          '1-10',
          '11-40',
          '41-70',
          '71-100',
          'Over 100',
        ]),
      },
    },
    {
      type: 'text',
      key: 'activities',
      data: {
        text: 'How often do you do the following activities with your child?',
      },
    },
    {
      type: 'select',
      key: 'activity1',
      data: {
        id: 'activity1',
        label: 'Read books together',
        menuItems: generateMenuItems([
          'Daily',
          '3-4 times a week',
          '1-2 times a week',
          'Rarely',
          'Not at all',
        ]),
      },
    },
    {
      type: 'select',
      key: 'activity2',
      data: {
        id: 'activity2',
        label: 'Tell stories to my child',
        menuItems: generateMenuItems([
          'Daily',
          '3-4 times a week',
          '1-2 times a week',
          'Rarely',
          'Not at all',
        ]),
      },
    },
    {
      type: 'select',
      key: 'activity3',
      data: {
        id: 'activity3',
        label: 'Listen to my child’s stories ',
        menuItems: generateMenuItems([
          'Daily',
          '3-4 times a week',
          '1-2 times a week',
          'Rarely',
          'Not at all',
        ]),
      },
    },
    {
      type: 'select',
      key: 'activity4',
      data: {
        id: 'activity4',
        label: 'Play or sing together ',
        menuItems: generateMenuItems([
          'Daily',
          '3-4 times a week',
          '1-2 times a week',
          'Rarely',
          'Not at all',
        ]),
      },
    },
    {
      type: 'select',
      key: 'activity5',
      data: {
        id: 'activity5',
        label: 'Visit the library ',
        menuItems: generateMenuItems([
          'Daily',
          '3-4 times a week',
          '1-2 times a week',
          'Rarely',
          'Not at all',
        ]),
      },
    },
    {
      type: 'select',
      key: 'readingToChild',
      data: {
        id: 'readingToChild',
        label: 'Approximately when did you start reading to your child? ',
        menuItems: generateMenuItems([
          'Immediately after birth ',
          '3 months  ',
          '6 months  ',
          '1 year  ',
          '2 years  ',
          '3 years ',
          '4 years  ',
          'I have not started reading to my child as yet.',
        ]),
        showWhen: {
          type: 'isValue',
          id: 'activity1',
          value: 'Daily',
        },
      },
    },
  ];
  return (
    <>
      <Signup
        title="Home Learning Discovery"
        submitText="Continue to About my Child's Development"
        formSchema={HomeLearningDiscoverySchema}
        formInitialValues={formInitialValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
      ></Signup>
    </>
  );
};

export default withRouter(HomeLearningDiscovery);
