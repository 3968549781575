import PlottFormFormat, {
  PlottMenuItems,
} from '../../components/PlottFormFormat';
import React, {useState} from 'react';
import store from '../../features/store';
import {store as storen} from 'react-notifications-component';
import firebase from 'firebase/app';
import {useSelector} from 'react-redux';
import {RootState} from '../../features/rootReducer';
import {ELQuestionnaireSchema} from '../../schema';
import {withRouter, useLocation, useHistory} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {childrenCollection} from '../../firebase-app';
import route from '../../routes/route';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import colors from '../../theming/colors';
import {Typography} from '@material-ui/core';
import {updatePLOTT} from '../../features/children';

import {
  getFileDeleteHandler,
  getFileUploadHandler,
  getPLOTTUploadedFiles,
} from '../../functions/plottQuestionnaire';

const useStyles = makeStyles(() =>
  createStyles({
    fillMessage: {
      textAlign: 'center',
      margin: 'auto',
      backgroundColor: colors.warning,
      padding: '6px',
    },
  })
);
export interface ELForm {
  GP1_EL: string;
  GP2_EL: string;
  GP3_EL: string;
  FP1_EL: string;
  FP2_EL: string;
  FP3_EL: string;
  FP4_EL: string;
  FP5_EL: string;
  FP6_EL: string;
  FP8_EL: string;
  IP1_EL: string;
  IP2_EL: string;
  FP1_EL_W: string;
  FP2_EL_W: string;
  GP1_EL_W: string;
  GP2_EL_W: string;
  RBP1_EL_W: string;
}

const ELQuestionnaire: React.FunctionComponent = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const childID = String(location.state);
  const childrenState = useSelector((state: RootState) => state.children);
  const configurationState = useSelector(
    (state: RootState) => state.configurations
  );
  const next = route.teacher.enQuestionnaire;
  const prev = route.teacher.olacQuestionnaire;
  const [goTO, setGoTo] = useState(route.teacher.plottQuestionnaire);

  let defaultELValues: ELForm = {
    GP1_EL: '',
    GP2_EL: '',
    GP3_EL: '',
    FP1_EL: '',
    FP2_EL: '',
    FP3_EL: '',
    FP4_EL: '',
    FP5_EL: '',
    FP6_EL: '',
    FP8_EL: '',
    IP1_EL: '',
    IP2_EL: '',
    FP1_EL_W: '',
    FP2_EL_W: '',
    GP1_EL_W: '',
    GP2_EL_W: '',
    RBP1_EL_W: '',
  };

  const defaultELExtrasValues: any = {
    uploadedFiles: [],
  };

  let existingValues = false;
  const getExistingValues = () => {
    for (const key in childrenState) {
      if (childrenState[key].uid === childID) {
        const childPLOTT = childrenState[key].PLOTT;
        if (childPLOTT) {
          const childEL = childPLOTT.EL;
          if (childEL) {
            defaultELValues = childEL;
            existingValues = true;
          }

          const uploadedFiles = getPLOTTUploadedFiles(childPLOTT, 'EL');
          if (uploadedFiles)
            defaultELExtrasValues.uploadedFiles = uploadedFiles;
        }
      }
    }
  };

  /**
   * Handles file upload to cloud storage when "saved" on file upload dialog
   * @param files files to upload
   */
  const fileUploadHandler = getFileUploadHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'EL'
  );

  /**
   * Handles file delete from cloud storage
   * @param files files to upload
   */
  const fileDeleteHandler = getFileDeleteHandler(
    configurationState.plottFilesStoragePath,
    childID,
    'EL'
  );

  const formOnSubmit = async (values: ELForm) => {
    await childrenCollection
      .doc(childID)
      .collection('PLOTT')
      .doc('EL')
      .set({
        ...values,
        dateSubmitted: firebase.firestore.Timestamp.now(),
      })
      .catch(() => {
        storen.addNotification({
          title: 'Failed to fill out the questionnaire',
          message: 'Please try again',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
      })
      .then(async () => {
        storen.addNotification({
          title: 'Questionnaire filled out successfully',
          message: 'Thank you!',
          type: 'success',
          insert: 'bottom',
          container: 'top-right',
          animationIn: ['animated', 'slideIn'],
          animationOut: ['animated', 'slideOut'],
          dismiss: {
            duration: 3000,
            onScreen: true,
            showIcon: true,
          },
        });
        await store.dispatch(updatePLOTT(childID, {...values}, 'EL'));
        if (goTO === 'prev') {
          history.push(prev, childID);
        }
        if (goTO === 'next') {
          history.push(next, childID);
        } else {
          history.push(goTO, childID);
        }
      });
  };
  const formModel: InputModels[] = [
    {
      type: 'radio',
      key: 'GP1_EL',
      data: {
        id: 'GP1_EL',
        label: 'Reads complex words.​​',
        labelInfoPopup:
          'e.g. ‘This word is ‘thermometer’ because it is measuring heat’',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP2_EL',
      data: {
        id: 'GP2_EL',
        label: 'Reads sight words​​​',
        labelInfoPopup: 'e.g., Recognizes ‘Yes’ or ‘No’ on a ‘doctor’s’ form',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP3_EL',
      data: {
        id: 'GP3_EL',
        label: 'Decodes words​',
        labelInfoPopup:
          'e.g., Sounds out the word ‘cat’ while playing a bingo rhyming game',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP1_EL',
      data: {
        id: 'FP1_EL',
        label:
          'Shows self-initiated interest in literacy materials, such as picture, digital, or audio books.​​​​',
        labelInfoPopup: 'e.g., sits with a tablet listening to an audio book',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_EL',
      data: {
        id: 'FP2_EL',
        label: 'Reads book for enjoyment.​',
        labelInfoPopup: 'e.g., Let’s read the cat book first, it’s my favorite',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP3_EL',
      data: {
        id: 'FP3_EL',
        label: 'Handles a book appropriately.​​​​',
        labelInfoPopup:
          'e.g., Pretend to read to a baby doll, orienting the front cover of the book and turning pages correctly',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP4_EL',
      data: {
        id: 'FP4_EL',
        label: 'Distinguishes between pictures and print. ​​​​',
        labelInfoPopup:
          'e.g., There is a J which is in my name; I see the little mouse in the picture',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP5_EL',
      data: {
        id: 'FP5_EL',
        label: 'Names letters of the alphabet.​​​',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP6_EL',
      data: {
        id: 'FP6_EL',
        label:
          'Demonstrates growing awareness of beginning, ending and medial sounds of words.',
        labelInfoPopup: 'e.g., y',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP8_EL',
      data: {
        id: 'FP8_EL',
        label: 'Predicts what will happen next in a story.​',
        labelInfoPopup:
          'e.g., I think he will find the puppy in the garden shed',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP1_EL',
      data: {
        id: 'IP1_EL',
        label: 'Understands the social functions of print.',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'IP2_EL',
      data: {
        id: 'IP2_EL',
        label: 'Recognizes and generates rhymes through songs or stories​',
        labelInfoPopup:
          'e.g., Says ‘Feel and seal rhyme!, when reading Is Your Mama a Llama?',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP1_EL_W',
      data: {
        id: 'FP1_EL_W',
        label:
          'Initiates writing activities without a teacher’s direction or suggestion.​',
        labelInfoPopup:
          'e.g., Sits with a tray of sand and writes letters in the sand with her finger',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'FP2_EL_W',
      data: {
        id: 'FP2_EL_W',
        label: 'Attempts or pretends to write.​',
        labelInfoPopup:
          'e.g., Writes squiggles or near-letter shapes from left to right, top to bottom of a page',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP1_EL_W',
      data: {
        id: 'GP1_EL_W',
        label: 'Writes their name.​',
        labelInfoPopup: 'e.g., Writes first and last name during play',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'GP2_EL_W',
      data: {
        id: 'GP2_EL_W',
        label:
          'Uses symbols and forms of early writing to create more complex play.​',
        labelInfoPopup:
          'e.g. letter-sound correspondences, Creates a road sign during construction play',
        radios: PlottMenuItems,
      },
    },
    {
      type: 'radio',
      key: 'RBP1_EL_W',
      data: {
        id: 'RBP1_EL_W',
        label: 'Uses letters and/or invented spelling and basic punctuation.​',
        radios: PlottMenuItems,
      },
    },
  ];
  getExistingValues();
  return (
    <>
      {existingValues ? (
        <Typography className={classes.fillMessage} variant="subtitle1">
          You have already filled out the questionnaire. If you need to update
          it please go ahead.
        </Typography>
      ) : (
        <></>
      )}
      <PlottFormFormat
        title="Reading through Play"
        submitText={'Submit Questionnaire'}
        formSchema={ELQuestionnaireSchema}
        formInitialValues={defaultELValues}
        formModel={formModel}
        formOnSubmit={formOnSubmit}
        formWidth="90vw"
        color={colors.el}
        fileUploadHandler={fileUploadHandler}
        fileDeleteHandler={fileDeleteHandler}
        uploadedFiles={defaultELExtrasValues.uploadedFiles}
        next={next}
        prev={prev}
        setGoTo={setGoTo}
      ></PlottFormFormat>
    </>
  );
};

export default withRouter(ELQuestionnaire);
