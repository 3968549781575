import {store as storen} from 'react-notifications-component';

export const createNotification = (
  title: string,
  message: string,
  type: 'success' | 'danger' | 'info' | 'default' | 'warning'
) => {
  storen.addNotification({
    title: title,
    message: message,
    type: type,
    insert: 'bottom',
    container: 'top-right',
    animationIn: ['animated', 'slideIn'],
    animationOut: ['animated', 'slideOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
      showIcon: true,
    },
  });
};

export const failureNotification = (title: string, message: string) =>
  createNotification(title, message, 'warning');

export const successNotification = (title: string, message: string) =>
  createNotification(title, message, 'success');
