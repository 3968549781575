import React, {useState} from 'react';
import CardCustom from './CardCustom';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import {FormikProps, FormikBag, Formik} from 'formik';
import {Schema} from 'yup';
import RecaptchaContainer from './RecaptchaContainer';
import colors from '../theming/colors';
import {
  typeToInput,
  ExtendedFormState,
  FormStateProps,
  InputModels,
} from './FormInputs';

interface Signup<T> {
  title: string; // title of the questionnaire;
  submitText?: string; // text on the submit button;
  recaptcha?: boolean; // whether to have a google recaptcha
  backButton?: {label: string; callback: (values: T) => void}; // if the form has a back button
  formSchema: Schema<any>; // form schema from schema.ts
  formInitialValues: T; // initial values of the form where keys are identical to model keys
  formModel: InputModels[]; // model to build form off
  formOnSubmit: (values: T, formikBag: FormikBag<object, T>) => void;
  formWidth?: string;
}

const useStyles = makeStyles({
  cardBody: {
    textAlign: 'center',
    padding: '24px',
  },
  cardActions: {
    textAlign: 'center',
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
  },
  repatchaContainer: {
    display: 'inline-block',
  },
  form: {
    margin: '24px auto',
  },
  submitButton: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  backButton: {
    marginRight: '60px',
  },
});

const Signup: React.FunctionComponent<any> = (props: Signup<any>) => {
  const classes = useStyles();
  const {
    recaptcha,
    formSchema,
    formInitialValues,
    formModel,
    formOnSubmit,
    title,
    submitText,
    backButton,
    formWidth,
  } = props;

  const [recaptchaComplete, setRecaptchaComplete] = useState(false);

  const recaptchaHandler = {
    verified: (success: boolean) => {
      if (success) setRecaptchaComplete(true);
      else setRecaptchaComplete(false);
    },
    expired: () => {
      setRecaptchaComplete(false);
    },
  };

  const form = (props: FormikProps<any> & FormStateProps) => {
    return (
      <div className={classes.form}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {props.formModel.map((item: InputModels) => (
            <Grid item key={item.key} xs={12}>
              {typeToInput[item.type]({...props, ...item.data} as any)}
            </Grid>
          ))}
          {recaptcha && (
            <Grid item xs={12}>
              <div className={classes.repatchaContainer}>
                <RecaptchaContainer
                  onVerify={recaptchaHandler['verified']}
                  onExpire={recaptchaHandler['expired']}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  const body = (
    <div className={classes.cardBody}>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formSchema}
        onSubmit={formOnSubmit as any}
      >
        {(props: FormikProps<any>) => {
          return (
            <ExtendedFormState {...props} formModel={formModel}>
              {(formProps: FormStateProps) => {
                return (
                  <div className={classes.cardActions}>
                    {form({...props, ...formProps})}
                    {backButton && (
                      <Button
                        className={classes.backButton}
                        color="primary"
                        variant="contained"
                        onClick={() => backButton.callback(props.values)}
                      >
                        {backButton.label}
                      </Button>
                    )}
                    <Button
                      disabled={recaptcha && !recaptchaComplete}
                      // className={classes.submitButton}
                      color="primary"
                      variant="contained"
                      onClick={() => props.handleSubmit()}
                    >
                      {submitText ? submitText : 'Submit'}
                    </Button>
                  </div>
                );
              }}
            </ExtendedFormState>
          );
        }}
      </Formik>
    </div>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <CardCustom
        title={title}
        color={colors.primary}
        titleColor={colors.white}
        cardBody={body}
        maxWidth={formWidth ? formWidth : '400px'}
      ></CardCustom>
    </Grid>
  );
};

export default Signup;
