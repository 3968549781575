import React, {FunctionComponent, useState, useEffect} from 'react';
import {Grid, Typography, CardMedia, Button} from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import colors from '../theming/colors';
import {useSelector} from 'react-redux';
import {RootState} from '../features/rootReducer';
import {Child} from '../features/children';
import LoadChildCard, {ChildCard} from './ChildCard';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router';
import route from '../routes/route';

import childview_desktop from '../static/img/childview/childview_desktop.png';
import childview_tablet from '../static/img/childview/childview_tablet.png';
import childview_mobile from '../static/img/childview/childview_mobile.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundImage: `url(${childview_desktop})`,
      '@media (min-width: 768px) and (max-width: 1365px)': {
        backgroundImage: `url(${childview_tablet})`,
      },
      '@media (max-width: 767px)': {
        backgroundImage: `url(${childview_mobile})`,
      },

      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      overflowY: 'auto',
    },
    text: {
      fontSize: '1.5rem',
      color: colors.primary,
      textAlign: 'center',
      textShadow: '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white',
    },
    gridContainer: {
      margin: '15vh 0 0 0',
      position: 'absolute',
      bottom: '10vh',
    },
    childList: {
      marginTop: '1vh',
      width: '100vw',
    },
  })
);

const ChildrensView: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  // use useSelect if we are in a react function componenet
  const childrenState = useSelector((state: RootState) => state.children);
  const childElements: Array<any> = [];

  useEffect(() => {
    if (Object.keys(childrenState).length === 1) {
      history.push(`/${route.modules.moduleDashboard}`);
    }
  }, []);

  const createChildElement = (childrenList: {[index: string]: Child}) => {
    for (const key in childrenList) {
      const childInfo: ChildCard = {
        name: childrenList[key].name.first,
        avatar: childrenList[key].avatar,
        childID: childrenList[key].uid,
        buttons: [],
      };
      childElements.push(
        <div key={childInfo.childID}>{LoadChildCard(childInfo)}</div>
      );
    }
  };

  createChildElement(childrenState);

  return (
    <Paper className={classes.container}>
      <div className={classes.gridContainer}>
        <Typography className={classes.text} variant="h3">
          {Object.keys(childrenState).length > 0 ? (
            <b>Who are you?</b>
          ) : (
            <b>No child profile found</b>
          )}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
          className={classes.childList}
        >
          {childElements}
        </Grid>
      </div>
    </Paper>
  );
};

export default withRouter(ChildrensView);
