import React, {useState, useEffect} from 'react';
import {
  TextField,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {FormikProps} from 'formik';
import {usersCollection, configurationCollection} from '../firebase-app';

interface TextFieldModel {
  type: 'textfield';
  key: string;
  data: {
    id: string;
    inputText?: string; // text above the input box
    label: string;
    type?: string;
    showWhen?: {
      // if this is provided, the input will render only if the field with input `id` is equal or not equal to `value` based on type
      type: 'isValue' | 'notEmptyString' | 'notValue'; // What kind of comparator shall we use to determine to render or not
      id: string; // id of the field the input is conditional on
      value?: any; // value that we should compare to
    };
  };
}

interface SelectModel {
  type: 'select';
  key: string;
  data: {
    id: string;
    inputText?: string; // text above the input box
    label: string;
    menuItems?: any[]; // if this is not provided, 'type' must be provided and vice-versa
    type?: 'school' | 'teacher' | 'activeSchool';
    schoolId?: string; // must be defined for 'teacher' type
    showWhen?: {
      // if this is provided, the input will render only if the field with input `id` is equal or not equal to `value` based on type
      type: 'isValue' | 'notEmptyString' | 'notValue'; // What kind of comparator shall we use to determine to render or not
      id: string; // id of the field the input is conditional on
      value?: any; // value that we should compare to
    };
  };
}

interface MultiSelectModel {
  type: 'multiSelect';
  key: string;
  data: {
    id: string;
    inputText?: string;
    label: string;
    menuItems: any[];
  };
}

interface InputTextModel {
  type: 'text'; // plain text
  key: string;
  data: {
    text: string; // the input text
  };
}

export interface RadioModel {
  type: 'radio';
  key: string;
  data: {
    id: string;
    label: string;
    labelInfoPopup?: string; // partial implementation for PLOTT form
    radios: {value: any; label: string}[];
  };
}

export interface CheckboxModel {
  type: 'checkbox';
  key: string;
  data: {
    id: string;
    label: string;
  };
}

export type InputModels =
  | TextFieldModel
  | SelectModel
  | InputTextModel
  | CheckboxModel
  | RadioModel
  | MultiSelectModel;

type ExtendedFormStateProps = {
  children: (props: FormStateProps) => React.ReactElement;
  formModel: InputModels[];
} & FormikProps<any>;

export interface FormStateProps {
  schoolAndTeachers: any;
  currentSchoolName: string;
  setCurrentSchoolName: React.Dispatch<React.SetStateAction<string>>;
  formModel: InputModels[]; // the string resemble the keys
  activeSchoolsList: string[]; // list of active schools according to firebase
}

const utilizeStyles = makeStyles({
  autocomplete: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  inputText: {
    fontSize: '0.85rem',
  },
  radioButton: {
    display: 'inline-block',
  },
});
const ExtendedFormState: React.FunctionComponent<ExtendedFormStateProps> = (
  props: ExtendedFormStateProps
) => {
  const {children, values, formModel} = props;

  const [schoolAndTeachers, setSchoolAndTeachers]: any = useState({});
  const [activeSchoolsList, setActiveSchoolsList] = useState<string[]>([]);
  const [currentSchoolName, setCurrentSchoolName] = useState('');

  const updatedFormModel = () => {
    const updatedModel: InputModels[] = [];
    formModel.forEach((input: InputModels) => {
      const data = input.data;
      if ('showWhen' in data) {
        const showWhen: any = data['showWhen'];

        if (showWhen.type === 'isValue') {
          if (values[showWhen.id] === showWhen.value) updatedModel.push(input);
        } else if (showWhen.type === 'notEmptyString') {
          if (values[showWhen.id] !== '' && values[showWhen.id] !== null) {
            updatedModel.push(input);
          }
        } else if (showWhen.type === 'notValue') {
          if (values[showWhen.id] !== showWhen.value) {
            updatedModel.push(input);
          }
        }
        return;
      }
      updatedModel.push(input);
    });
    return updatedModel;
  };

  const propsToInject = {
    schoolAndTeachers,
    currentSchoolName,
    setCurrentSchoolName,
    activeSchoolsList,
    formModel: updatedFormModel(),
  };

  useEffect(() => {
    getSchoolAndTeachers();
    getActiveSchoolsList();
  });

  const getActiveSchoolsList = async () => {
    if (activeSchoolsList.length > 0) return;

    const _list: string[] = [];
    configurationCollection
      .doc('schools')
      .get()
      .then((snapshot: any) => {
        const data = snapshot.data();
        data['schools'].forEach(
          (schoolData: {name: string; active: boolean}) => {
            if (schoolData.active) _list.push(schoolData.name);
          }
        );
        setActiveSchoolsList(_list);
      });
  };

  const getSchoolAndTeachers = async () => {
    if (Object.keys(schoolAndTeachers).length !== 0) return;
    const _schoolAndTeachers: any = {};

    await usersCollection
      .where('accountType', '==', 'teacher')
      .get()
      .then(async (snapshot: any) => {
        await snapshot.forEach((doc: any) => {
          const data: any = doc.data();
          if (_schoolAndTeachers[data['schoolName']]) {
            _schoolAndTeachers[data['schoolName']].push(doc);
          } else {
            _schoolAndTeachers[data['schoolName']] = [doc];
          }
        });
        setSchoolAndTeachers(_schoolAndTeachers);
      });
  };

  return children(propsToInject);
};

type CheckboxInputProps = CheckboxModel['data'] &
  FormikProps<any> &
  FormStateProps;

const CheckboxInput = (props: CheckboxInputProps) => {
  const {touched, errors, handleChange, values, id, label} = props;

  return (
    <FormControl error={touched[id] && Boolean(errors[id])}>
      <FormControlLabel
        control={
          <Checkbox name={id} checked={values[id]} onChange={handleChange} />
        }
        label={label}
      />
      <FormHelperText>{touched[id] ? errors[id] : ''}</FormHelperText>
    </FormControl>
  );
};

type RadioInputProps = RadioModel['data'] & FormikProps<any> & FormStateProps;

const RadioInput = (props: RadioInputProps) => {
  const {touched, errors, handleChange, values, id, label, radios} = props;
  const classes = utilizeStyles();
  return (
    <FormControl error={touched[id] && Boolean(errors[id])}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        id={id}
        row
        name={id}
        value={values[id]}
        onChange={handleChange}
        className={classes.radioButton}
      >
        {radios.map((item, i) => (
          <FormControlLabel
            key={i}
            control={<Radio value={item.value} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{touched[id] ? errors[id] : ''}</FormHelperText>
    </FormControl>
  );
};

interface InputTextProps {
  text: string; // the text to be shown
}

const InputText: React.FunctionComponent<InputTextProps> = (
  props: InputTextProps
) => {
  const {text} = props;
  const classes = utilizeStyles();

  return <FormHelperText className={classes.inputText}>{text}</FormHelperText>;
};

type TextInputProps = TextFieldModel['data'] &
  FormikProps<any> &
  FormStateProps;

const TextInput: React.FunctionComponent<TextInputProps> = (
  props: TextInputProps
) => {
  const {
    id,
    label,
    type,
    touched,
    errors,
    handleChange,
    values,
    handleBlur,
    inputText,
  } = props;

  return (
    <>
      {inputText && <InputText text={inputText} />}
      <TextField
        id={id}
        label={label}
        type={type}
        margin="dense"
        variant="outlined"
        fullWidth
        value={values[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        InputLabelProps={type === 'date' ? {shrink: true} : undefined}
        helperText={touched[id] ? errors[id] : ''}
        error={touched[id] && Boolean(errors[id])}
      />
    </>
  );
};

type MultiSelectInputProps = MultiSelectModel['data'] &
  FormikProps<any> &
  FormStateProps;
export const MultiSelect: React.FunctionComponent<MultiSelectInputProps> = (
  props: MultiSelectInputProps
) => {
  const {
    id,
    label,
    menuItems,
    touched,
    errors,
    values,
    inputText,
    handleChange,
  } = props;

  return (
    <>
      {inputText && <InputText text={inputText} />}
      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        error={touched[id] && Boolean(errors[id])}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          style={{textAlign: 'left'}}
          multiple
          name={id}
          margin="dense"
          variant="outlined"
          fullWidth
          label={label}
          value={values[id]}
          onChange={handleChange}
          error={touched[id] && Boolean(errors[id])}
        >
          {menuItems &&
            menuItems.map(obj => (
              <MenuItem key={obj.value} value={obj.value}>
                {obj.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{touched[id] ? errors[id] : ''}</FormHelperText>
      </FormControl>
    </>
  );
};

type SelectInputProps = SelectModel['data'] & FormikProps<any> & FormStateProps;
export const SelectInput: React.FunctionComponent<SelectInputProps> = (
  props: SelectInputProps
) => {
  const {
    id,
    label,
    menuItems,
    type,
    touched,
    errors,
    setFieldValue,
    values,
    schoolAndTeachers,
    inputText,
    currentSchoolName,
    setCurrentSchoolName,
    activeSchoolsList,
  } = props;

  const input = values[id];

  const getTeacherList = () => {
    if (!input && schoolAndTeachers[currentSchoolName] === undefined) {
      if (input !== null) {
        setTimeout(() => setFieldValue(id, null), 0); // reset teacher name field;
      }
      return [];
    }

    const teacherList: {
      value: {name: string; id: string};
      label: string;
    }[] = [];
    if (!(currentSchoolName in schoolAndTeachers)) {
      return teacherList;
    }
    for (const doc of schoolAndTeachers[currentSchoolName]) {
      const data = doc.data() as any;
      const name = data['firstname'] + ' ' + data['lastname'];
      teacherList.push({
        value: {
          name: name,
          id: doc.id,
        },
        label: name,
      });
    }
    return teacherList;
  };

  const getSchoolNamesList = () => {
    let list: {label: string; value: string}[] = [];

    if (!schoolAndTeachers) return list;
    if (type === 'school')
      Object.keys(schoolAndTeachers).forEach(school => {
        list.push({label: school, value: school});
      });
    else if (type === 'activeSchool')
      list = activeSchoolsList.map(schoolName => ({
        label: schoolName,
        value: schoolName,
      }));
    return list;
  };

  const typeToOptions = {
    school: getSchoolNamesList,
    teacher: getTeacherList,
    activeSchool: getSchoolNamesList,
  };

  const options: {value: any; label: any} | any = type
    ? typeToOptions[type]()
    : menuItems; // any[] actually
  const optionsLabel = (option: any) => option.label;
  const optionsEquivalence = (option: any, value: any) => {
    return option.label === value.label;
  };

  const getOptionFromValue = (value: any) => {
    if (value === null || value === undefined) return null;

    let op = null;
    let compare: any = (a: string, b: string) => a === b;
    if (typeof value === 'object')
      // this implies that if a value is an object, it must have an id field
      compare = (a: {id: any}, b: {id: any}) => a.id === b.id;
    options.forEach((option: {label: any; value: any}) => {
      if (compare(option.value, value)) {
        op = option;
        return;
      }
    });
    return op;
  };

  return (
    <>
      {inputText && <InputText text={inputText} />}
      <Autocomplete
        css="margin-top: 8px; margin-botton: 4px;"
        id={id}
        options={options}
        getOptionLabel={optionsLabel}
        onChange={(e: any, value: any) => {
          setFieldValue(id, value !== null ? value.value : '');
          if (type === 'activeSchool') {
            setCurrentSchoolName(value.value);
          }
        }}
        getOptionSelected={optionsEquivalence}
        disabled={options.length === 0}
        value={values[id] === '' ? null : getOptionFromValue(values[id])}
        renderInput={params => (
          <TextField
            name={id}
            {...params}
            label={label}
            variant="outlined"
            helperText={touched[id] ? errors[id] : ''}
            error={touched[id] && Boolean(errors[id])}
          />
        )}
      ></Autocomplete>
    </>
  );
};

const getFormCompletionPercentage = (formikProps: FormikProps<any>) => {
  const {values, errors} = formikProps;
  const formFields: string[] = Object.keys(values);
  const totalFields = formFields.length;
  let filled = 0;
  formFields.forEach((field: string) => {
    if (values[field] && errors[field] === undefined) filled += 1;
  });

  return (filled / totalFields) * 100;
};

const typeToInput = {
  textfield: TextInput,
  select: SelectInput,
  text: InputText,
  checkbox: CheckboxInput,
  radio: RadioInput,
  multiSelect: MultiSelect,
};

export {typeToInput, ExtendedFormState, getFormCompletionPercentage};
