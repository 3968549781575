import React, {FunctionComponent} from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {InputModels} from '../../components/FormInputs';
import {AboutMyChildSchema} from '../../schema';
import Signup from '../../components/Signup';
import {FormikBag} from 'formik';
import ParentSignupFormFormat from '../../components/ParentSignupFormFormat';
import colors from '../../theming/colors';
import {useHistory, useLocation} from 'react-router';

export interface FormValues {
  OLC1: string;
  OLC2: string;
  OLC3: string;
  OLC4: string;
  OLC5: string;
  OLC6: string;
  OLC7: string;
  OLC8: string;
  OLC9: string;

  ERW1: string;
  ERW2: string;
  ERW3: string;
  ERW4: string;
  ERW5: string;
  ERW6: string;
  ERW7: string;
  ERW8: string;
  ERW9: string;
  ERW10: string;
  ERW11: string;
  ERW12: string;
  ERW13: string;

  EN1: string;
  EN2: string;
  EN3: string;
  EN4: string;
  EN5: string;
  EN6: string;
  EN7: string;
  EN8: string;
  EN9: string;
  EN10: string;
  EN11: string;

  SEEFS1: string;
  SEEFS2: string;
  SEEFS3: string;
  SEEFS4: string;
  SEEFS5: string;
  SEEFS6: string;
  SEEFS7: string;
  SEEFS8: string;
  SEEFS9: string;
  SEEFS10: string;
  SEEFS11: string;
  SEEFS12: string;
  SEEFS13: string;
  SEEFS14: string;
  SEEFS15: string;

  MS1: string;
  MS2: string;
  MS3: string;
  MS4: string;
  MS5: string;
  MS6: string;
  MS7: string;
  MS8: string;
  MS9: string;
}

type AboutMyChildsDevelopmentProps = RouteComponentProps<any> & {
  formOnSubmit: (
    values: FormValues,
    formikBag: FormikBag<object, FormValues>
  ) => void;
  formInitialValues: FormValues;
  onBack: (values: FormValues) => void;
};

export const PlottMenuItems = [
  {value: '4', label: 'Always'},
  {value: '3', label: 'Often'},
  {value: '2', label: 'Sometimes'},
  {value: '1', label: 'Rarely'},
  {value: '0', label: 'Not Yet'},
];

const AboutMyChildsDevelopment: React.FunctionComponent<AboutMyChildsDevelopmentProps> =
  (props: AboutMyChildsDevelopmentProps) => {
    const pathname = useLocation().pathname;
    const isOnAddChild = pathname === '/add_new_child';
    const {formOnSubmit, formInitialValues, onBack} = props;
    const generateMenuItems = (valArr: any[], labelArr?: any[]) => {
      const lst = [];
      if (!labelArr) labelArr = valArr;
      for (let i = 0; i < valArr.length; i++)
        lst.push({value: valArr[i], label: labelArr[i]});
      return lst;
    };

    const formModel: InputModels[] = [
      {
        type: 'radio',
        key: 'OLC1',
        data: {
          id: 'OLC1',
          label: 'Understand ideas said to them on the first try ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC2',
        data: {
          id: 'OLC2',
          label: 'Follow 2-3 oral instructions in sequence  ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC3',
        data: {
          id: 'OLC3',
          label: 'Express ideas so that others can understand ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC4',
        data: {
          id: 'OLC4',
          label: 'Initiate or respond verbally to greetings',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC5',
        data: {
          id: 'OLC5',
          label: 'Talk using complete sentences  ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC6',
        data: {
          id: 'OLC6',
          label: 'Ask for help and express wants and needs ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC7',
        data: {
          id: 'OLC7',
          label: 'Use a wide range of vocabulary words ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC8',
        data: {
          id: 'OLC8',
          label: 'Express curiosity by asking questions ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'OLC9',
        data: {
          id: 'OLC9',
          label: 'Tell or retell stories  ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW1',
        data: {
          id: 'ERW1',
          label: 'Hold a book in the right direction ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW2',
        data: {
          id: 'ERW2',
          label:
            'Pretend to read by following the words and pictures in books ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW3',
        data: {
          id: 'ERW3',
          label: 'Recognize rhyming words ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW4',
        data: {
          id: 'ERW4',
          label: 'Name letters of the alphabet ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW5',
        data: {
          id: 'ERW5',
          label: 'Sound letters out  ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW6',
        data: {
          id: 'ERW6',
          label: 'Recognize and read aloud some common words in print',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW7',
        data: {
          id: 'ERW7',
          label: 'Describe characters’ feelings and actions in a story ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW8',
        data: {
          id: 'ERW8',
          label: 'Put events of a story in order ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW9',
        data: {
          id: 'ERW9',
          label: 'Connect stories to personal experiences',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW10',
        data: {
          id: 'ERW10',
          label: 'Read books for enjoyment',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW11',
        data: {
          id: 'ERW11',
          label: 'Attempt or pretend to write. ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW12',
        data: {
          id: 'ERW12',
          label: 'Write their name',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'ERW13',
        data: {
          id: 'ERW13',
          label:
            'Attempt to spell words based on how they sound? (e.g. like ‘wuz’ for ‘was’)',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN1',
        data: {
          id: 'EN1',
          label: 'Recognize and read numbers out loud ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN2',
        data: {
          id: 'EN2',
          label: 'Count forward up to 10',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN3',
        data: {
          id: 'EN3',
          label: 'Count backwards ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN4',
        data: {
          id: 'EN4',
          label: 'Match numbers with objects in pictures',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN5',
        data: {
          id: 'EN5',
          label: 'Write numbers',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN6',
        data: {
          id: 'EN6',
          label:
            'Compare objects using language such as bigger, smaller more, less, same as ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN7',
        data: {
          id: 'EN7',
          label: 'Arrange objects in size order (big to small, small to big) ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN8',
        data: {
          id: 'EN8',
          label: 'Identify shapes ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN9',
        data: {
          id: 'EN9',
          label: 'Understand the idea of addition and subtraction',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN10',
        data: {
          id: 'EN10',
          label: 'Understand basic time concepts like yesterday or today. ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'EN11',
        data: {
          id: 'EN11',
          label:
            'Recognize a pattern of 2-4 items. (?- e.g., Creates a bead necklace with a consistent pattern of blue, red and yellow beads) ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS1',
        data: {
          id: 'SEEFS1',
          label: 'Recognize & communicate own feelings ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS2',
        data: {
          id: 'SEEFS2',
          label: 'Regulate emotions and impulses.',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS3',
        data: {
          id: 'SEEFS3',
          label: 'Talk through conflicts to solve problems ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS4',
        data: {
          id: 'SEEFS4',
          label:
            'Use words like ‘please,’ ‘thank you,’ ‘excuse me,’ and ‘sorry’',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS5',
        data: {
          id: 'SEEFS5',
          label: 'Show empathy and care for others ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS6',
        data: {
          id: 'SEEFS6',
          label: 'Respect others’ rights and belongings ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS7',
        data: {
          id: 'SEEFS7',
          label:
            'Work cooperatively by listening to others, sharing, and taking turns',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS8',
        data: {
          id: 'SEEFS8',
          label: 'Accept responsibility for own actions ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS9',
        data: {
          id: 'SEEFS9',
          label: 'Stay with an activity to completion ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS10',
        data: {
          id: 'SEEFS10',
          label: 'Show pride in accomplishments ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS11',
        data: {
          id: 'SEEFS11',
          label: 'Adapt to changes in situations or the environment',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS12',
        data: {
          id: 'SEEFS12',
          label: 'Remain focused without being easily distracted ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS13',
        data: {
          id: 'SEEFS13',
          label: 'Switch attention between tasks without losing focus',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS14',
        data: {
          id: 'SEEFS14',
          label: 'Plan, initiate, and organize tasks ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'SEEFS15',
        data: {
          id: 'SEEFS15',
          label: 'Retain and recall information',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS1',
        data: {
          id: 'MS1',
          label: 'Put puzzles together  ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS2',
        data: {
          id: 'MS2',
          label: 'Cut out simple shapes with scissors ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS3',
        data: {
          id: 'MS3',
          label: 'Hold pencils, crayons, or markers correctly.',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS4',
        data: {
          id: 'MS4',
          label: 'Build using blocks ',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS5',
        data: {
          id: 'MS5',
          label: 'Draw main body parts on a person',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS6',
        data: {
          id: 'MS6',
          label: 'Bounce, throw, and catch a ball',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS7',
        data: {
          id: 'MS7',
          label: 'Kick or trap a ball with their feet',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS8',
        data: {
          id: 'MS8',
          label: 'Jump on one foot',
          radios: PlottMenuItems,
        },
      },
      {
        type: 'radio',
        key: 'MS9',
        data: {
          id: 'MS9',
          label: 'Tie or secure own shoes (laces or Velcro)',
          radios: PlottMenuItems,
        },
      },
    ];
    return (
      <>
        <ParentSignupFormFormat
          title="About My Child's Development​"
          submitText={
            isOnAddChild ? 'Finish' : 'Continue to Home Demographic Questions'
          }
          formSchema={AboutMyChildSchema}
          formInitialValues={formInitialValues}
          formModel={formModel}
          formOnSubmit={formOnSubmit}
          formWidth="90vw"
          color={colors.primary}
        ></ParentSignupFormFormat>
      </>
    );
  };

export default withRouter(AboutMyChildsDevelopment);
